import React from 'react'
import PropTypes from 'prop-types'
import {Link, useNavigate} from 'react-router-dom'

export default function Navbar(props) {

    let loginstatus = sessionStorage.getItem("loginstatus");
    const navigate = useNavigate();

    const handleClick = (e) =>{
        e.preventDefault();

        if(loginstatus === "true"){
            navigate('/dashboard')
        }
        else{
            navigate('/login')
        }
    }
    return (
        <nav className="navbar navbar-expand-lg navbar-dark">

            <div className="container-fluid">

                <a className="navbar-brand ms-5" href="/">{props.title}</a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto pe-3">
                        <li className="nav-item active">
                            <Link className="nav-link " aria-current="page" to="/">Home</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link " to="/about">About</Link>
                        </li> */}

                        <li className="nav-item">
                            <a className="nav-link" href="#" onClick={handleClick}>Login</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

Navbar.propTypes = {
    title:PropTypes.string
}
