import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../../components/NavbarUser';
import config from '../config.json';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import Box from '@mui/material/Box'
import { Button, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import NavBarFaculty from '../NavBarFaculty';
import { decrypter } from "../../Util"

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
            display: "none"
        }
    }
}));


export default function MapBeneficiary() {

    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();
    const classes = useStyles();
    const [unmapped, setunmapped] = useState([]);
    const [maps, setmaps] = useState([]);

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectbatch, setselectbatch] = useState([]);

    const [smap, setsmap] = useState([]);

    const [m, setm] = useState('');

    const [c, setc] = useState('');
    const [t, sett] = useState('');
    const [b, setb] = useState('');

    const [open, setOpen] = useState(false);
    const [click, setclick] = useState(false);

    const [centers, setcenters] = useState([])
    const [sessions, setsessions] = useState([])
    //var un = []

    const [un, setun] = useState([])
    const [bt, setbt] = useState([])

    const [br, setbr] = useState([])



    const [cid, setcid] = useState(userdata.center_id)
    const [sid, setsid] = useState('')

    const [selectionModel, setSelectionModel] = useState([]);

    const columns = [
        {
            field: 'benId', headerName: 'ID', flex: 0.1, align: 'center', headerAlign: 'center'
        },

        {
            field: 'ben_name', headerName: 'Beneficiary Name', flex: 0.3, align: 'center', headerAlign: 'center',
        },

        {
            field: 'state_name', headerName: 'State', flex: 0.3, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.state) {
                    if (cellValues.row.state.state_name) {

                        result.push(cellValues.row.state.state_name);

                    }
                }
                else {
                    result = [""]
                }
                return result;
            }
        },

        {
            field: 'district_name', headerName: 'District', flex: 0.3, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.district) {
                    if (cellValues.row.district.district_name) {

                        result.push(cellValues.row.district.district_name);

                    }
                }
                else {
                    result = [""]
                }
                return result;
            }
        },
        {
            field: 'center_name', headerName: 'Center Name', flex: 0.3, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.centerSessionMap) {
                    if (cellValues.row.centerSessionMap.center.center_name) {

                        result.push(cellValues.row.centerSessionMap.center.center_name);

                    }
                }
                else {
                    result = [""]
                }
                return result;
            }
        },
        {
            field: 'session_name', headerName: 'Session Name', flex: 0.3, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.centerSessionMap) {
                    if (cellValues.row.centerSessionMap.session_name) {

                        result.push(cellValues.row.centerSessionMap.session_name);

                    }
                }
                else {
                    result = [""]
                }
                return result;
            }
        },

        {
            field: 'ben_course_opted', headerName: 'Course Opted', flex: 0.3, align: 'center', headerAlign: 'center'
        }
    ]

    const btcol = [
        {
            field: 'session_batch_id', headerName: 'ID', flex: 0.1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'center_name', headerName: 'Center Name', flex: 0.3, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.centerSessionMap) {
                    if (cellValues.row.centerSessionMap.center.center_name) {

                        result.push(cellValues.row.centerSessionMap.center.center_name);

                    }
                }
                else {
                    result = [""]
                }
                return result;
            }
        },
        {
            field: 'session_name', headerName: 'Session Name', flex: 0.3, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.centerSessionMap) {
                    if (cellValues.row.centerSessionMap.session_name) {

                        result.push(cellValues.row.centerSessionMap.session_name);

                    }
                }
                else {
                    result = [""]
                }
                return result;
            }
        },
        {
            field: 'batch_name', headerName: 'Batch Name', flex: 0.3, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];


                //console.log("first",cellValues.row.sessions)
                // cellValues.row.sessions.map((option) => (
                //     option.batches.map((option1) => (
                //         result.push(option1.batch.batch_name)
                //     ))
                //   ))
                if (cellValues.row.batch) {
                    if (cellValues.row.batch.batch_name) {

                        result.push(cellValues.row.batch.batch_name);

                    }
                }


                else {
                    result = [""]
                }
                return result;
            }
        },
        {
            field: 'start_time', headerName: 'Start Time', flex: 0.3, align: 'center', headerAlign: 'center',
            // valueGetter: (cellValues) => {
            //     let result = [];


            //         //console.log("first",cellValues.row.sessions)
            //         // cellValues.row.sessions.map((option) => (
            //         //     option.batches.map((option1) => (
            //         //         result.push(option1.batch.batch_name)
            //         //     ))
            //         //   ))
            //         if (cellValues.row.batch) {
            //             if (cellValues.row.batch.batch_name) {

            //                 result.push(cellValues.row.batch.batch_name);

            //             }
            //         }


            //     else {
            //         result = ["No User ID Created"]
            //     }
            //     return result;
            // }
        },
        {
            field: 'end_time', headerName: 'End Time', flex: 0.3, align: 'center', headerAlign: 'center',
            // valueGetter: (cellValues) => {
            //     let result = [];


            //         //console.log("first",cellValues.row.sessions)
            //         // cellValues.row.sessions.map((option) => (
            //         //     option.batches.map((option1) => (
            //         //         result.push(option1.batch.batch_name)
            //         //     ))
            //         //   ))
            //         if (cellValues.row.batch) {
            //             if (cellValues.row.batch.batch_name) {

            //                 result.push(cellValues.row.batch.batch_name);

            //             }
            //         }


            //     else {
            //         result = ["No User ID Created"]
            //     }
            //     return result;
            // }
        },
        {
            field: 'batchCourses', headerName: 'Courses', flex: 0.5, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.batchCourses) {

                    //console.log("first",cellValues.row.sessions)

                    cellValues.row.batchCourses.map((option2) => (
                        result.push(option2.courseName.course_name)
                    ))


                }
                else {
                    result = [""]
                }
                return result.join(", ");
            }
        }
    ]

    const params = new URLSearchParams();
    params.append("center_id", userdata.center_id);

    const cen = userdata.center_id;

    const params1 = new URLSearchParams();
    params1.append("cid", userdata.center_id);

    useEffect(() => {

        if (userdata === "false") {

            navigate('/')

        }

        const headers = {
            'publicKey': KEY
        }

        const cen = userdata.center_id;
        console.log("center", cen)

        const getunmapped = axios.post(BASE_URL + '/all', params, { headers: headers })
        const getcenter = axios.post(BASE_URL + '/getCenter', {}, { headers: headers })
        const getsession = axios.post(BASE_URL + '/getTSessionactive', params1, { headers: headers })
        const getbatch = axios.post(BASE_URL + '/getBatch', {}, { headers: headers })
        const getmap = axios.post(BASE_URL + '/getsessionbatches', {}, { headers: headers })


        Promise.all([getunmapped, getcenter, getsession, getbatch, getmap])
            .then(function (responses) {

                //setunmapped(responses[0].data.Beneficiaries)
                setunmapped(responses[0].data.BenList.filter(status => status.mapStatus === 'N'))
                setcenters(responses[1].data.Centers)
                console.log("first", responses[2].data.CenterSession)
                setsessions(responses[2].data.CenterSession)
                // setbatches(responses[3].data.Batches)
                setmaps(responses[4].data.SessionBatches)

            }).catch(function (errors) {

                console.log("state errors", errors[0])
                console.log("caste errors", errors[1])
                console.log("proof errors", errors[2])
                console.log("proof errors", errors[3])
                console.log("proof errors", errors[4])

            });

    }, [click])

    useEffect(() => {

        smap.map((option) => (
            setc(option.center_id),
            sett(option.session_id),
            setb(option.batch_id)
        ))

    }, [m])





    const handleclick = (e) => {

        console.log("selected", selectedRows)
        console.log("batch", selectbatch)
        // console.log("smap", smap)
        // console.log("m", m)



        console.log("json", JSON.stringify({
            benIds: selectedRows,
            batches: selectionModel

        }))

        const headers = {
            'publicKey': KEY
        }

        const data = new FormData();
        data.append('model', JSON.stringify({
            benIds: selectedRows,
            batches: selectionModel
        }))

        axios.post(BASE_URL + '/bulkBenMappings', data, { headers: headers })
            .then(function (responses) {

                console.log(responses.data)
                if (responses.data === 'OK') {

                    console.log("ok")

                    setclick(true)
                    setOpen(true)

                }

            })
            .catch(function (errors) {

            })
        setclick(false)

    }

    const handleselectcenter = (id) => {


        setm(id)
        console.log("selected center", id)
        setsmap(maps.filter(item => item.session_batch_id === id))

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleAddBatch = (e) => {
        e.preventDefault();
        navigate('/viewmapBeneficiary')

    }

    const handlesubmitclick = () => {
        console.log("first", sid)

        console.log("before", maps)



        setun(
            unmapped.filter(op => op.session_id.includes(sid))
        )

        setbt(
            maps.filter(m => m.session_id === sid)
        )



        console.log("un", un)
    }

    const handlec = (e) => {

        setb(e.target.value)

        setbr(bt.filter(b => b.session_id === e.target.value))


    }



    return (
        <>
            {userdata.role_id == 1 ? <NavbarUser
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
            /> : <NavBarFaculty
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />}
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="container">
                <Button variant="contained" sx={{ mt: 2, mb: 2 }} onClick={handleAddBatch}>View Mapped Beneficiaries</Button>
                <h1>
                    <div className="abc text-white text-center col 10 p-1 mt-1">
                        <h3> Map Beneficiary </h3>
                    </div>
                </h1>
                <Grid container
                    alignItems="center"
                    justifyContent="left"
                    justifyItems="left"
                    spacing={1}
                >
                    <Grid item xs={3}>
                        <div>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="outlined-select-district"
                                select
                                label="Select Center"
                                value={cid}
                                InputProps={{
                                    readOnly: true,
                                }}
                                onChange={(e) => setcid(e.target.value)}
                            //onChange={handleChange}
                            >
                                {centers.map((option) => (
                                    <MenuItem key={option.center_id} value={option.center_id}>
                                        {option.center_name}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </div>

                    </Grid>
                    <Grid item xs={3}>
                        <div>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="outlined-select-district"
                                select
                                label="Select Session"
                                value={sid}
                                onChange={(e) => setsid(e.target.value)}
                            //onChange={handleChange}
                            >
                                {sessions.map((option) => (
                                    <MenuItem key={option.session_id} value={option.session_id}>
                                        {option.session_name}, {option.center_name}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </div>

                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" onClick={handlesubmitclick}>
                            Submit
                        </Button>
                    </Grid>

                </Grid>

                <Box
                    justifyContent='center'
                    sx={{ height: 400, width: '100%' }}>
                    <DataGrid

                        rows={un}
                        getRowId={(r) => r.benId}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        checkboxSelection={true}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectedRows(newSelectionModel);
                        }}

                    />
                </Box>
                <h1>
                    <div className="abc text-white text-center col 10 p-1 mt-4">
                        <h3> Available Batches </h3>
                    </div>
                </h1>
                <Box
                    justifyContent='center'
                    sx={{ height: 300, width: '100%' }}>
                    <DataGrid

                        className={classes.root}
                        rows={bt}
                        getRowId={(r) => r.session_batch_id}
                        columns={btcol}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectAllCheckbox

                        // onSelectionModelChange={(selection) => {
                        //     if (selection.length > 1) {

                        //         //console.log("first",selection.length)
                        //         const selectionSet = new Set(selectbatch);
                        //         console.log("first",selectionSet)
                        //         const result = selection.filter((s) => !selectionSet.has(s));

                        //         setselectbatch(result);
                        //     } else {
                        //         setselectbatch(selection);
                        //     }
                        // }}
                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setselectbatch(newSelectionModel);
                        // }}
                        selectionModel={selectionModel}

                        onSelectionModelChange={(selection) => {
                            if (selection.length > 1) {
                                const selectionSet = new Set(selectionModel);
                                const result = selection.filter((s) => !selectionSet.has(s));

                                setSelectionModel(result);
                            } else {
                                setSelectionModel(selection);
                            }
                        }}

                    />
                </Box>
                <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
                    <Button variant="contained" onClick={handleclick}>
                        Map Beneficiaries
                    </Button>
                </Grid>


                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Mapped Successfully"
                //action={action}
                />


            </div>
        </>
    )
}
