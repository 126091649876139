import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../NavbarUser';
import NavBarAdmin from '../NavBarAdmin';
import NavBarFaculty from '../NavBarFaculty';
import config from '../config.json';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';


import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { CheckCircle, Cancel, RemoveCircleOutline, PlusOneOutlined, AddCircleOutline } from '@mui/icons-material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { Close } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { decrypter } from "../../Util"

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

export default function ManageRole() {

    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();
    const [roles, setroles] = useState([])
    const [open, setOpen] = useState(false);
    const [click, setclick] = useState(false);
    const [del, setdel] = useState('')
    const [dialog, setdialog] = useState(false)
    const [snack, setsnack] = useState('')
    const [alert, setalert] = useState(false)
    const [severe, setsevere] = useState('error')
    const [loading, setloading] = useState(false)
    const [deac, setdeac] = useState(false)
    const [val, setval] = useState()

    const columns = [
        {
            field: 'faculty_sys_id', headerName: 'ID', flex: 0.3, align: 'center', headerAlign: 'center'
        },
        {
            field: 'faculty_name', headerName: 'Faculty Name', flex: 1, align: 'center', headerAlign: 'center',
            /*valueGetter : (cellValues) => {
                let result = [];
                if(cellValues.row.faculty){
                    if (cellValues.row.faculty.faculty_name) {

                        result.push(cellValues.row.faculty.faculty_name);
                        
                    }
                }
                return result;
            }*/
        },
        {
            field: 'center_name', headerName: 'Center', flex: 1, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.center) {
                    if (cellValues.row.center.center_name) {

                        result.push(cellValues.row.center.center_name);

                    }
                }
                else {
                    result = ["No Center Mapped"]
                }
                return result;
            }
        },
        {
            field: 'user_id', headerName: 'User ID', flex: 1.1, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.facultyLoginMap) {
                    if (cellValues.row.facultyLoginMap.user_id) {

                        result.push(cellValues.row.facultyLoginMap.user_id);

                    }
                }
                else {
                    result = ["No User ID Created"]
                }
                return result;
            }
        },

        {
            field: 'last_login', headerName: 'Last Login', flex: 1, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = "";
                if (cellValues.row.facultyLoginMap) {
                    if (cellValues.row.facultyLoginMap.last_login) {


                        result = cellValues.row.facultyLoginMap.last_login;

                    }
                }

                if (result === "") {

                    result = "Not Logged In"

                }
                return result;
            }
        },

        {
            field: 'role_name', headerName: 'Role Name', flex: 0.5, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.facultyLoginMap) {
                    if (cellValues.row.facultyLoginMap.facultyRole) {

                        if (cellValues.row.facultyLoginMap.facultyRole.role) {

                            if (cellValues.row.facultyLoginMap.facultyRole.role.role_name) {

                                result.push(cellValues.row.facultyLoginMap.facultyRole.role.role_name);
                            }

                        }

                    }
                }
                else {
                    result = ["No Role Assigned"]
                }
                return result;
            }
        },
        {
            field: 'active_status', headerName: 'Status', flex: 1, align: 'center', headerAlign: 'center',

            renderCell: (cellValues) => {

                if (cellValues.row.facultyLoginMap) {
                    if (cellValues.row.facultyLoginMap.active_status) {

                        if (cellValues.row.facultyLoginMap.active_status === "Y") {
                            return (

                                <>
                                    <Chip icon={<CheckCircle></CheckCircle>} color="success" label="Active" variant="outlined" />  <Fade
                                        in={onload(cellValues)}
                                        style={{
                                            transitionDelay: loading ? '0ms' : '0ms',
                                        }}

                                    >
                                        <CircularProgress size={25} />
                                    </Fade>
                                </>
                            );
                        }
                        else {
                            return (

                                <>
                                    <Chip icon={<Cancel ></Cancel>} color="error" label="Inactive" variant="outlined" /> <Fade
                                        in={onload(cellValues)}
                                        style={{
                                            transitionDelay: loading ? '0ms' : '0ms',
                                        }}

                                    >
                                        <CircularProgress size={25} />
                                    </Fade>
                                </>
                            );
                        }



                    }
                }
                else {
                    return (

                        <Chip icon={<RemoveCircleOutline ></RemoveCircleOutline>} color="warning" label="No Login Assigned" variant="outlined" />
                    );
                }
            },




        },
        {
            field: "Action", align: 'center', flex: 0.5, headerAlign: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleLoginMapClick(event, cellValues);
                            }}
                        >
                            <AddCircleOutline></AddCircleOutline>
                        </IconButton>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleEditClick(event, cellValues);
                            }}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                        {/* <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleClickOpen(event, cellValues);
                            }}
                        >
                            <DeleteIcon></DeleteIcon>
                        </IconButton> */}

                    </>
                );
            }, flex: 1
        },
        {
            field: "Activate/Deactivate", align: 'center', headerAlign: 'center', flex: 0.8,
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleactivate(event, cellValues);
                            }}
                        >
                            <CheckCircle sx={{ color: "green" }}></CheckCircle>
                        </IconButton>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handledeactivate(event, cellValues);
                            }}
                        >
                            <Cancel sx={{ color: "red" }}></Cancel>
                        </IconButton>
                    </>
                );
            }, flex: 1
        }

    ]


    useEffect(() => {

        if (userdata === "false") {

            navigate('/')

        }


        const headers = {
            'publicKey': KEY



        }

        const params1 = new URLSearchParams();
        params1.append("center_id", userdata.center_id);

        if (userdata.role_id == 5) {
            axios.post(BASE_URL + '/loginmapAdmin', {}, { headers: headers })
                .then(function (responses) {

                    console.log("FacultyLogins", responses.data.FacultyLogins)
                    //setunmapped(responses.data.Beneficiaries)
                    setroles(responses.data.FacultyLogins)
                    setloading(false)
                    setval()
                    setdeac(false)

                })
                .catch(function (errors) {

                })

        } else {
            axios.post(BASE_URL + '/loginmap', params1, { headers: headers })
                .then(function (responses) {

                    console.log("FacultyLogins", responses.data.FacultyLogins)
                    //setunmapped(responses.data.Beneficiaries)
                    setroles(responses.data.FacultyLogins)
                    setloading(false)
                    setval()
                    setdeac(false)

                })
                .catch(function (errors) {

                })
        }


        //setloading(false)
    }, [click])

    const onload = (cellValues) => {
        if (cellValues.row.faculty_sys_id === val) {

            setloading(true)
            return true

        }
        else {
            setloading(false)
            return false
        }
    }

    const handleCloseDialog = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setdialog(false);
    };

    // dialogbox open close functions
    const handleClickOpen = (event, cellValues) => {
        setOpen(true);
        //setdel(cellValues.row.session_id)
    };

    const handleClose = () => {
        setOpen(false);
    };

    // dialogbox open close functions

    const handleLoginMapClick = (event, cellValues) => {

        if (cellValues.row.login_provided === "Y") {

            setsnack("Login Already Provided")
            setalert(true)


        } else {
            navigate('/maprole', {
                state: {
                    id: cellValues.row.faculty_sys_id, name: cellValues.row.faculty_name,
                    email: cellValues.row.faculty_email_id, cid: cellValues.row.centerId
                }
            })
        }


    }

    //edit delete button onclick functions//

    const handleEditClick = (event, cellValues) => {
        navigate('/editRole', {
            state: {
                id: cellValues.row.faculty_sys_id, name: cellValues.row.faculty_name,
                email: cellValues.row.faculty_email_id, cid: cellValues.row.centerId,
                uid: cellValues.row.facultyLoginMap.user_id, role: cellValues.row.facultyLoginMap.facultyRole.role_id
            }
        })

    }

    const handleactivate = (event, cellValues) => {

        const headers = {
            'publicKey': KEY
        }



        if (cellValues.row.facultyLoginMap.active_status === "Y") {
            setsnack(' User Is Already Active')
            setsevere("error")
            setalert(true)
        } else {
            setloading(true)
            const data = new FormData();
            data.append('model', JSON.stringify({
                activate: 'Y',
                id: cellValues.row.faculty_sys_id
            }))

            setval(cellValues.row.faculty_sys_id)
            console.log("val", cellValues.row.faculty_sys_id)
            console.log("activate")

            axios.post(BASE_URL + '/activator', data, { headers: headers })
                .then(function (response) {

                    console.log(response.data)
                    if (response.data === 'OK') {

                        setloading(true)
                        setsnack("User Activated")
                        setsevere("success")
                        setalert(true)
                        setclick(true)




                    }

                })
                .catch(function (errors) {
                    console.log(errors.response.status)

                    // if (errors.response.status === 403) {

                    //     setalert(true)

                    // }
                })

            //setloading(false)
            setclick(false)



        }
    }

    const handledeactivate = (event, cellValues) => {

        const headers = {
            'publicKey': KEY
        }
        console.log("deactive")

        if (cellValues.row.facultyLoginMap.active_status === "N") {
            setsnack(' User Is Already Inactive')
            setsevere("error")
            setalert(true)
        } else {

            const data = new FormData();
            data.append('model', JSON.stringify({
                activate: 'N',
                id: cellValues.row.faculty_sys_id
            }))

            setval(cellValues.row.faculty_sys_id)
            console.log("val", val)
            axios.post(BASE_URL + '/activator', data, { headers: headers })
                .then(function (response) {

                    console.log(response.data)
                    if (response.data === 'OK') {



                        setloading(true)
                        setsnack("User Deactivated")
                        setsevere("success")
                        setalert(true)
                        setclick(true)




                    }

                })
                .catch(function (errors) {
                    console.log(errors.response.status)

                    // if (errors.response.status === 403) {

                    //     setalert(true)

                    // }
                })

            //setloading(false)
            setclick(false)



        }

    }


    const handleDelete = () => {
        console.log("The del ", del)


        const headers = {
            'publicKey': KEY
        }

        // const data = new FormData();
        // data.append('model', JSON.stringify({
        //     session_id: del
        // }))

        // axios.post(BASE_URL + '/deleteTSession', data, { headers: headers })
        //     .then(function (responses) {

        //         console.log(responses.data)
        //         if (responses.data === 'OK') {

        //             setsnack('Training Session Deleted')
        //             setclick(true)
        //             setdialog(true);


        //         }




        //     })
        //     .catch(function (errors) {

        //     })
        setOpen(false);
        setclick(false)
        //navigate('/manageBatch')
        //setbatches(batches.filter(item => item.batch_id !== del))


    }

    //edit delete button onclick functions//

    //add batch onclick function///////

    const handleAddBatch = (e) => {
        e.preventDefault();
        //navigate('/addSession')

    }


    return (
        <>
            {userdata.role_id == 1 ? <NavbarUser
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
            /> : userdata.role_id == 5 ? <NavBarAdmin
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
            /> : <NavBarFaculty
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />}
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="row mx-5">
                <div className="container ">
                    {/* <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<FontAwesomeIcon icon={faPlus} className="me-1" />} onClick={handleAddBatch}>Add New Faculty Login</Button> */}

                    <h1>
                        <div className="abc text-white text-center col 10 p-1 mt-2">
                            <h3> Manage Faculty Login </h3>
                        </div>
                    </h1>
                    <Collapse in={alert}>
                        <Alert
                            severity={severe}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setalert(false);
                                    }}
                                >
                                    <Close fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {snack}
                        </Alert>
                    </Collapse>
                    <Box
                        justifyContent='center'
                        sx={{ height: 400, width: '100%', mb: 5 }}>
                        <DataGrid
                            rows={roles}
                            getRowId={(r) => r.faculty_sys_id}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection={true}
                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setSelectedRows(newSelectionModel);
                        // }}

                        />
                    </Box>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"ARE YOU SURE YOU WANT TO DELETE?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Once deleted it cannot be undone
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDelete}>YES</Button>
                            <Button onClick={handleClose} autoFocus>
                                NO
                            </Button>
                        </DialogActions>
                    </Dialog>



                </div >
            </div>
        </>
    )
}
