import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../../components/NavbarUser';
import NavBarAdmin from '../NavBarAdmin';
import NavBarFaculty from '../NavBarFaculty';
import config from '../config.json';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { VisibilityOff, Visibility, Close } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { decrypter } from "../../Util"

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

export default function MapRole() {

    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [alert, setalert] = useState(false)
    const [showPassword, setshowPassword] = useState(false)
    const [centers, setcenters] = useState([]);
    const [roles, setroles] = useState([]);

    const [fid, setfid] = useState(location.state.id)
    const [name, setname] = useState(location.state.name)
    const [mail, setmail] = useState(location.state.email)
    const [cid, setcid] = useState(location.state.cid)
    const [role, setrole] = useState();
    const [uid, setuid] = useState(location.state.email);
    const [pwd, setpwd] = useState();





    useEffect(() => {


        if (userdata === "false") {

            navigate('/')

        }

        const headers = {
            publicKey: KEY,
        };

        const getcenter = axios.post(
            BASE_URL + "/getCenter",
            {},
            { headers: headers }
        );
        const getrole = axios.post(
            BASE_URL + "/getRole",
            {},
            { headers: headers }
        );

        Promise.all([getcenter, getrole])
            .then(function (responses) {
                setcenters(responses[0].data.Centers);
                setroles(responses[1].data.Roles);

            })
            .catch(function (errors) {
                console.log("center errors", errors[0]);
                console.log("role errors", errors[1]);

            });


    }, [])



    const handleClickShowPassword = () => {
        if (showPassword === false) {

            setshowPassword(true)

        } else {

            setshowPassword(false)

        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleAddBatch = (e) => {
        navigate('/manageRole')
    }

    const handlesubmitclick = () => {

        const headers = {
            'publicKey': KEY
        }

        const md5 = require('md5')

        console.log("json", JSON.stringify({
            faculty_id: fid,
            user_pass: md5(pwd),
            active_status: 'N',
            user_id: uid,
            role_id: role,
            center_id: cid
        }))

        const data = new FormData();
        data.append('model', JSON.stringify({
            faculty_id: fid,
            user_pass: md5(pwd),
            active_status: 'N',
            user_id: uid,
            role_id: role,
            center_id: cid
        }))

        axios.post(BASE_URL + '/rolemap', data, { headers: headers })
            .then(function (response) {

                console.log(response.data)
                if (response.data === 'OK') {

                    setOpen(true);

                    setTimeout(function () {
                        navigate('/manageRole')
                    }, 3000);

                }

            })
            .catch(function (errors) {
                console.log(errors.response.status)

                if (errors.response.status === 403) {

                    setalert(true)

                }
            })

    }


    return (
        <>
            {userdata.role_id == 1 ? <NavbarUser
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
            /> : userdata.role_id == 5 ? <NavBarAdmin
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
            /> : <NavBarFaculty
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />}
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="container">
                <Button variant="contained" sx={{ mt: 2, mb: 2 }} onClick={handleAddBatch}>Manage Faculty Login</Button>
                <h1>
                    <div className="abc text-white text-center col 10 p-1 mt-1">
                        <h3> Create Login </h3>
                    </div>
                </h1>
                <Collapse in={alert}>
                    <Alert
                        severity='error'
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setalert(false);
                                }}
                            >
                                <Close fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        User ID Already Exists
                    </Alert>
                </Collapse>
                <Box
                    component="form"
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container
                        alignItems="center"
                        justifyContent="center"
                        justifyItems="center"
                        spacing={1}
                    >
                        <Grid item xs={3} >
                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label=" ID"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={fid}
                                    sx={{ mt: 3 }}
                                //onChange={(e) => setname(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3} >
                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label=" Name"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={name}
                                    sx={{ mt: 3 }}
                                //onChange={(e) => setname(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3} >
                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label=" Email"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={mail}
                                    sx={{ mt: 3 }}
                                //onChange={(e) => setname(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-select-district"
                                    select
                                    label="Center"
                                    value={cid}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    sx={{ mt: 3 }}
                                    onChange={(e) => setcid(e.target.value)}
                                //onChange={handleChange}
                                >
                                    {centers.map((option) => (
                                        <MenuItem
                                            key={option.center_id}
                                            value={option.center_id}
                                        >
                                            {option.center_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-select-district"
                                    select
                                    label="Select Role"
                                    value={role}
                                    sx={{ mt: 3 }}
                                    onChange={(e) => setrole(e.target.value)}
                                //onChange={handleChange}
                                >
                                    {roles.map((option) => (
                                        <MenuItem
                                            key={option.role_id}
                                            value={option.role_id}
                                        >
                                            {option.role_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Grid>
                        <Grid item xs={3} >
                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label=" User ID"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={uid}
                                    sx={{ mt: 3 }}
                                //onChange={(e) => setuid(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3} >
                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label=" Password(Atleast 6 characters)"
                                    type={showPassword ? 'text' : 'password'}
                                    value={pwd}
                                    sx={{ mt: 3 }}
                                    onChange={(e) => setpwd(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    //onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}

                                />
                            </div>
                        </Grid>
                        <Grid container justifyContent='center' sx={{ mt: 3 }} >
                            <Button variant="contained" onClick={handlesubmitclick}>Submit</Button>
                        </Grid>


                    </Grid>
                </Box>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Role Mapped"
                //action={action}
                />

            </div>
        </>
    )
}
