import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../NavbarUser';
import config from '../config.json';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { CheckCircle, Cancel } from '@mui/icons-material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Chip from '@mui/material/Chip';

import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import { decrypter } from "../../Util"

import NavBarAdmin from '../NavBarAdmin';
import Tooltip from '@mui/material/Tooltip';

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

export default function ManageSession() {

    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();
    const [sessions, setsessions] = useState([]);
    const [open, setOpen] = useState(false);
    const [click, setclick] = useState(false);
    const [del, setdel] = useState('')
    const [dialog, setdialog] = useState(false)
    const [snack, setsnack] = useState('')
    const [val, setval] = useState()
    const [loading, setloading] = useState(false)

    const columns = [
        {
            field: 'session_id', headerName: 'ID', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'center_name', headerName: 'Center Name', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'session_name', headerName: 'Session Name', flex: 1, align: 'center', headerAlign: 'center'
        },

        {
            field: 'session_start_date', headerName: 'Start Date', flex: 1, align: 'center', headerAlign: 'center'
        },

        {
            field: 'session_end_date', headerName: 'End Date', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'active_status', headerName: 'Status', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.active_status === "Y") {
                    return (
                        // <IconButton
                        // //   variant="contained"
                        // //   color="primary"
                        // //   size='medium'
                        // //   startIcon={<EditIcon></EditIcon>}

                        // // onClick={(event) => {
                        // //     handleClickOpen(event, cellValues);
                        // // }}
                        // >
                        //     <CheckCircle sx={{ color: "green" }}></CheckCircle>
                        // </IconButton>
                        <>
                            <Chip icon={<CheckCircle></CheckCircle>} color="success" label="Active" variant="outlined" />  <Fade
                                in={onload(cellValues)}
                                style={{
                                    transitionDelay: loading ? '0ms' : '0ms',
                                }}

                            >
                                <CircularProgress size={25} />
                            </Fade>
                        </>

                    );
                }
                else {
                    return (
                        // <IconButton
                        // //   variant="contained"
                        // //   color="primary"
                        // //   size='medium'
                        // //   startIcon={<EditIcon></EditIcon>}

                        // // onClick={(event) => {
                        // //     handleClickOpen(event, cellValues);
                        // // }}
                        // >
                        //     <Cancel sx={{color: "red"}}></Cancel>
                        // </IconButton>
                        <>
                            <Chip icon={<Cancel ></Cancel>} color="error" label="Inactive" variant="outlined" /> <Fade
                                in={onload(cellValues)}
                                style={{
                                    transitionDelay: loading ? '0ms' : '0ms',
                                }}

                            >
                                <CircularProgress size={25} />
                            </Fade>
                        </>

                    );
                }
            }
        },
        {
            field: "Action", align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        <Tooltip title="Edit" arrow>
                            <IconButton
                                //   variant="contained"
                                //   color="primary"
                                //   size='medium'
                                //   startIcon={<EditIcon></EditIcon>}

                                onClick={(event) => {
                                    handleEditClick(event, cellValues);
                                }}
                            >
                                <EditIcon></EditIcon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" arrow>
                            <IconButton
                                //   variant="contained"
                                //   color="primary"
                                //   size='medium'
                                //   startIcon={<EditIcon></EditIcon>}

                                onClick={(event) => {
                                    handleClickOpen(event, cellValues);
                                }}
                            >
                                <DeleteIcon></DeleteIcon>
                            </IconButton>
                        </Tooltip>

                    </>
                );
            }, flex: 1
        },
        {
            field: "Activate/Deactivate", align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        <Tooltip title="Activate" arrow>
                            <IconButton
                                //   variant="contained"
                                //   color="primary"
                                //   size='medium'
                                //   startIcon={<EditIcon></EditIcon>}

                                onClick={(event) => {
                                    handleactivate(event, cellValues);
                                }}
                            >
                                <CheckCircle sx={{ color: "green" }}></CheckCircle>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="De-Activate" arrow>
                            <IconButton
                                //   variant="contained"
                                //   color="primary"
                                //   size='medium'
                                //   startIcon={<EditIcon></EditIcon>}

                                onClick={(event) => {
                                    handledeactivate(event, cellValues);
                                }}
                            >
                                <Cancel sx={{ color: "red" }}></Cancel>
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }, flex: 1
        }

    ]

    useEffect(() => {

        if (userdata === "false") {

            navigate('/')

        }

        const headers = {
            'publicKey': KEY

        }

        //const params1 = new URLSearchParams();
        //params1.append("center_id", userdata.center_id);

        axios.post(BASE_URL + '/getTSession', {}, { headers: headers })
            .then(function (responses) {

                console.log("Sessions", responses.data.CenterSession)
                //setunmapped(responses.data.Beneficiaries)
                setsessions(responses.data.CenterSession)
                setloading(false)
                setval()

            })
            .catch(function (errors) {

            })
    }, [click])

    const onload = (cellValues) => {
        if (cellValues.row.session_id === val) {

            setloading(true)
            return true

        }
        else {
            setloading(false)
            return false
        }
    }

    const handleCloseDialog = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setdialog(false);
    };

    // dialogbox open close functions
    const handleClickOpen = (event, cellValues) => {
        setOpen(true);
        setdel(cellValues.row.session_id)
    };

    const handleClose = () => {
        setOpen(false);
    };

    // dialogbox open close functions

    //edit delete button onclick functions//

    const handleEditClick = (event, cellValues) => {
        navigate('/editSession', {
            state: {
                id: cellValues.row.session_id, cid: cellValues.row.center_id, name: cellValues.row.session_name,
                start: cellValues.row.session_start_date, end: cellValues.row.session_end_date, status: cellValues.row.active_status
            }
        })

    }

    const handleactivate = (event, cellValues) => {
        const headers = {
            'publicKey': KEY
        }

        console.log("activate")
        if (cellValues.row.active_status === "Y") {
            setsnack(' Session Is Already Active')
            setdialog(true)
        } else {

            setloading(true)
            const data = new FormData();
            data.append('model', JSON.stringify({
                activate: 'Y',
                id: cellValues.row.session_id
            }))

            setval(cellValues.row.session_id)
            console.log("val", cellValues.row.session_id)
            console.log("activate")

            axios.post(BASE_URL + '/TSactivator', data, { headers: headers })
                .then(function (response) {

                    console.log(response.data)
                    if (response.data === 'OK') {

                        setloading(true)
                        setsnack("Training Session Activated")
                        //setsevere("success")
                        //setalert(true)
                        setdialog(true)
                        setclick(true)




                    }

                })
                .catch(function (errors) {
                    console.log(errors.response.status)

                    // if (errors.response.status === 403) {

                    //     setalert(true)

                    // }
                })

            //setloading(false)
            setclick(false)




        }
    }

    const handledeactivate = (event, cellValues) => {
        const headers = {
            'publicKey': KEY
        }
        console.log("deactive")
        if (cellValues.row.active_status === "N") {
            setsnack(' Session Is Already De-Active')
            setdialog(true)
        } else {

            const data = new FormData();
            data.append('model', JSON.stringify({
                activate: 'N',
                id: cellValues.row.session_id
            }))

            setval(cellValues.row.session_id)
            console.log("val", val)
            axios.post(BASE_URL + '/TSactivator', data, { headers: headers })
                .then(function (response) {

                    console.log(response.data)
                    if (response.data === 'OK') {



                        setloading(true)
                        setsnack("Training Session Deactivated")
                        //setsevere("success")
                        //setalert(true)
                        setdialog(true)
                        setclick(true)




                    }

                })
                .catch(function (errors) {
                    console.log(errors.response.status)

                    // if (errors.response.status === 403) {

                    //     setalert(true)

                    // }
                })

            //setloading(false)
            setclick(false)

        }

    }


    const handleDelete = () => {
        console.log("The del ", del)


        const headers = {
            'publicKey': KEY
        }

        const data = new FormData();
        data.append('model', JSON.stringify({
            session_id: del
        }))

        axios.post(BASE_URL + '/deleteTSession', data, { headers: headers })
            .then(function (responses) {

                console.log(responses.data)
                if (responses.data === 'OK') {

                    setsnack('Training Session Deleted')
                    setclick(true)
                    setdialog(true);


                }




            })
            .catch(function (errors) {

            })
        setOpen(false);
        setclick(false)
        //navigate('/manageBatch')
        //setbatches(batches.filter(item => item.batch_id !== del))


    }

    //edit delete button onclick functions//

    //add batch onclick function///////

    const handleAddBatch = (e) => {
        e.preventDefault();
        navigate('/addSession')

    }

    return (
        <>
            <NavBarAdmin
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}

            <div className="row mx-5">
                <div className="container ">
                    <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<FontAwesomeIcon icon={faPlus} className="me-1" />} onClick={handleAddBatch}>Add Training Session</Button>

                    <h1>
                        <div className="abc text-white text-center col 10 p-1 mt-1">
                            <h3> Manage Training Session Details </h3>
                        </div>
                    </h1>
                    <Box
                        justifyContent='center'
                        sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={sessions}
                            getRowId={(r) => r.session_id}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}

                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setSelectedRows(newSelectionModel);
                        // }}

                        />
                    </Box>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"ARE YOU SURE YOU WANT TO DELETE?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Once deleted it cannot be undone
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDelete}>YES</Button>
                            <Button onClick={handleClose} autoFocus>
                                NO
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={dialog}
                        autoHideDuration={6000}
                        onClose={handleCloseDialog}
                        message={snack}
                    //action={action}
                    />


                </div >
            </div>
        </>
    )
}
