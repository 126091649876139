import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../../components/NavbarUser';
import config from '../config.json';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import { decrypter } from "../../Util"

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

export default function AddBatchCourseMap() {

    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();
    const [courses, setcourses] = useState([])
    const [batches, setbatches] = useState([])
    const [courseid, setcourseid] = useState('')
    const [batchid, setbatchid] = useState('')
    const [open, setOpen] = useState(false);

    useEffect(() => {

        if (userdata === "false") {

            navigate('/')

        }

        const headers = {
            'publicKey': KEY
        }

        const getcourse = axios.post(BASE_URL + '/getCourses', {}, { headers: headers })
        const getbatch = axios.post(BASE_URL + '/getBatch', {}, { headers: headers })


        Promise.all([getcourse, getbatch])
            .then(function (responses) {

                setcourses(responses[0].data.Courses)
                setbatches(responses[1].data.Batches)

            }).catch(function (errors) {

                console.log("batch errors", errors[0])
                console.log("courses errors", errors[1])


            });

    }, [])



    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleAddBatch = (e) => {
        navigate('/batchcourseMap')
    }

    const handlesubmitclick = () => {

        const headers = {
            'publicKey': KEY
        }

        const data = new FormData();
        data.append('model', JSON.stringify({
            batch_id: batchid,
            course_id: courseid
        }))

        axios.post(BASE_URL + '/addMapBatchCourse', data, { headers: headers })
            .then(function (responses) {

                console.log(responses.data)
                if (responses.data === 'OK') {
                    setOpen(true);
                    setbatchid('')
                    setcourseid('')
                }

            })
            .catch(function (errors) {

            })
    }

    return (
        <>
            <NavbarUser title={TITLE} name={userdata.faculty_name} role={userdata.role_name} state={userdata.district_name} />
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="container">
                <Button variant="contained" sx={{ mt: 2, mb: 2 }} onClick={handleAddBatch}>Manage Batch Course Map</Button>
                <h1>
                    <div className="abc text-white text-center col 10 p-1 mt-1">
                        <h3> Map New Batch and Course </h3>
                    </div>
                </h1>
                <Box
                    component="form"
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container
                        alignItems="center"
                        justifyContent="center"
                        justifyItems="center"
                        spacing={1}
                    >
                        <Grid item xs={6} >
                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-select-district"
                                    select
                                    label="Select Batch"
                                    value={batchid}
                                    onChange={(e) => setbatchid(e.target.value)}
                                //onChange={handleChange}
                                >
                                    {batches.map((option) => (
                                        <MenuItem key={option.batch_id} value={option.batch_id}>
                                            {option.batch_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Grid>
                        <Grid item xs={6}>

                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-select-district"
                                    select
                                    label="Select Course"
                                    value={courseid}
                                    onChange={(e) => setcourseid(e.target.value)}
                                //onChange={handleChange}
                                >
                                    {courses.map((option) => (
                                        <MenuItem key={option.course_id} value={option.course_id}>
                                            {option.course_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                        </Grid>
                        <Grid container justifyContent='center' sx={{ mt: 3 }} >
                            <Button variant="contained" onClick={handlesubmitclick}>Submit</Button>
                        </Grid>

                    </Grid>
                </Box>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Batch Course Map Added"
                //action={action}
                />
            </div>
        </>
    )
}
