import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../images/login.jpg';
import config from "../components/config.json";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";


import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileOpenIcon from '@mui/icons-material/FileOpen';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan, faPlus, faFilePen } from '@fortawesome/free-solid-svg-icons'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import {
    DataGrid, GridToolbar,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    gridPageSizeSelector
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Avatar from '@mui/material/Avatar';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from "@mui/material";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
//import { Document, Page, ReactPDF } from "react-pdf";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import Alert from '@mui/material/Alert';

import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { decrypter } from "../Util"
import moment from 'moment';
import NavBarAdmin from "../components/NavBarAdmin";

import Pagination from '@mui/material/Pagination';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';

import MediaQuery from 'react-responsive'
import { useMediaQuery } from 'react-responsive'

import Workbook from 'react-excel-workbook'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Chip from '@mui/material/Chip';
import { CheckCircle, Cancel } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import doc from '../test.docx'
import pdf from '../tester.pdf'
import lucida from '../LucidaUnicodeCalligraphy.ttf'
import humans from '../humans.ttf'
import pdfform from 'pdfform.js';
import { Tooltip } from '@mui/material';
import { PDFDocument, StandardFonts, PDFTextField, formCore } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit';

import DBaksa2 from '../Certificate Template/DBaksa2.docx'
import GBaksa2 from '../Certificate Template/GBaksa2.docx'

import DKokrajhar2 from '../Certificate Template/DKokrajhar2.docx'
import GKokrajhar2 from '../Certificate Template/GKokrajhar2.docx'









function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
}


var base64;

const useStyle = makeStyles({
    root: {
        "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 1.5,
            textAlign: "center"
        },
        "& .MuiDataGrid-cellContent": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center"
        },
        "& .MuiTypography-string": {
            textOverflow: "clip",
            whiteSpace: "pre-wrap",
            lineHeight: 2,
            textAlign: "center"
        },
        boxShadow: 2,
        '& .MuiTablePagination-selectLabel': {
            marginTop: 'auto'
        },
        '& .MuiTablePagination-displayedRows': {
            marginTop: 'auto'
        }
    }
});

const style2 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    margin: 'auto',
    backgroundColor: 'white'


}


const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;
export default function Certificate() {
    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();

    const [modalopen2, setmodalopen2] = useState(false)
    const [formname, setformname] = useState('');

    const [bens, setbens] = useState([]);
    const [certbens, setcertbens] = useState([])

    const [pageSize, setPageSize] = useState(10);

    const classes = useStyle()

    useEffect(() => {

        if (userdata === "false") {

            navigate('/')

        }

        const headers = {
            'publicKey': KEY

        }

        if (userdata.role_id == 5) {


            axios.post(BASE_URL + '/getAllBeneficiariesAdmin', {}, { headers: headers })
                .then(function (responses) {

                    console.log("Beneficiaries", responses.data.Beneficiaries)
                    //setunmapped(responses.data.Beneficiaries)
                    setbens(responses.data.Beneficiaries.filter(op => op.certificate_issued === "Y"))


                })
                .catch(function (errors) {

                })

        } else {

            const params = new URLSearchParams();
            params.append("center_id", userdata.center_id);

            axios.post(BASE_URL + '/getAllBeneficiaries', params, { headers: headers })
                .then(function (responses) {

                    console.log("Beneficiaries", responses.data.Beneficiaries)
                    //setunmapped(responses.data.Beneficiaries)
                    setbens(responses.data.Beneficiaries)

                })
                .catch(function (errors) {

                })

        }
    }, [])

    const columns = [
        {
            field: 'ben_id', headerName: 'ID', flex: 0.1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'enrollment_id', headerName: 'Enrollment ID', flex: 0.5, align: 'center', headerAlign: 'center'
        },

        {
            field: 'Image', headerName: 'Image', flex: 0.2, align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.ben_image === null) {
                    return (
                        <Avatar alt={cellValues.row.ben_name}
                            src={cellValues.row.ben_name}
                            sx={{ width: 40, height: 40 }}
                            variant="circular"
                        />
                    );
                }
                else {
                    return (
                        <Avatar alt={cellValues.row.ben_name}
                            src={BASE_URL + "/" + "images" + "/" + cellValues.row.ben_image}
                            sx={{ width: 40, height: 40 }}
                            variant="circular"
                        />
                    );
                }
            }
        },

        {
            field: 'center_name', headerName: 'Center Name', flex: 0.5, align: 'center', headerAlign: 'center'
        },
        {
            field: 'session_name', headerName: 'Session Name', flex: 0.4, align: 'center', headerAlign: 'center'
        },
        {
            field: 'ben_name', headerName: 'Name', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (<Typography variant="string" textTransform="uppercase">
                    {params.row.ben_name}
                </Typography>)
            }
        },
        {
            field: 'gender', headerName: 'Gender', flex: 0.5, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (<Typography variant="string" textTransform="uppercase">
                    {params.row.gender}
                </Typography>)
            }
        },
        {
            field: 'ben_fname', headerName: 'Guardian Name', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (<Typography variant="string" textTransform="uppercase">
                    {params.row.ben_fname}
                </Typography>)
            }
        },


        {
            field: 'relation', headerName: 'Relation', flex: 0.5, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.relation) {
                    if (cellValues.row.relation === "F") {

                        result.push("Father");
                    }
                    else if (cellValues.row.relation === "M") {
                        result.push("Mother");
                    }
                    else if (cellValues.row.relation === "H") {
                        result.push("Husband");
                    }
                }
                else {
                    result = ["Email Not Provided"]
                }
                return result;
            }
        },
        ,
        {
            field: "View Form",
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleViewFormOpen(event, cellValues);
                                //window.open(BASE_URL + "/" + "forms" + "/" + cellValues.row.ben_reg_form,"","location=yes,width=600,height=480,toolbar=no,menubar=no,resizable=yes")
                                //window.open('file:///C:/benForm/BTC02B0006.pdf')
                                //const url = BASE_URL + "/" + "forms" + "/" + cellValues.row.ben_reg_form;
                                //console.log("url is ", url)
                                //<iframe src= "url" width="800" height="600"></iframe>
                                // <iframe src="https://research.google.com/pubs/archive/44678.pdf"
                                //     width="800" height="600"></iframe>
                            }}

                        >

                            <InsertDriveFileIcon></InsertDriveFileIcon>
                        </IconButton>


                        {/* <a href={BASE_URL + "/" + "forms" + "/" + cellValues.row.ben_reg_form} target="_blank" rel="noopener noreferrer"><DesktopWindowsIcon></DesktopWindowsIcon></a>
                    <a href='file:///C:\benForm\BTC02B0006.pdf' className='pdf' target="_blank" rel="noopener noreferrer">View</a> */}
                    </>


                );
            }, flex: 0.4, align: 'center', headerAlign: 'center'
        },

        // {
        //     field: 'certificate_issued', headerName: 'Certificate Status', flex: 0.5, align: 'center', headerAlign: 'center',
        //     renderCell: (cellValues) => {
        //         if (cellValues.row.certificate_issued === "Y") {
        //             return (
        //                 // <IconButton
        //                 // //   variant="contained"
        //                 // //   color="primary"
        //                 // //   size='medium'
        //                 // //   startIcon={<EditIcon></EditIcon>}

        //                 // // onClick={(event) => {
        //                 // //     handleClickOpen(event, cellValues);
        //                 // // }}
        //                 // >
        //                 //     <CheckCircle sx={{ color: "green" }}></CheckCircle>
        //                 // </IconButton>
        //                 <>
        //                     <Chip icon={<CheckCircle></CheckCircle>} color="success" label="Issued" variant="outlined" />

        //                 </>

        //             );
        //         }
        //         else {
        //             return (
        //                 // <IconButton
        //                 // //   variant="contained"
        //                 // //   color="primary"
        //                 // //   size='medium'
        //                 // //   startIcon={<EditIcon></EditIcon>}

        //                 // // onClick={(event) => {
        //                 // //     handleClickOpen(event, cellValues);
        //                 // // }}
        //                 // >
        //                 //     <Cancel sx={{color: "red"}}></Cancel>
        //                 // </IconButton>
        //                 <>
        //                     <Chip icon={<Cancel ></Cancel>} color="error" label="Not Issued" variant="outlined" />
        //                 </>

        //             );
        //         }
        //     }
        // },
        ,
        {
            field: "Generate Certificate",
            renderCell: (cellValues) => {
                return (
                    <>
                        <Tooltip title="Generate Certificate" arrow>
                            <IconButton
                                //   variant="contained"
                                //   color="primary"
                                //   size='medium'
                                //   startIcon={<EditIcon></EditIcon>}
                                onClick={(event) => {
                                    generateWord(cellValues.row, "G");
                                }}


                            >
                                <FileDownloadIcon ></FileDownloadIcon>
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }, flex: 0.5, align: 'center', headerAlign: 'center'
        },
        {
            field: "Generate Distinction Certificate",
            renderCell: (cellValues) => {
                return (
                    <>
                        <Tooltip title="Generate Distinction Certificate" arrow>
                            <IconButton
                                //   variant="contained"
                                //   color="primary"
                                //   size='medium'
                                //   startIcon={<EditIcon></EditIcon>}
                                onClick={(event) => {
                                    generateWord(cellValues.row, "D");
                                }}


                            >
                                <FileDownloadIcon ></FileDownloadIcon>
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }, flex: 0.5, align: 'center', headerAlign: 'center'
        }


    ]

    const handleViewFormOpen = (e, cellValues) => {
        //setmodalopen2(true)
        const ab = BASE_URL + "/" + "forms" + "/" + cellValues.row.ben_reg_form
        setformname(ab)
        console.log("formname ", formname);

        window.open(ab, "Form", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=1000,height=1000")

        //var FileSaver = require('file-saver');
        //FileSaver.saveAs(BASE_URL + "/" + "forms" + "/" + cellValues.row.ben_reg_form, "cellValues.row.ben_reg_form.pdf");
        // fetch(BASE_URL + "/" + "forms" + "/" + cellValues.row.ben_reg_form).then(response => {
        //     response.blob().then(blob => {
        //         // Creating new object of PDF file
        //         const fileURL = window.URL.createObjectURL(blob);
        //         // Setting various property values
        //         let alink = document.createElement('a');
        //         alink.href = fileURL;
        //         alink.download = cellValues.row.ben_reg_form;
        //         alink.click();
        //     })
        // })
    }

    const handlemodalClose2 = () => {
        setmodalopen2(false);
    };



    const generateWord = (cellValues, type) => {

        const fname = cellValues.ben_name;
        const enroll = cellValues.enrollment_id;
        const gname = cellValues.ben_fname;
        var relation = "";

        var filename = cellValues.enrollment_id + ".docx";

        if (cellValues.gender === "Male") {
            switch (cellValues.relation) {
                case "F":
                    relation = "S/o"
                    break;
            }
        }
        else if (cellValues.gender === "Female") {
            switch (cellValues.relation) {
                case "F":
                    relation = "D/o"
                    break;
                case "H":
                    relation = "W/o"
                    break;
            }
        }

        var certfile;

        if (cellValues.center_id === "1") {

            if (type === "G") {
                certfile = GBaksa2

            } else if (type === "D") {

                certfile = DBaksa2

            }


        } else if (cellValues.center_id === "2") {

            if (type === "G") {
                certfile = GKokrajhar2

            } else if (type === "D") {

                certfile = DKokrajhar2

            }

        }








        loadFile(
            certfile,
            function (error, content) {
                if (error) {
                    throw error;
                }
                var zip = new PizZip(content);
                var doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                });
                doc.setData({
                    first_name: fname.replace(/(\w)(\w*)/g,
                        function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); }),
                    enrollment: enroll,
                    relation: relation,
                    f_name: gname.replace(/(\w)(\w*)/g,
                        function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); })
                });
                try {
                    // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                    doc.render();
                } catch (error) {
                    // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                    function replaceErrors(key, value) {
                        if (value instanceof Error) {
                            return Object.getOwnPropertyNames(value).reduce(function (
                                error,
                                key
                            ) {
                                error[key] = value[key];
                                return error;
                            },
                                {});
                        }
                        return value;
                    }
                    console.log(JSON.stringify({ error: error }, replaceErrors));

                    if (error.properties && error.properties.errors instanceof Array) {
                        const errorMessages = error.properties.errors
                            .map(function (error) {
                                return error.properties.explanation;
                            })
                            .join('\n');
                        console.log('errorMessages', errorMessages);
                        // errorMessages is a humanly readable message looking like this :
                        // 'The tag beginning with "foobar" is unopened'
                    }
                    throw error;
                }
                var out = doc.getZip().generate({
                    type: 'blob',
                    mimeType:
                        'application/pdfapplication/vnd.openxmlformats-officedocument.wordprocessingml.document',
                }); //Output the document using Data-URI
                saveAs(out, filename);
                //const file = new File([out], 'out.docx', { type: 'application/pdfapplication/vnd.openxmlformats-officedocument.wordprocessingml.document' })





                // const reader = new FileReader();
                // reader.readAsArrayBuffer(file);
                // reader.onload = function () {
                //     const dataUrl = reader.result;
                //     console.log(dataUrl);
                //     saveAs(new Blob([dataUrl], { type: 'application/pdf' }), 'op.pdf')


                //     //const pdfWindow = window.open("");
                //     //pdfWindow.document.write("<iframe width='100%' height='100%' src='" + dataUrl + "'></iframe>");

                // };



                //saveAs(out,{ type: 'application/pdf' }, 'op.pdf')

                //console.log("first,", out)
                //win.document.write("<iframe width='100%' height='100%' src='" + fileURL + "'></iframe>");




                // var arrayBuffer;
                // var fileReader = new FileReader();
                // //fileReader.readAsDataURL(out.slice(0, out.size, "application/pdf"))
                // fileReader.readAsArrayBuffer(out)
                // fileReader.onloadend = function () {
                //     arrayBuffer = fileReader.result;
                //     // console.log("buffin", arrayBuffer)
                //     // console.log("buffinnew", arrayBuffer.substr(arrayBuffer.indexOf(',') + 1))
                //     // base64 = arrayBuffer.substr(arrayBuffer.indexOf(',') + 1);




                //     //saveAs(new Blob([arrayBuffer], { type: 'application/pdf' }), 'op.pdf')
                //     const arr = new Uint8Array(arrayBuffer);

                //     const blob = new Blob([arr], { type: 'application/pdf' });

                //     saveAs(blob, 'op.pdf')

                //     // const url = URL.createObjectURL(new Blob(
                //     //     [out],
                //     //     { type: 'application/pdf' }
                //     // ))
                //     // const pdfWindow = window.open("");
                //     // pdfWindow.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");




                // };










            }
        );
    };

    const generatePDF = (cellValues, type) => {

        const fname = cellValues.ben_name;
        const enroll = cellValues.enrollment_id;
        const gname = cellValues.ben_fname;
        var relation = "";

        var filename = cellValues.enrollment_id + ".docx";

        if (cellValues.gender === "Male") {
            switch (cellValues.relation) {
                case "F":
                    relation = "S/o"
                    break;
            }
        }
        else if (cellValues.gender === "Female") {
            switch (cellValues.relation) {
                case "F":
                    relation = "D/o"
                    break;
                case "H":
                    relation = "W/o"
                    break;
            }
        }







        console.log("in gen doc")
        var xhr = new XMLHttpRequest()
        var fileReader = new FileReader();
        xhr.open("GET", pdf, true);
        xhr.responseType = "arraybuffer";
        xhr.addEventListener("load", function () {
            if (xhr.status === 200) {
                // var fields = {
                //     'first_name': ['Indranil '],
                //     'last_name': [' Ganguly']
                // };
                // var outbuff = pdfform().transform(xhr.response, fields);
                // console.log("after outbuff")

                // saveAs(new Blob(
                //     [outbuff],
                //     { type: 'application/pdf' }
                // ), 'op.pdf')

                const formPdfBytes = xhr.response;
                form(formPdfBytes, fname, enroll, gname, relation);
            }
        }, false);
        xhr.send();
    }



    async function form(pdfbuff, fname, enroll, gname, relation) {



        // var fontbuff;
        // var filereader = new FileReader()
        // filereader.onload = function(){
        //     fontbuff = filereader.result
        // }

        // filereader.readAsArrayBuffer(lucida)

        var filename = enroll + ".pdf";

        const fontBytes = await fetch(lucida).then(res => res.arrayBuffer())
        const humansBytes = await fetch(humans).then(res => res.arrayBuffer())
        //const pdfDoc = await PDFDocument.create()
        const pdfDoc = await PDFDocument.load(pdfbuff)

        // Register the `fontkit` instance
        pdfDoc.registerFontkit(fontkit)

        // Embed our custom font in the document
        const customFont = await pdfDoc.embedFont(fontBytes)
        const humansFont = await pdfDoc.embedFont(humansBytes)

        // Add a blank page to the document
        // const page = pdfDoc.getPages()
        // const p = page[0]

        // Create a string of text and measure its width and height in our custom font
        // const text = 'This is text in an embedded font!'
        // const textSize = 35
       
        // Draw the string of text on the page
        // p.drawText(text, {
        //     x: 40,
        //     y: 450,
        //     size: textSize,
        //     font: customFont,
            
        // })

        

        const f = pdfDoc.getForm()
        const namefield = f.getTextField('first_name')
        const fnamefield = f.getTextField('f_name')
        const enrollfield = f.getTextField('enroll')
        const relationfield = f.getTextField('relation')

        namefield.setText(fname)
        namefield.updateAppearances(customFont)
        namefield.setFontSize(14)
        fnamefield.setText(gname)
        fnamefield.updateAppearances(customFont)
        fnamefield.setFontSize(14)
        enrollfield.setText(enroll)
        enrollfield.setFontSize(14)
        enrollfield.updateAppearances(humansFont)
        relationfield.setText(relation)
        relationfield.setFontSize(18)
        relationfield.updateAppearances(humansFont)

        f.flatten()

        
        



        const pdfBytes = await pdfDoc.save()


        saveAs(new Blob(
            [pdfBytes],
            { type: 'application/pdf' }
        ), filename)






    }


    return (
        <>
            <NavBarAdmin
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}

            {/* <button onClick={generateDocuments}>Generate document</button> */}

            <div className="row mx-5 mb-5">
                <div className="container">
                    <h1>

                        <div className="abc text-white text-center col 10 p-1 mt-1">
                            <h3> Beneficiary Certificate Status </h3>
                        </div>
                    </h1>
                    <Box
                        justifyContent='center'
                    //</div>sx={{ height: 700, width: '100%' }}
                    >
                        <DataGrid
                            className={classes.root}
                            sx={{
                                boxShadow: 2,
                                '& .MuiTablePagination-selectLabel': {
                                    marginTop: 'auto'
                                },
                                '& .MuiTablePagination-displayedRows': {
                                    marginTop: 'auto'
                                }
                            }}
                            rows={bens}
                            getRowId={(r) => r.ben_id}
                            columns={columns}
                            components={{ Toolbar: GridToolbar }}
                            autoHeight
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            pagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            disableVirtualization
                        //checkboxSelection={true}
                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setSelectedRows(newSelectionModel);
                        // }}

                        />
                    </Box>
                    {/* pdf modal */}
                    <Modal
                        open={modalopen2}
                        onClose={handlemodalClose2}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >


                        <Box sx={style2}>
                            <button onClick={handlemodalClose2}>CLOSE</button>
                            <p align='center'>

                                <iframe src={formname}
                                    width="800" height="800" margin='auto'></iframe></p>
                        </Box>




                    </Modal>
                </div>
            </div>

        </>
    )
}
