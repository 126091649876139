import React from 'react'
import BaseNavBar from './BaseNavBar'
import Banner1 from '../images/Banner1.jpg'
import Banner2 from '../images/Banner2.jpg'
import objective from '../images/objective.jpg'
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import e1 from "../images/emblem.png"
import cdac from "../images/cdac.png"
import mission from "../images/mission.jpg"
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faPowerOff, faBullseye } from '@fortawesome/free-solid-svg-icons'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import { CheckCircle,CheckCircleOutline } from '@mui/icons-material';
import config from '../components/config.json'

import Slide from 'react-reveal/Slide';

const TITLE = config.TITLE;

export default function Objective() {
    return (
        <>
            <BaseNavBar title={TITLE}></BaseNavBar>
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={objective} className="d-block w-100" alt="image1" />
                            </div>
                            {/* <div className="carousel-item">
                                <img src={Banner2} className="d-block w-100" alt="image2" />
                            </div> */}

                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className='container'>

                <div className=" text-black text-center col 10 p-1 mt-5">
                    <h3> MISSION OBJECTIVE </h3>
                </div>
                <Box
                    component="form"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"

                    sx={{
                        marginTop: 8,
                        // display: "flex",
                        // flexDirection: "column"


                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Slide left>

                        <Grid container spacing={6} direction="row"
                            alignItems={"center"}
                            justifyContent={"center"}>


                            <Grid item xs={3} alignItems="right" >
                                <img src={mission} className="w-20" alt="" />

                            </Grid>

                            <Grid item xs={9} >
 
                                <Typography variant="subtitle1" align='justify' sx={{marginTop: 2}} >
                                    <CheckCircleOutline color='success' sx={{ marginRight: 2 }}  />
                                    Handloom community development at Two Districts of BTC, Assam i.e. Kokrajhar &amp; Baksa using modern
                                    technologies like computer embroidery machine, e-Tailoring Technology, Design creation using CAD
                                    software etc.


                                </Typography>
                                <Typography variant="subtitle1" align='justify' sx={{marginTop: 2}}>
                                    <CheckCircleOutline color='success' sx={{ marginRight: 2 }} />
                                    Beneficiaries will be trained on State-of-Art based Handloom Design creation, Product
                                    preparation using computer embroidery machine &amp; e-tailoring for product customization, product quality
                                    checking-packaging &amp; Marketing. Simultaneously, Basic Computer &amp; Soft’s Skill training will be given to the
                                    beneficiaries to do their day to day activities.


                                </Typography>
                                <Typography variant="subtitle1" align='justify' sx={{marginTop: 2}}>
                                    <CheckCircleOutline color='success' sx={{ marginRight: 2 }} />
                                    State-of-Art based centralized Design Hub Development based on Cultural &amp; Heritage wise Handloom
                                    arts/design to rejuvenate the rich handloom design of the BTC and Digital support for their product
                                    preparation.


                                </Typography>
                                <Typography variant="subtitle1" align='justify' sx={{marginTop: 2}}>
                                    <CheckCircleOutline color='success' sx={{ marginRight: 2 }} />
                                    Web Portal Development with e-commerce for Handloom Community of BTC, Assam for sustainable growth
                                    of their handloom sector and product selling/marketing.


                                </Typography>
                                <Typography variant="subtitle1" align='justify' sx={{marginTop: 2}}>
                                    <CheckCircleOutline color='success' sx={{ marginRight: 2 }} />
                                    Entrepreneurship and Employability scope generation for the beneficiaries with the support of Local Govt.,
                                    Handloom Dept. of Assam &amp; other corporate sectors and overall economic growth for the tribal community
                                    of Bodoland Territorial Council, Assam.


                                </Typography>
                                <Typography variant="subtitle1" align='justify' sx={{marginTop: 2}}>
                                    <CheckCircleOutline color='success' sx={{ marginRight: 2 }} />
                                    Technical Skill Development &amp; Soft Skill Training for the people of Bodoland Territory for their
                                    betterment in future and boost up this Tribal community of Assam.


                                </Typography>
                            </Grid>
                        </Grid>

                    </Slide>

                </Box>

            </div>
        </>
    )
}
