import React from 'react'
import gallery from '../images/gallery.jpg'
import BaseNavBar from './BaseNavBar'
import config from '../components/config.json'
import ImageGallery from "react-image-gallery";
import { useState } from 'react';
import { useEffect } from 'react';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import { CleanHands } from '@mui/icons-material';
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom'


import "react-image-gallery/styles/css/image-gallery.css";
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';


// import Lightbox from "yet-another-react-lightbox";
// import "yet-another-react-lightbox/styles.css";
// import Captions from "yet-another-react-lightbox/plugins/captions";
// import "yet-another-react-lightbox/plugins/captions.css";

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

export default function BaseGallery() {

    const [images, setimages] = useState(null);
    const [infra, setinfra] = useState(null);
    const [event, setevent] = useState(null);
    const [slides, setslides] = useState([]);

    const [backdrop, setbackdrop] = useState(true)
    const navigate = useNavigate();


    useEffect(() => {

        

        const headers = {
            'publicKey': KEY

        }

        const params = new URLSearchParams();
        params.append("gallery_category_id", 1);

        const params2 = new URLSearchParams();
        params2.append("gallery_category_id", 2);

        const params3 = new URLSearchParams();
        params3.append("gallery_category_id", 3);

        const training = axios.post(BASE_URL + "/getallgalleryitems", params, {
            headers: headers,
        });

        const infra = axios.post(BASE_URL + "/getallgalleryitems", params2, {
            headers: headers,
        });

        const event = axios.post(BASE_URL + "/getallgalleryitems", params3, {
            headers: headers,
        });

        Promise.all([training, infra, event])
            .then(function (responses) {
                setimages(
                    responses[0].data.GalleryItems.map(url => ({
                        original: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
                        thumbnail: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
                        originalHeight: "500px",
                        originalAlt: url.image_description,
                        originalTitle: url.image_description,
                        thumbnailTitle: url.image_description,
                        description: url.image_description,

                    }))
                )
                setinfra(
                    responses[1].data.GalleryItems.map(url => ({
                        original: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
                        thumbnail: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
                        originalHeight: "500px",
                        originalAlt: url.image_description,
                        originalTitle: url.image_description,
                        thumbnailTitle: url.image_description,
                        description: url.image_description,



                    }))
                )
                setevent(
                    responses[2].data.GalleryItems.map(url => ({
                        original: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
                        thumbnail: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
                        originalHeight: "500px",
                        originalAlt: url.image_description,
                        originalTitle: url.image_description,
                        thumbnailTitle: url.image_description,
                        description: url.image_description,



                    }))
                )

                setbackdrop(false)

            })
            .catch(function (errors) {
                console.log("training errors", errors[0]);
                console.log("infra errors", errors[1]);

            });

        console.log("slides ", slides)



        // axios.post(BASE_URL + '/getallgalleryitems', params, { headers: headers })
        //     .then(function (responses) {

        //         console.log("GalleryItems", responses.data.GalleryItems)
        //         //setunmapped(responses.data.Beneficiaries)
        //         //setbens(responses.data.Beneficiaries)

        //         if (responses.data.GalleryItems) {
        //             setimages(
        //                 responses.data.GalleryItems.map(url => ({
        //                     original: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
        //                     thumbnail: `${BASE_URL + "/" + "gallery" + "/" + url.gallery_id}`,
        //                     originalAlt: url.image_description,
        //                     originalTitle: url.image_description,
        //                     thumbnailTitle: url.image_description,
        //                     description: url.image_description,
        //                     sizes: 4


        //                 })))
        //         }

        //         console.log("imgs ", images)

        //     })
        //     .catch(function (errors) {

        //     })


    }, [])


    const handlebackdropClose = () => {
        setbackdrop(false);
    };





    return (
        <>
            <BaseNavBar title={TITLE}></BaseNavBar>
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={gallery} className="d-block w-100" alt="image1" />
                            </div>
                            {/* <div className="carousel-item">
                                <img src={Banner2} className="d-block w-100" alt="image2" />
                            </div> */}

                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className='container'>
                <Fade bottom>
                    <div className=" text-black text-center col 10 p-1 mt-5 ">
                        <h3> TRAINING ACTIVITIES </h3>
                    </div>
                </Fade>

                <Fade left>
                    <Box

                        sx={{
                            marginTop: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            boxShadow: 4,
                            padding: 1,


                        }}
                        noValidate
                        autoComplete="off"
                    >


                        {images ? <ImageGallery items={images} autoPlay={true} /> : null}


                        {/* {slides ? <Lightbox slides={slides.map((img) => ({
                            src: `${BASE_URL + "/" + "gallery" + "/" + img.img_file}`,
                            description: img.image_description
                        }))}
                            plugins={[Captions]} /> : null} */}




                    </Box>
                </Fade>

                <Fade bottom>
                    <div className=" text-black text-center col 10 p-1 mt-5 ">
                        <h3> INFRASTRUCTURE </h3>
                    </div>

                </Fade>

                <Fade right>
                    <Box
                        component="form"
                        sx={{
                            marginTop: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            boxShadow: 4,
                            padding: 1
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        {infra ? <ImageGallery items={infra} autoPlay={true} /> : null}



                    </Box>
                </Fade>

                <Fade bottom>
                    <div className=" text-black text-center col 10 p-1 mt-5 ">
                        <h3> EVENTS </h3>
                    </div>

                </Fade>

                <Fade left>
                    <Box
                        component="form"
                        sx={{
                            marginTop: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            boxShadow: 4,
                            padding: 1
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        {infra ? <ImageGallery items={event} autoPlay={true} /> : null}



                    </Box>
                </Fade>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdrop}
                    onClick={handlebackdropClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>


            </div>


        </>
    )
}
