import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../NavbarUser';
import config from '../config.json';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Avatar from '@mui/material/Avatar';
import NavBarFaculty from '../NavBarFaculty';
import { decrypter } from "../../Util"

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

export default function ViewMapBeneficiary() {

    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();
    const [bens, setbens] = useState([]);
    const [open, setOpen] = useState(false);
    const [click, setclick] = useState(false);
    const [del, setdel] = useState('')
    const [dialog, setdialog] = useState(false)

    const columns = [
        {
            field: 'benId', headerName: 'ID', flex: 0.1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'ben_name', headerName: 'Name', flex: 0.4, align: 'center', headerAlign: 'center'
        },
        {
            field: 'state_name', headerName: 'State', flex: 0.3, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.state) {
                    if (cellValues.row.state.state_name) {

                        result.push(cellValues.row.state.state_name);

                    }
                }
                else {
                    result = ["No User ID Created"]
                }
                return result;
            }
        },
        {
            field: 'district_name', headerName: 'District', flex: 0.3, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.district) {
                    if (cellValues.row.district.district_name) {

                        result.push(cellValues.row.district.district_name);

                    }
                }
                else {
                    result = ["No User ID Created"]
                }
                return result;
            }
        },
        {
            field: 'center_name', headerName: 'Center Name', flex: 0.5, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.sessions) {

                    //console.log("first",cellValues.row.sessions)
                    cellValues.row.sessions.map((option) => (
                        option.batches.map((option1) => (
                            result.push(option1.centerSessionMap.center.center_name)
                        ))
                    ))

                }
                else {
                    result = ["No User ID Created"]
                }
                return result;
            }
        },
        {
            field: 'session_name', headerName: 'Session Name', flex: 0.3, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.centerSessionMap) {
                    if (cellValues.row.centerSessionMap.session_name) {

                        result.push(cellValues.row.centerSessionMap.session_name);

                    }
                }
                else {
                    result = ["No User ID Created"]
                }
                return result;
            }
        },
        {
            field: 'batch_name', headerName: 'Batch Name', flex: 0.3, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.sessions) {

                    //console.log("first",cellValues.row.sessions)
                    cellValues.row.sessions.map((option) => (
                        option.batches.map((option1) => (
                            result.push(option1.batch.batch_name)
                        ))
                    ))

                }
                else {
                    result = ["No User ID Created"]
                }
                return result;
            }
        },
        {
            field: 'batchCourses', headerName: 'Courses', flex: 0.5, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.sessions) {

                    //console.log("first",cellValues.row.sessions)
                    cellValues.row.sessions.map((option) => (
                        option.batches.map((option1) => (
                            option1.batchCourses.map((option2) => (
                                result.push(option2.courseName.course_name)
                            ))
                        ))
                    ))

                }
                else {
                    result = ["No User ID Created"]
                }
                return result.join(", ");
            }
        },
        {
            field: "Action",
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleEditClick(event, cellValues);
                            }}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                    </>
                );
            }, flex: 0.2, align: 'center', headerAlign: 'center'
        }


    ]


    useEffect(() => {

        if (userdata === "false") {

            navigate('/')

        }

        const headers = {
            'publicKey': KEY

        }

        const params = new URLSearchParams();
        params.append("center_id", userdata.center_id);

        axios.post(BASE_URL + '/all', params, { headers: headers })
            .then(function (responses) {

                console.log("Beneficiaries", responses.data.BenList)
                //setunmapped(responses.data.Beneficiaries)

                setbens(responses.data.BenList.filter(status => status.mapStatus === 'Y'))
                // setbens(responses.data.BenList)

            })
            .catch(function (errors) {

            })


    }, [])

    const handleEditClick = (event, cellValues) => {

        navigate('/editmapBeneficiary', {
            state: {
                bid: cellValues.row

            }
        })

    }



    const handleCloseDialog = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setdialog(false);
    };

    const handleAddBatch = (e) => {
        e.preventDefault();
        navigate('/mapBeneficiary')

    }


    return (
        <>
            {userdata.role_id == 1 ? <NavbarUser
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
            /> : <NavBarFaculty
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />}
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="row mx-5">
                <div className="container">
                    <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<FontAwesomeIcon icon={faPlus} className="me-1" />} onClick={handleAddBatch}>Map Beneficiaries</Button>
                    <h1>
                        <div className="abc text-white text-center col 10 p-1 mt-1">
                            <h3> Mapped Beneficiary Details </h3>
                        </div>
                    </h1>
                    <Box
                        justifyContent='center'
                        sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={bens}
                            getRowId={(r) => r.benId}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                        //checkboxSelection={true}
                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setSelectedRows(newSelectionModel);
                        // }}

                        />
                    </Box>
                    {/* <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"ARE YOU SURE YOU WANT TO DELETE?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Once deleted it cannot be undone
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDelete}>YES</Button>
                            <Button onClick={handleClose} autoFocus>
                                NO
                            </Button>
                        </DialogActions>
                    </Dialog> */}
                    <Snackbar
                        open={dialog}
                        autoHideDuration={6000}
                        onClose={handleCloseDialog}
                        message="Beneficiary Deleted"
                    //action={action}
                    />

                </div>
            </div>
        </>
    )
}
