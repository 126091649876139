import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../../components/NavbarUser';
import config from '../config.json';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import { decrypter } from "../../Util"

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
            display: "none"
        }
    }
}));


const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

export default function AddBatchSessionCenter() {

    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();
    const classes = useStyles();
    const [sessions, setsessions] = useState([])
    const [batches, setbatches] = useState([])
    const [sid, setsid] = useState('')
    const [cid, setcid] = useState('')
    const [batchid, setbatchid] = useState('')
    const [start, setstart] = useState(null)
    const [stop, setstop] = useState(null)
    const [open, setOpen] = useState(false);

    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {

        if (userdata === "false") {

            navigate('/')

        }

        const headers = {
            'publicKey': KEY
        }

        const params1 = new URLSearchParams();
        params1.append("center_id", userdata.center_id);

        const getsession = axios.post(BASE_URL + '/getTSession', params1, { headers: headers })
        const getbatch = axios.post(BASE_URL + '/getbatchmaps', {}, { headers: headers })


        Promise.all([getsession, getbatch])
            .then(function (responses) {

                setsessions(responses[0].data.CenterSession)
                setbatches(responses[1].data.BatchMaps)

            }).catch(function (errors) {

                console.log("batch errors", errors[0])
                console.log("Session errors", errors[1])


            });

    }, [])

    const btcol = [
        {
            field: 'batch_id', headerName: 'ID', flex: 0.1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'batch_name', headerName: 'Batch Name', flex: 0.5, align: 'center', headerAlign: 'center'
        },
        {
            field: 'batchCourses', headerName: 'Courses', flex: 0.5, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.batchCourses) {

                    //console.log("first",cellValues.row.sessions)

                    cellValues.row.batchCourses.map((option2) => (
                        result.push(option2.courseName.course_name)
                    ))


                }
                else {
                    result = ["No User ID Created"]
                }
                return result.join(", ");
            }
        }
    ]

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleAddBatch = (e) => {
        navigate('/batchsessioncenterMap')
    }

    const handlesubmitclick = () => {

        const headers = {
            'publicKey': KEY
        }

        const data = new FormData();
        data.append('model', JSON.stringify({
            session_id: sid,
            batch_id: selectionModel,
            start_time: moment(new Date(start)).format("HH:mm:ss"),
            end_time: moment(new Date(stop)).format("HH:mm:ss")
        }))

        console.log("first", JSON.stringify({
            session_id: sid,
            batch_id: selectionModel,
            start_time: moment(new Date(start)).format("HH:mm:ss"),
            end_time: moment(new Date(stop)).format("HH:mm:ss")
        }))

        axios.post(BASE_URL + '/addMapBatchSessionCenter', data, { headers: headers })
            .then(function (responses) {

                console.log(responses.data)
                if (responses.data === 'OK') {
                    setOpen(true);
                    setbatchid('')
                    setsid('')
                    setstart(null)
                    setstop(null)
                }

            })
            .catch(function (errors) {

            })
    }

    return (
        <>
            <NavbarUser title={TITLE} name={userdata.faculty_name} role={userdata.role_name} state={userdata.district_name} />
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="container">
                <Button variant="contained" sx={{ mt: 2, mb: 2 }} onClick={handleAddBatch}>Manage Center, Session and Batch</Button>
                <h1>
                    <div className="abc text-white text-center col 10 p-1 mt-1">
                        <h3> Map New Center, Session and Batch </h3>
                    </div>
                </h1>
                <Box
                    component="form"
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container
                        alignItems="center"
                        justifyContent="center"
                        justifyItems="center"
                        spacing={1}
                    >
                        <Grid item xs={3}>

                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-select-district"
                                    select
                                    label="Select Session"
                                    value={sid}
                                    sx={{ mb: 2 }}
                                    onChange={(e) => setsid(e.target.value)}
                                //onChange={handleChange}
                                >
                                    {sessions.map((option) => (
                                        <MenuItem key={option.session_id} value={option.session_id}>
                                            {option.center_name} , {option.session_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                        </Grid>
                        {/* <Grid item xs={3}>

                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-select-district"
                                    select
                                    label="Select Batch"
                                    value={batchid}
                                    onChange={(e) => setbatchid(e.target.value)}
                                //onChange={handleChange}
                                >
                                    {batches.map((option) => (
                                        <MenuItem key={option.batch_id} value={option.batch_id}>
                                            {option.batch_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Grid> */}
                        <Grid item xs={3} >
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileTimePicker
                                        label="Start Time"
                                        fullWidth
                                        value={start}
                                        onChange={(newValue) => {
                                            setstart(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </LocalizationProvider>
                            </div>

                        </Grid>
                        <Grid item xs={3}>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileTimePicker
                                        label="End Time"
                                        value={stop}
                                        onChange={(newValue) => {
                                            setstop(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </LocalizationProvider>
                            </div>

                        </Grid>
                        <Box
                            justifyContent='center'
                            sx={{ height: 400, width: '100%' }}>
                            <DataGrid

                                className={classes.root}
                                rows={batches}
                                getRowId={(r) => r.batch_id}
                                columns={btcol}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                checkboxSelection
                                disableSelectAllCheckbox

                                // onSelectionModelChange={(selection) => {
                                //     if (selection.length > 1) {

                                //         //console.log("first",selection.length)
                                //         const selectionSet = new Set(selectbatch);
                                //         console.log("first",selectionSet)
                                //         const result = selection.filter((s) => !selectionSet.has(s));

                                //         setselectbatch(result);
                                //     } else {
                                //         setselectbatch(selection);
                                //     }
                                // }}
                                // onSelectionModelChange={(newSelectionModel) => {
                                //     setselectbatch(newSelectionModel);
                                // }}
                                selectionModel={selectionModel}

                                onSelectionModelChange={(selection) => {
                                    if (selection.length > 1) {
                                        const selectionSet = new Set(selectionModel);
                                        const result = selection.filter((s) => !selectionSet.has(s));

                                        setSelectionModel(result);
                                    } else {
                                        setSelectionModel(selection);
                                    }
                                }}

                            />
                        </Box>
                        <Grid container justifyContent='center' sx={{ mt: 3 }} >
                            <Button variant="contained" onClick={handlesubmitclick}>Submit</Button>
                        </Grid>

                    </Grid>
                </Box>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Mapping Added"
                //action={action}
                />
            </div>
        </>
    )
}
