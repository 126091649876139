
import React, { useState, useEffect } from "react";

import { Cookies } from "react-cookie";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import login from '../images/login.jpg';
import config from '../components/config.json';
import { isValid, parseISO } from "date-fns";
import { Link, useNavigate, useLocation, Navigate } from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import regex from "../components/regex.json";
import { decrypter } from "../Util.js"
import NavBarAdmin from "../components/NavBarAdmin";


const cookies = new Cookies();
//const BASE_URL = "http://10.240.13.181:8888";
const BASE_URL = config.SERVER_URL;

const KEY = config.KEY;
const TITLE = config.TITLE;
export default function EditGallery() {
    let userdata = decrypter(cookies.get("userdata"))

    const navigate = useNavigate();
    const location = useLocation();

    const [id, setid] = useState(location.state.id)

    const [gallerycat, setgallerycat] = useState([])

    const [cat, setcat] = useState(location.state.cat)
    const [errcatb, seterrcatb] = useState(false)
    const [errcat, seterrcat] = useState('')

    const [desc, setdesc] = useState(location.state.desc)
    const [errdesc, seterrdesc] = useState('')
    const [errdescb, seterrdescb] = useState('')

    const [preview, setpreview] = useState()
    const [hiddenb, sethiddenb] = useState(true)

    const [openalertimg, setopenalertimg] = useState(false)

    const [open, setOpen] = useState(false);
    const [imagealert, setimagealert] = useState("")

    const [filename, setFilename] = useState("");
    const [file, setfile] = useState();

    const [btncolor, setbtncolor] = useState("primary")
    const [errbtn, seterrbtn] = useState("")

    const [snackmsg, setsnackmsg] = useState("")
    const [backdrop, setbackdrop] = useState(false)

    useEffect(() => {

        if (userdata === "false") {

            navigate('/')
            
          }

        const headers = {
            publicKey: KEY,
        };


        const gallerycategory = axios.post(
            BASE_URL + "/getgallerycategory",
            {},
            { headers: headers }
        );


        Promise.all([gallerycategory])
            .then(function (responses) {
                setgallerycat(responses[0].data.GalleryCategory);
            })
            .catch(function (errors) {
                //console.log("gallery errors", errors[0]);

            });

    }, []);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const handlebackdropClose = () => {
        setbackdrop(false);
    };

    const handlesubmitclick = (e) => {
        e.preventDefault();

        if (validation()) {

            const headers = {
                publicKey: KEY,

            };

            setbackdrop(true)

            const data = new FormData();
            data.append("file", file);
            data.append(
                "model",
                JSON.stringify({
                    gallery_id: id,
                    gallery_category_id: cat,
                    image_description: desc,
                    updated_by: userdata.faculty_sys_id
                })
            );

            // console.log(
            //     "sending data",
            //     JSON.stringify({
            //         gallery_id: id,
            //         gallery_category_id: cat,
            //         image_description: desc,
            //         updated_by: userdata.faculty_sys_id
            //     })
            // );

            axios
                .post(BASE_URL + "/editGallery", data, { headers: headers })
                .then(function (response) {
                    //console.log(response);
                    if (response.data === "OK" && response.status == 202) {
                        setbackdrop(false)
                        setsnackmsg("Item Edited Successfully")
                        setOpen(true);
                        setTimeout(function () {
                            navigate('/galleryManagement')
                        }, 3000);

                    }
                    else {
                        setbackdrop(false)

                        setsnackmsg("Edit Failed")
                        setOpen(true);
                    }
                })
                .catch(function (errors) {
                    setbackdrop(false)
                    setsnackmsg("Edit Failed")
                    setOpen(true);
                });
        }
    }

    function validation() {
        let isvalid = true;


        if (desc === "") {
            seterrdescb(true)
            seterrdesc("Description Cannot Be Empty")
            isvalid = false
        } else if (!desc.match(regex.letternumberspacecoma)) {
            seterrdescb(true)
            seterrdesc("Enter Valid Description")
            isvalid = false
        } else {
            seterrdescb(false)
            seterrdesc("")
        }


        if (cat === "") {
            seterrcatb(true)
            seterrcat("Please Select Relation")
            isvalid = false

        } else {
            seterrcatb(false)
            seterrcat("")

        }


        return isvalid;

    }
    return (
        <>
            <NavBarAdmin
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div
                        id="carouselExampleIndicators"
                        className="carousel slide"
                        data-bs-ride="carousel"
                    >
                        <div className="carousel-indicators">
                            <button
                                type="button"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide-to="0"
                                className="active"
                                aria-current="true"
                                aria-label="Slide 1"
                            ></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img
                                    src={login}
                                    className="d-block w-100"
                                    alt="Max-width 100%"
                                />
                            </div>
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="prev"
                        >
                            <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                            ></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="next"
                        >
                            <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                            ></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="container">
                <h1>
                    <div className="abc text-white text-center col 10 p-1 mt-2">
                        <h3> Edit Gallery Details </h3>
                    </div>
                </h1>
                <Box
                    component="form"
                    sx={{
                        marginTop: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <div>
                                <TextField
                                    error={errcatb}
                                    helperText={errcat}
                                    margin="normal"
                                    required
                                    fullWidth
                                    disabled
                                    InputProps={{
                                        readOnly: true,
                                      }}
                                    id="outlined-name"
                                    label="Gallery ID"
                                    //defaultValue={userdata.state_name}
                                    value={id}
                                    //onChange={(e) => setcat(e.target.value)}
                                >
                                </TextField>
                            </div>

                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                <TextField
                                    error={errcatb}
                                    helperText={errcat}
                                    margin="normal"
                                    required
                                    fullWidth
                                    select
                                    id="outlined-name"
                                    label="Select Gallery Category"
                                    //defaultValue={userdata.state_name}
                                    value={cat}
                                    onChange={(e) => setcat(e.target.value)}
                                >
                                    {gallerycat.map((option) => (
                                        <MenuItem
                                            key={option.gallery_category_id}
                                            value={option.gallery_category_id}
                                        >
                                            {option.gallery_category_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                        </Grid>

                        <Grid item xs={12}>
                            <div>
                                <TextField
                                    error={errdescb}
                                    margin="normal"
                                    required
                                    fullWidth
                                    multiline
                                    rows={4}
                                    id="outlined-name"
                                    label="Enter Image Description"
                                    helperText={errdesc}
                                    //defaultValue={userdata.state_name}
                                    value={desc}
                                    onChange={(e) => setdesc(e.target.value)}
                                />
                            </div>
                        </Grid>


                    </Grid>
                    <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
                        <Button variant="contained" onClick={handlesubmitclick}>
                            Submit
                        </Button>
                    </Grid>
                </Box>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={snackmsg}
                //action={action}
                />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdrop}
                    onClick={handlebackdropClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    )
}
