import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import login from "../../images/login.jpg";
import NavbarUser from "../NavbarUser";
import NavBarAdmin from "../NavBarAdmin";
import config from "../config.json";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrashCan,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Avatar from "@mui/material/Avatar";
import NavBarFaculty from "../NavBarFaculty";

import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from "@mui/material";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { decrypter } from "../../Util"

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStyle = makeStyles({
  root: {
      "& .MuiDataGrid-columnHeaderTitle": {
          textOverflow: "clip",
          whiteSpace: "pre-wrap",
          lineHeight: 2,
          textAlign: "center"
      },
      "& .MuiDataGrid-cellContent": {
          textOverflow: "clip",
          whiteSpace: "pre-wrap",
          lineHeight: 1,
          textAlign: "center"
      },
      boxShadow: 2,
      '& .MuiTablePagination-selectLabel': {
          marginTop: 'auto'
      },
      '& .MuiTablePagination-displayedRows': {
          marginTop: 'auto'
      },
      "& .MuiTypography-string": {
          textOverflow: "clip",
          whiteSpace: "pre-wrap",
          lineHeight: 2,
          textAlign: "center"
      },
  
  }
});

export default function ManageFaculty() {
  let userdata = decrypter(cookies.get("userdata"))
  const navigate = useNavigate();

  const classes = useStyle();

  const [faculties, setfaculties] = useState([]);
  const [click, setclick] = useState(false);
  const [open, setOpen] = useState(false);
  const [del, setdel] = useState("");
  const [dialog, setdialog] = useState(false);

  const [modalopen, setmodalopen] = useState(false)

  const [bid, setbid] = useState('')
  const [img, setimg] = useState('')
  const [bname, setbname] = useState('')

  const [preview, setpreview] = useState()
  const [filename, setFilename] = useState("");
  const [file, setfile] = useState();
  const [btncolor, setbtncolor] = useState("primary")
  const [errbtn, seterrbtn] = useState("")

  const [msg, setmsg] = useState('')

  const columns = [
    {
      field: 'faculty_sys_id', headerName: 'ID', flex: 0.5, align: 'center', headerAlign: 'center'
    },

    {
      field: 'Image', headerName: 'Image', flex: 0.5, align: 'center', headerAlign: 'center',
      renderCell: (cellValues) => {
        if (cellValues.row.faculty_image === null) {
          return (
            <Avatar alt={cellValues.row.faculty_name}
              src={cellValues.row.faculty_name}
              sx={{ width: 40, height: 40 }}
              variant="rounded"
              onClick={(event) => {
                handleimageclick(event, cellValues.row);
              }} />
          );
        }
        else {
          return (
            <Avatar alt={cellValues.row.faculty_name}
              src={BASE_URL + "/" + "faculty" + "/" + cellValues.row.faculty_image}
              sx={{ width: 40, height: 40 }}
              variant="rounded"
              onClick={(event) => {
                handleimageclick(event, cellValues.row);
              }} />
          );
        }
      }
    },

    {
      field: 'faculty_emp_id', headerName: 'Employee ID', flex: 1, align: 'center', headerAlign: 'center'
    },
    {
      field: 'faculty_name', headerName: 'Name', flex: 1.5, align: 'center', headerAlign: 'center'
    },
    {
      field: 'faculty_designation', headerName: 'Designation', flex: 1, align: 'center', headerAlign: 'center'
    },
    {
      field: 'faculty_email_id', headerName: 'Email ID', flex: 1.5, align: 'center', headerAlign: 'center'
    },
    {
      field: 'faculty_mobile_no', headerName: 'Phone', flex: 1, align: 'center', headerAlign: 'center'
    },
    
    {
      field: 'updated_date', headerName: 'Updated Date', flex: 1.5, align: 'center', headerAlign: 'center'
    },
    {
      field: "Action",
      renderCell: (cellValues) => {
        return (
          <>
            <IconButton
              //   variant="contained"
              //   color="primary"
              //   size='medium'
              //   startIcon={<EditIcon></EditIcon>}

              onClick={(event) => {
                handleEditClick(event, cellValues);
              }}
            >
              <EditIcon></EditIcon>
            </IconButton>
            {/* <IconButton
              //   variant="contained"
              //   color="primary"
              //   size='medium'
              //   startIcon={<EditIcon></EditIcon>}

              onClick={(event) => {
                handleClickOpen(event, cellValues);
              }}
            >
              <DeleteIcon></DeleteIcon>
            </IconButton> */}
          </>
        );
      }, flex: 0.5, align: 'center', headerAlign: 'center'
    },
    {
      field: "View",
      renderCell: (cellValues) => {
        return (
          <IconButton
            //   variant="contained"
            //   color="primary"
            //   size='medium'
            //   startIcon={<EditIcon></EditIcon>}

            onClick={(event) => {
              handleViewOpen(event, cellValues);
            }}
          >
            <DesktopWindowsIcon></DesktopWindowsIcon>
          </IconButton>
        );
      }, flex: 0.5, align: 'center', headerAlign: 'center'
    }

  ]

  useEffect(() => {

    if (userdata === "false") {

      navigate('/')

    }

    const headers = {
      publicKey: KEY,
    };

    const params1 = new URLSearchParams();
    params1.append("center_id", userdata.center_id);

    if (userdata.role_id == 5) {
      axios
        .post(BASE_URL + "/viewFacultyAdmin", {}, { headers: headers })
        .then(function (responses) {
          console.log("Faculties", responses.data.Faculties);
          //setunmapped(responses.data.Beneficiaries)
          setfaculties(responses.data.Faculties);
        })
        .catch(function (errors) { });

    } else {
      axios
        .post(BASE_URL + "/viewFaculty", params1, { headers: headers })
        .then(function (responses) {
          console.log("Faculties", responses.data.Faculties);
          //setunmapped(responses.data.Beneficiaries)
          setfaculties(responses.data.Faculties);
        })
        .catch(function (errors) { });
    }




  }, [click]);

  useEffect(() => {
    if (!file) {
      setpreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(file)
    setpreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [file])

  const handleimageclick = (event, image) => {

    setmodalopen(true)
    setbid(image.faculty_sys_id)
    setimg(image.faculty_image)
    setbname(image.faculty_name)




  }

  const handlefilechange = (e) => {
    //const [files] = e.target.files;
    //console.log("data", files);
    setFilename(e.target.files[0].name);
    setfile(e.target.files[0]);



    //setFilename(e.target.files);
  };
  console.log("data from file value", file);

  const handlemodalOpen = () => {
    setmodalopen(true);
  };
  const handlemodalClose = () => {
    setmodalopen(false);
  };

  const handleCloseDialog = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setdialog(false);
  };

  const handleClickOpen = (event, cellValues) => {
    setOpen(true);
    setdel(cellValues.row.faculty_sys_id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewOpen = (event, cellValues) => {
    navigate("/viewFaculty", {
      state: {
        data: cellValues.row,
      },
    });
  };

  const handleEditClick = (event, cellValues) => {
    navigate('/editFaculty', {
      state: {
        fid: cellValues.row.faculty_sys_id, emp: cellValues.row.faculty_emp_id,
        name: cellValues.row.faculty_name, desg: cellValues.row.faculty_designation,
        email: cellValues.row.faculty_email_id, mob: cellValues.row.faculty_mobile_no,
        qual: cellValues.row.faculty_Qualification, add: cellValues.row.faculty_address,
        cid: cellValues.row.centerId

      }
    })
  };

  const handleDelete = () => {
    const headers = {
      publicKey: KEY,
    };

    console.log("id", del);

    const data = new FormData();
    data.append('model', JSON.stringify({
      faculty_sys_id: del
    }))

    axios.post(BASE_URL + '/delFaculty', data, { headers: headers })
      .then(function (responses) {

        console.log(responses.data)
        if (responses.data === 'OK') {

          setclick(true)
          setmsg("Faculty Deleted")
          setdialog(true);
        }
      })
      .catch(function (errors) {

      })
    setOpen(false);
    setclick(false);
  };

  const handlesubmitclick = () => {

    console.log("valid", validation())

    if (validation()) {

      const headers = {
        publicKey: KEY,

      };

      const data = new FormData();
      data.append("file", file);

      data.append(
        "model",
        JSON.stringify({
          faculty_sys_id: bid,
          updated_by: userdata.faculty_sys_id
        })
      );

      axios
        .post(BASE_URL + "/updateimgFaculty", data, { headers: headers })
        .then(function (response) {
          console.log(response.data);
          if (response.data === "OK") {
            setmsg("Image Updated")
            setdialog(true);
            setTimeout(function () {
              window.location.reload(false);
            }, 3000);

          }
        })
        .catch(function (errors) { });

    }

  }

  function validation() {
    let isvalid = true

    if (file === undefined) {
      setbtncolor("error")
      seterrbtn("Please Upload Image")
      isvalid = false

    } else {
      setbtncolor("primary")
      seterrbtn("")


    }

    return isvalid
  }

  return (
    <>
      {userdata.role_id == 1 ? <NavbarUser
        title={TITLE}
        name={userdata.faculty_name}
        role={userdata.role_name}
        state={userdata.district_name}
      /> : userdata.role_id == 5 ? <NavBarAdmin
        title={TITLE}
        name={userdata.faculty_name}
        role={userdata.role_name}
        state={userdata.district_name}
      /> : <NavBarFaculty
        title={TITLE}
        name={userdata.faculty_name}
        role={userdata.role_name}
        state={userdata.district_name}
        image={userdata.faculty_image}
      />}

      {/* carousel starts */}
      <div className="row">
        <div className="container ">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={login}
                  className="d-block w-100"
                  alt="Max-width 100%"
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </button>
          </div>
        </div>
      </div>
      {/* carousel ends */}

      <div className="row mx-5 ">
        <div className="container">
          <h1>
            <div className="abc text-white text-center col 10 p-1 mt-1">
              <h3> Faculty Details </h3>
            </div>
          </h1>
          <Box
            justifyContent='center'
            sx={{ height: 400, width: '100%' }}>
            <DataGrid
              className={classes.root}
              rows={faculties}
              getRowId={(r) => r.faculty_sys_id}
              columns={columns}
              pageSize={5}
              components={{ Toolbar: GridToolbar }}
              rowsPerPageOptions={[5]}
              disableVirtualization
            //checkboxSelection={true}
            // onSelectionModelChange={(newSelectionModel) => {
            //     setSelectedRows(newSelectionModel);
            // }}

            />
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"ARE YOU SURE YOU WANT TO DELETE?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Once deleted it cannot be undone
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDelete}>YES</Button>
              <Button onClick={handleClose} autoFocus>
                NO
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={dialog}
            autoHideDuration={6000}
            onClose={handleCloseDialog}
            message={msg}
          //action={action}
          />
          <Modal
            open={modalopen}
            onClose={handlemodalClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={style}>
              <Avatar alt={bname}
                src={BASE_URL + "/" + "faculty" + "/" + img}
                sx={{ width: 100, height: 100 }}
                variant="circular"

              />
              <Grid item xs={4}>
                <div>
                  <Button
                    margin="normal"
                    fullWidth
                    required
                    component="label"
                    variant="outlined"
                    sx={{ mt: 2 }}
                    startIcon={<FontAwesomeIcon icon={faFileArrowUp} />}
                    color={btncolor}
                    helperText={errbtn}
                  >
                    Upload Image (jpg, jpeg & png )

                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      name={file}
                      hidden
                      onChange={handlefilechange}
                    />
                  </Button>
                </div>
                {filename} <img src={preview} alt="" width="100" height="100" />
              </Grid>
              <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
                <Button variant="contained" onClick={handlesubmitclick}>
                  Submit
                </Button>
              </Grid>

            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
}
