import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../NavbarUser';
import config from '../config.json';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from "@mui/x-date-pickers";

import TextField from "@mui/material/TextField";


import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import Chip from '@mui/material/Chip';
import { CheckCircle, Cancel } from '@mui/icons-material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan, faPlus, faFilePen } from '@fortawesome/free-solid-svg-icons'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Avatar from '@mui/material/Avatar';
import NavBarFaculty from '../NavBarFaculty';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from "@mui/material";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
//import { Document, Page, ReactPDF } from "react-pdf";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import CircularProgress from '@mui/material/CircularProgress';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Backdrop from '@mui/material/Backdrop';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { decrypter } from "../../Util"
import moment from 'moment';

import NavBarAdmin from '../NavBarAdmin';

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

export default function UpdateBenCertificate() {

    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();

    const [pageSize, setPageSize] = useState(10);
    const [pageSize2, setPageSize2] = useState(10);

    const [value, setValue] = useState('1');
    const [bens, setbens] = useState([]);
    const [yesbens, setyesbens] = useState([])
    const [nobens, setnobens] = useState([]);

    const [date, setdate] = useState(null);
    const [errdate, seterrdate] = useState("")
    const [errdateb, seterrdateb] = useState(false)

    const [date2, setdate2] = useState(null);
    const [errdate2, seterrdate2] = useState("")
    const [errdateb2, seterrdateb2] = useState(false)

    const [selectionModel, setSelectionModel] = useState([]);
    const [selectionModel2, setSelectionModel2] = useState([]);

    const [alertopen, setalertopen] = useState(false);
    const [alertopen2, setalertopen2] = useState(false);

    const [backdrop, setbackdrop] = useState(false);
    const [snackopen, setsnackopen] = useState(false)
    const [snackmsg, setsnackmsg] = useState("")


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const columns = [
        {
            field: 'ben_id', headerName: 'ID', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'enrollment_id', headerName: 'Enrollment ID', flex: 1, align: 'center', headerAlign: 'center'
        },

        {
            field: 'Image', headerName: 'Image', flex: 0.5, align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.ben_image === null) {
                    return (
                        <Avatar alt={cellValues.row.ben_name}
                            src={cellValues.row.ben_name}
                            sx={{ width: 40, height: 40 }}
                            variant="circular"
                        />
                    );
                }
                else {
                    return (
                        <Avatar alt={cellValues.row.ben_name}
                            src={BASE_URL + "/" + "images" + "/" + cellValues.row.ben_image}
                            sx={{ width: 40, height: 40 }}
                            variant="circular"
                        />
                    );
                }
            }
        },

        {
            field: 'center_name', headerName: 'Center Name', flex: 1.2, align: 'center', headerAlign: 'center'
        },
        {
            field: 'ben_name', headerName: 'Name', flex: 1.5, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (<Typography variant="string" textTransform="uppercase">
                    {params.row.ben_name}
                </Typography>)
            }
        },
        {
            field: 'ben_dob', headerName: 'DOB', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (<Typography variant="string" textTransform="uppercase">
                    {moment(params.row.ben_dob).format('DD/MM/YYYY')}
                </Typography>)
            }
        },
        {
            field: 'session_name', headerName: 'Session Name', flex: 1, align: 'center', headerAlign: 'center'
        },
        // {
        //     field: 'ben_email_id', headerName: 'Email ID', flex: 1.5, align: 'center', headerAlign: 'center',
        //     valueGetter: (cellValues) => {
        //         let result = [];
        //         if (cellValues.row.ben_email_id) {
        //             if (cellValues.row.ben_email_id) {

        //                 result.push(cellValues.row.ben_email_id);
        //             }
        //         }
        //         else {
        //             result = ["Email Not Provided"]
        //         }
        //         return result;
        //     }
        // },

        // {
        //     field: 'certificate_issued', headerName: 'Certificate Issued', flex: 1, align: 'center', headerAlign: 'center',
        //     renderCell: (cellValues) => {
        //         if (cellValues.row.certificate_issued === "Y") {
        //             return (
        //                 // <IconButton
        //                 // //   variant="contained"
        //                 // //   color="primary"
        //                 // //   size='medium'
        //                 // //   startIcon={<EditIcon></EditIcon>}

        //                 // // onClick={(event) => {
        //                 // //     handleClickOpen(event, cellValues);
        //                 // // }}
        //                 // >
        //                 //     <CheckCircle sx={{ color: "green" }}></CheckCircle>
        //                 // </IconButton>
        //                 <>
        //                     <Chip icon={<CheckCircle></CheckCircle>} color="success" label="Issued" variant="outlined" />

        //                 </>

        //             );
        //         }
        //         else {
        //             return (
        //                 // <IconButton
        //                 // //   variant="contained"
        //                 // //   color="primary"
        //                 // //   size='medium'
        //                 // //   startIcon={<EditIcon></EditIcon>}

        //                 // // onClick={(event) => {
        //                 // //     handleClickOpen(event, cellValues);
        //                 // // }}
        //                 // >
        //                 //     <Cancel sx={{color: "red"}}></Cancel>
        //                 // </IconButton>
        //                 <>
        //                     <Chip icon={<Cancel ></Cancel>} color="error" label="Not Issued" variant="outlined" />
        //                 </>

        //             );
        //         }
        //     }
        // }

    ]

    const columns2 = [
        {
            field: 'ben_id', headerName: 'ID', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'enrollment_id', headerName: 'Enrollment ID', flex: 1, align: 'center', headerAlign: 'center'
        },

        {
            field: 'Image', headerName: 'Image', flex: 0.5, align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.ben_image === null) {
                    return (
                        <Avatar alt={cellValues.row.ben_name}
                            src={cellValues.row.ben_name}
                            sx={{ width: 40, height: 40 }}
                            variant="circular"
                        />
                    );
                }
                else {
                    return (
                        <Avatar alt={cellValues.row.ben_name}
                            src={BASE_URL + "/" + "images" + "/" + cellValues.row.ben_image}
                            sx={{ width: 40, height: 40 }}
                            variant="circular"
                        />
                    );
                }
            }
        },

        {
            field: 'center_name', headerName: 'Center Name', flex: 1.2, align: 'center', headerAlign: 'center'
        },
        {
            field: 'ben_name', headerName: 'Name', flex: 1.5, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (<Typography variant="string" textTransform="uppercase">
                    {params.row.ben_name}
                </Typography>)
            }
        },
        {
            field: 'ben_dob', headerName: 'DOB', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (<Typography variant="string" textTransform="uppercase">
                    {moment(params.row.ben_dob).format('DD/MM/YYYY')}
                </Typography>)
            }
        },
        {
            field: 'session_name', headerName: 'Session Name', flex: 1, align: 'center', headerAlign: 'center'
        },
        // {
        //     field: 'ben_email_id', headerName: 'Email ID', flex: 1.5, align: 'center', headerAlign: 'center',
        //     valueGetter: (cellValues) => {
        //         let result = [];
        //         if (cellValues.row.ben_email_id) {
        //             if (cellValues.row.ben_email_id) {

        //                 result.push(cellValues.row.ben_email_id);
        //             }
        //         }
        //         else {
        //             result = ["Email Not Provided"]
        //         }
        //         return result;
        //     }
        // },

        // {
        //     field: 'certificate_issued', headerName: 'Certificate Issued', flex: 1, align: 'center', headerAlign: 'center',
        //     renderCell: (cellValues) => {
        //         if (cellValues.row.certificate_issued === "Y") {
        //             return (
        //                 // <IconButton
        //                 // //   variant="contained"
        //                 // //   color="primary"
        //                 // //   size='medium'
        //                 // //   startIcon={<EditIcon></EditIcon>}

        //                 // // onClick={(event) => {
        //                 // //     handleClickOpen(event, cellValues);
        //                 // // }}
        //                 // >
        //                 //     <CheckCircle sx={{ color: "green" }}></CheckCircle>
        //                 // </IconButton>
        //                 <>
        //                     <Chip icon={<CheckCircle></CheckCircle>} color="success" label="Issued" variant="outlined" />

        //                 </>

        //             );
        //         }
        //         else {
        //             return (
        //                 // <IconButton
        //                 // //   variant="contained"
        //                 // //   color="primary"
        //                 // //   size='medium'
        //                 // //   startIcon={<EditIcon></EditIcon>}

        //                 // // onClick={(event) => {
        //                 // //     handleClickOpen(event, cellValues);
        //                 // // }}
        //                 // >
        //                 //     <Cancel sx={{color: "red"}}></Cancel>
        //                 // </IconButton>
        //                 <>
        //                     <Chip icon={<Cancel ></Cancel>} color="error" label="Not Issued" variant="outlined" />
        //                 </>

        //             );
        //         }
        //     }
        // }

    ]

    useEffect(() => {

        if (userdata === "false") {

            navigate('/')

        }

        const headers = {
            'publicKey': KEY

        }

        if (userdata.role_id == 5) {



            axios.post(BASE_URL + '/getAllBeneficiariesAdmin', {}, { headers: headers })
                .then(function (responses) {

                    console.log("Beneficiaries", responses.data.Beneficiaries)
                    //setunmapped(responses.data.Beneficiaries)
                    //setbens(responses.data.Beneficiaries)
                    setyesbens(responses.data.Beneficiaries.filter(op => op.certificate_issued === "Y"))
                    setnobens(responses.data.Beneficiaries.filter(op => op.certificate_issued === "N"))



                })
                .catch(function (errors) {

                })

        } else {

            const params = new URLSearchParams();
            params.append("center_id", userdata.center_id);

            axios.post(BASE_URL + '/getAllBeneficiaries', params, { headers: headers })
                .then(function (responses) {

                    console.log("Beneficiaries", responses.data.Beneficiaries)
                    //setunmapped(responses.data.Beneficiaries)
                    //setbens(responses.data.Beneficiaries)
                    setyesbens(responses.data.Beneficiaries.filter(op => op.certificate_issued === "Y"))
                    setnobens(responses.data.Beneficiaries.filter(op => op.certificate_issued === "N"))



                })
                .catch(function (errors) {

                })

        }




    }, [])

    const handleclick = () => {

        setbackdrop(true)
        console.log("issued", selectionModel.length)
        console.log("issued", selectionModel)

        const headers = {
            publicKey: KEY,

        };


        if (validation()) {
            console.log("in validation")
            const data = new FormData();
            data.append("model",
                JSON.stringify({
                    ids: selectionModel,
                    status: "N",
                    date: moment(new Date(date)).format("YYYY-MM-DD")
                }))

            console.log("isue tab", JSON.stringify({
                ids: selectionModel,
                status: "N",
                date: moment(new Date(date)).format("YYYY-MM-DD")
            }))

            axios
                .post(BASE_URL + "/updateCertStatus", data, { headers: headers })
                .then(function (response) {
                    console.log(response);
                    if (response.data === "OK" && response.status == 200) {
                        setbackdrop(false)
                        setsnackmsg("Certification Revoked Successfully")
                        setsnackopen(true);
                        setTimeout(function () {
                            window.location.reload(false);
                        }, 3000);



                    }
                    else {
                        setbackdrop(false)

                        setsnackmsg("Certification Revoked Failed")
                        setsnackopen(true);
                    }
                })
                .catch(function (errors) {
                    setbackdrop(false)
                    setsnackmsg("Certification Revoked Failed")
                    setsnackopen(true);
                });
        }

    }

    const handleclick2 = () => {


        console.log("not issued", selectionModel2.length)
        console.log("not issued", selectionModel2)

        const headers = {
            publicKey: KEY,

        };


        if (validation2()) {
            console.log("in validation2")
            const data = new FormData();
            data.append("model",
                JSON.stringify({
                    ids: selectionModel2,
                    status: "Y",
                    date: moment(new Date(date2)).format("YYYY-MM-DD")
                }))

            console.log("not issue tab", JSON.stringify({
                ids: selectionModel2,
                status: "Y",
                date: moment(new Date(date2)).format("YYYY-MM-DD")
            }))

            axios
                .post(BASE_URL + "/updateCertStatus", data, { headers: headers })
                .then(function (response) {
                    console.log(response);
                    if (response.data === "OK" && response.status == 200) {
                        setbackdrop(false)
                        setsnackmsg("Certification Issued Successfully")
                        setsnackopen(true);
                        setTimeout(function () {
                            window.location.reload(false);
                        }, 3000);



                    }
                    else {
                        setbackdrop(false)

                        setsnackmsg("Certification Issued Failed")
                        setsnackopen(true);
                    }
                })
                .catch(function (errors) {
                    setbackdrop(false)
                    setsnackmsg("Certification Issued Failed")
                    setsnackopen(true);
                });


        }
    }

    function validation() {
        let isvalid = true;
        if (moment(new Date(date)).format("YYYY-MM-DD") === "1970-01-01") {
            seterrdateb(true)
            seterrdate("Please Enter Date")
            isvalid = false

        } else {
            seterrdateb(false)
            seterrdate("")
        }

        if (selectionModel.length == 0) {
            setalertopen(true)
            isvalid = false
            handleAlertClose()

        } else {
            setalertopen(false)
        }

        return isvalid;
    }

    function validation2() {
        let isvalid = true;
        if (moment(new Date(date2)).format("YYYY-MM-DD") === "1970-01-01") {
            seterrdateb2(true)
            seterrdate2("Please Enter Date")
            isvalid = false

        } else {
            seterrdateb2(false)
            seterrdate2("")
        }

        if (selectionModel2.length == 0) {
            setalertopen(true)
            isvalid = false
            handleAlertClose()

        } else {
            setalertopen(false)
        }

        return isvalid;
    }

    const handleAddBatch = (e) => {
        e.preventDefault();
        navigate('/manageCertificate')
    }

    const handleAlertClose = () => {
        setTimeout(function () {
            setalertopen(false)
        }, 2000);
    }

    const handleSnackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setsnackopen(false);
    };

    const handlebackdropClose = () => {
        setbackdrop(false);
    };



    return (
        <>
            {userdata.role_id == 1 ? <NavbarUser
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
            /> : userdata.role_id == 5 ? <NavBarAdmin
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
            /> : <NavBarFaculty
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />}
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="row mx-5 mb-5">
                <div className="container">
                    <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<FontAwesomeIcon icon={faPlus} className="me-1" />} onClick={handleAddBatch} >Manage Certificate</Button>

                    <Box sx={{ width: '100%', typography: 'body1', boxShadow: 5, marginTop: 1, background: "#fafafa" }}>

                        <Collapse in={alertopen} >

                            <Alert severity="error" >
                                <AlertTitle>Error</AlertTitle>
                                <strong>No Beneficiaries Selected</strong>
                            </Alert>

                        </Collapse>

                        <TabContext value={value}>

                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} centered aria-label="lab API tabs example">
                                    <Tab label="Certificate Issued" value="1" />
                                    <Tab label="Certificate Not Issued" value="2" />

                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <Box
                                    justifyContent='center'
                                    //</TabPanel>sx={{ height: 700, width: '100%' }}
                                    >

                                    <DataGrid
                                        sx={{
                                            boxShadow: 2,
                                            '& .MuiTablePagination-selectLabel': {
                                                marginTop: 'auto'
                                            },
                                            '& .MuiTablePagination-displayedRows': {
                                                marginTop: 'auto'
                                            }
                                        }}
                                        rows={yesbens}
                                        getRowId={(r) => r.ben_id}
                                        columns={columns}
                                        components={{ Toolbar: GridToolbar }}
                                        autoHeight
                                        pageSize={pageSize}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        pagination
                                        rowsPerPageOptions={[10, 25, 50, 100]}

                                        checkboxSelection={true}
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setSelectionModel(newSelectionModel);
                                        }}
                                        selectionModel={selectionModel}

                                    // onSelectionModelChange={(selection) => {
                                    //     if (selection.length > 1) {
                                    //         const selectionSet = new Set(selectionModel);
                                    //         const result = selection.filter((s) => !selectionSet.has(s));

                                    //         setSelectionModel(result);
                                    //     } else {
                                    //         setSelectionModel(selection);
                                    //     }
                                    // }}


                                    />
                                    <Grid container spacing={2} sx={{ justifyItems: 'center', alignItems: 'center' }}>

                                        <Grid item xs={4}>
                                            <div>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker

                                                        sx
                                                        label="Date"
                                                        inputFormat="dd/MM/yyyy"
                                                        utcOffset={0}
                                                        value={date}
                                                        disableFuture

                                                        onChange={(newValue) => {
                                                            // const offsetDate = new Date(newValue.getTime() - (newValue.getTimezoneOffset() * 60000));
                                                            // //const offsetDate = moment(new Date(newValue.getTime() - (newValue.getTimezoneOffset() * 60000))).format("YYYY-MM-DD");
                                                            // setdate(offsetDate);
                                                            setdate(newValue);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField  {...params}
                                                                onKeyDown={e => e.preventDefault()}
                                                                fullWidth sx={{ mt: 2 }}
                                                                required
                                                                error={errdateb}
                                                                helperText={errdate}

                                                            />

                                                        )}
                                                    //onChangeRaw={handleDateChangeRaw}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </Grid>
                                        <Grid item xs={8}></Grid>
                                        <Grid item xs={4}>

                                            <Button variant="contained" onClick={handleclick} sx={{ marginTop: 4 }}>
                                                Revoke Certificate
                                            </Button>

                                        </Grid>


                                    </Grid>

                                </Box>
                            </TabPanel>
                            <TabPanel value="2">
                                <Box
                                    justifyContent='center'
                                    //sx={{ height: 700, width: '100%' }}
                                    >

                                    <DataGrid
                                        sx={{
                                            boxShadow: 2,
                                            '& .MuiTablePagination-selectLabel': {
                                                marginTop: 'auto'
                                            },
                                            '& .MuiTablePagination-displayedRows': {
                                                marginTop: 'auto'
                                            }
                                        }}
                                        rows={nobens}
                                        getRowId={(r) => r.ben_id}
                                        columns={columns2}
                                        components={{ Toolbar: GridToolbar }}
                                        autoHeight
                                        pageSize={pageSize2}
                                        onPageSizeChange={(newPageSize) => setPageSize2(newPageSize)}
                                        pagination
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        checkboxSelection={true}
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setSelectionModel2(newSelectionModel);
                                        }}
                                        selectionModel={selectionModel2}

                                    />
                                    <Grid container spacing={2} sx={{ justifyItems: 'center', alignItems: 'center' }}>

                                        <Grid item xs={4}>
                                            <div>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker

                                                        sx
                                                        label="Date"
                                                        inputFormat="dd/MM/yyyy"
                                                        utcOffset={0}
                                                        value={date2}
                                                        

                                                        onChange={(newValue) => {
                                                            // const offsetDate = new Date(newValue.getTime() - (newValue.getTimezoneOffset() * 60000));
                                                            // //const offsetDate = moment(new Date(newValue.getTime() - (newValue.getTimezoneOffset() * 60000))).format("YYYY-MM-DD");
                                                            // setdate(offsetDate);
                                                            setdate2(newValue);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField  {...params}
                                                                onKeyDown={e => e.preventDefault()}
                                                                fullWidth sx={{ mt: 2 }}
                                                                required
                                                                error={errdateb2}
                                                                helperText={errdate2}

                                                            />

                                                        )}
                                                    //onChangeRaw={handleDateChangeRaw}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </Grid>
                                        <Grid item xs={8}></Grid>
                                        <Grid item xs={4}>

                                            <Button variant="contained" onClick={handleclick2} sx={{ marginTop: 4 }}>
                                                Issue Certificate
                                            </Button>

                                        </Grid>


                                    </Grid>

                                </Box>

                            </TabPanel>
                        </TabContext>

                    </Box>

                </div>
                <Snackbar
                    open={snackopen}
                    autoHideDuration={6000}
                    onClose={handleSnackClose}
                    message={snackmsg}
                //action={action}
                />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdrop}
                    onClick={handlebackdropClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    )
}
