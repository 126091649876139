import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../images/login.jpg';

import config from '../components/config.json';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileOpenIcon from '@mui/icons-material/FileOpen';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan, faPlus, faFilePen } from '@fortawesome/free-solid-svg-icons'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Avatar from '@mui/material/Avatar';
import NavBarFaculty from '../components/NavBarFaculty.js';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from "@mui/material";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
//import { Document, Page, ReactPDF } from "react-pdf";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import Alert from '@mui/material/Alert';

import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { decrypter } from "../Util.js"
import moment from 'moment';
import NavBarAdmin from '../components/NavBarAdmin';
import { CheckCircle, Cancel } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

};

export default function Gallery() {
    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();

    const [gallery, setgallery] = useState([]);
    const [open, setOpen] = useState(false);
    const [click, setclick] = useState(false);
    const [del, setdel] = useState('')
    const [dialog, setdialog] = useState(false)
    const [loading, setloading] = useState(false)
    const [snack, setsnack] = useState('')
    const [val, setval] = useState()
    const [modalopen, setmodalopen] = useState(false)

    const [preview, setpreview] = useState()
    const [filename, setFilename] = useState("");
    const [file, setfile] = useState();
    const [btncolor, setbtncolor] = useState("primary")
    const [errbtn, seterrbtn] = useState("")
    const [openalertimg, setopenalertimg] = useState(false)
    const [imagealert, setimagealert] = useState("")
    const [hiddenb, sethiddenb] = useState(true)

    const [gid, setgid] = useState('')
    const [iname, setiname] = useState('')
    const [img, setimg] = useState('')



    const columns = [
        {
            field: 'gallery_id', headerName: 'ID', flex: 0.5, align: 'center', headerAlign: 'center'
        },

        {
            field: 'Image', headerName: 'Image', flex: 0.5, align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.img_file === null) {
                    return (
                        <Avatar alt={cellValues.row.image_description}
                            src={cellValues.row.image_description}
                            sx={{ width: 40, height: 40 }}
                            variant="square"
                            onClick={(event) => {
                                handleimageclick(event, cellValues.row);
                            }} />
                    );
                }
                else {
                    return (
                        <Avatar alt={cellValues.row.image_description}
                            src={BASE_URL + "/" + "gallery" + "/" + cellValues.row.gallery_id}
                            sx={{ width: 40, height: 40 }}
                            variant="square"
                            onClick={(event) => {
                                handleimageclick(event, cellValues.row);
                            }} />
                    );
                }
            }
        },

        {
            field: 'image_description', headerName: 'Description', flex: 1.5, align: 'center', headerAlign: 'center'
        },
        {
            field: 'gallery_category_name', headerName: 'Category Name', flex: 1.5, align: 'center', headerAlign: 'center',
            valueGetter: (cellValues) => {
                let result = [];
                if (cellValues.row.galleryCategory) {
                    if (cellValues.row.galleryCategory.gallery_category_name) {

                        if (cellValues.row.galleryCategory.gallery_category_name) {
                            result.push(cellValues.row.galleryCategory.gallery_category_name);
                        }


                    }
                }
                else {
                    result = ["Email Not Provided"]
                }
                return result;
            }
        },
        {
            field: 'updated_date', headerName: 'Updated Date', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: "Action",
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleEditClick(event, cellValues);
                            }}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleClickOpen(event, cellValues);
                            }}
                        >
                            <DeleteIcon></DeleteIcon>
                        </IconButton>

                    </>
                );
            }, flex: 1, align: 'center', headerAlign: 'center'
        },
        // {
        //     field: "View",
        //     renderCell: (cellValues) => {
        //         return (
        //             <IconButton
        //                 //   variant="contained"
        //                 //   color="primary"
        //                 //   size='medium'
        //                 //   startIcon={<EditIcon></EditIcon>}

        //                 onClick={(event) => {
        //                     handleViewOpen(event, cellValues);
        //                 }}
        //             >
        //                 <DesktopWindowsIcon></DesktopWindowsIcon>
        //             </IconButton>
        //         );
        //     }, flex: 0.5, align: 'center', headerAlign: 'center'
        // },
        {
            field: 'status', headerName: 'Status', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.status === "Y") {
                    return (
                        // <IconButton
                        // //   variant="contained"
                        // //   color="primary"
                        // //   size='medium'
                        // //   startIcon={<EditIcon></EditIcon>}

                        // // onClick={(event) => {
                        // //     handleClickOpen(event, cellValues);
                        // // }}
                        // >
                        //     <CheckCircle sx={{ color: "green" }}></CheckCircle>
                        // </IconButton>
                        <>
                            <Chip icon={<CheckCircle></CheckCircle>} color="success" label="Active" variant="outlined" />
                        </>

                    );
                }
                else {
                    return (
                        // <IconButton
                        // //   variant="contained"
                        // //   color="primary"
                        // //   size='medium'
                        // //   startIcon={<EditIcon></EditIcon>}

                        // // onClick={(event) => {
                        // //     handleClickOpen(event, cellValues);
                        // // }}
                        // >
                        //     <Cancel sx={{color: "red"}}></Cancel>
                        // </IconButton>
                        <>
                            <Chip icon={<Cancel ></Cancel>} color="error" label="Inactive" variant="outlined" />
                        </>

                    );
                }
            }
        },
        {
            field: "Activate/Deactivate", align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleactivate(event, cellValues);
                            }}
                        >
                            <CheckCircle sx={{ color: "green" }}></CheckCircle>
                        </IconButton>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handledeactivate(event, cellValues);
                            }}
                        >
                            <Cancel sx={{ color: "red" }}></Cancel>
                        </IconButton>
                    </>
                );
            }, flex: 1
        }

    ]


    useEffect(() => {

        if (userdata === "false") {

            navigate('/')
            
          }

        const headers = {
            'publicKey': KEY

        }

        axios.post(BASE_URL + '/getallgallery', {}, { headers: headers })
            .then(function (responses) {

                //console.log("GalleryItems", responses.data.GalleryItems)
                //setunmapped(responses.data.Beneficiaries)
                setgallery(responses.data.GalleryItems)

            })
            .catch(function (errors) {

            })
    }, [click])

    useEffect(() => {
        if (!file) {
            setpreview(undefined)
            sethiddenb(true)
            return
        }

        const objectUrl = URL.createObjectURL(file)
        sethiddenb(false)
        setpreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [file])

    const handlefilechange = (e) => {
        //const [files] = e.target.files;
        //console.log("data", files);
        setFilename(e.target.files[0].name);
        setfile(e.target.files[0]);



        //setFilename(e.target.files);
    };

    const handlemodalClose = () => {
        setmodalopen(false);
    };

    const handleCloseDialog = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setdialog(false);
    };

    const handleClickOpen = (event, cellValues) => {
        setOpen(true);
        setdel(cellValues.row.gallery_id)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEditClick = (event, cellValues) => {
        navigate('/editgallery', {
            state: {
                id: cellValues.row.gallery_id, cat: cellValues.row.gallery_category_id,
                desc: cellValues.row.image_description
            }
        })

    }

    const handleDelete = () => {

        const headers = {
            'publicKey': KEY
        }

        const data = new FormData();
        data.append('model', JSON.stringify({
            gallery_id: del
        }))

        axios.post(BASE_URL + '/delGallery', data, { headers: headers })
            .then(function (responses) {

                //console.log(responses.data)
                if (responses.data === 'OK' && responses.status == 202) {

                    setclick(true)
                    setdialog(true);
                    setsnack("Gallery Item Deleted")
                }
            })
            .catch(function (errors) {

            })
        setOpen(false);
        setclick(false)

    }

    const handleAddBatch = (e) => {
        e.preventDefault();
        navigate('/addGallery')

    }

    const handleimageclick = (event, image) => {

        setmodalopen(true)
        setgid(image.gallery_id)
        setiname(image.image_description)
        setimg(image.gallery_id)
        // setbid(image.ben_id)
        // setimg(image.ben_image)
        // setbname(image.ben_name)
        // setenrollid(image.enrollment_id)





    }

    const handleactivate = (event, cellValues) => {
        const headers = {
            'publicKey': KEY
        }

        //console.log("activate")
        if (cellValues.row.status === "Y") {
            setsnack(' Item Is Already Active')
            setdialog(true)
        } else {

            setloading(true)
            const data = new FormData();
            data.append('model', JSON.stringify({
                activate: 'Y',
                id: cellValues.row.gallery_id
            }))

            setval(cellValues.row.gallery_id)
            //console.log("val", cellValues.row.gallery_id)
            //console.log("activate")

            axios.post(BASE_URL + '/galleryactivator', data, { headers: headers })
                .then(function (response) {

                    //console.log(response.data)
                    if (response.data === 'OK' && response.status == 200) {

                        setloading(true)
                        setsnack("Item Activated")
                        //setsevere("success")
                        //setalert(true)
                        setdialog(true)
                        setclick(true)




                    }

                })
                .catch(function (errors) {
                    //console.log(errors.response.status)

                    // if (errors.response.status === 403) {

                    //     setalert(true)

                    // }
                })

            //setloading(false)
            setclick(false)




        }
    }

    const handledeactivate = (event, cellValues) => {
        const headers = {
            'publicKey': KEY
        }
        //console.log("deactive")
        if (cellValues.row.status === "N") {
            setsnack(' Item Is Already De-Active')
            setdialog(true)
        } else {

            const data = new FormData();
            data.append('model', JSON.stringify({
                activate: 'N',
                id: cellValues.row.gallery_id
            }))

            setval(cellValues.row.gallery_id)
            //console.log("val", val)
            axios.post(BASE_URL + '/galleryactivator', data, { headers: headers })
                .then(function (response) {

                    //console.log(response.data)
                    if (response.data === 'OK' && response.status == 200) {



                        setloading(true)
                        setsnack("Item Deactivated")
                        //setsevere("success")
                        //setalert(true)
                        setdialog(true)
                        setclick(true)




                    }

                })
                .catch(function (errors) {
                    //console.log(errors.response.status)

                    // if (errors.response.status === 403) {

                    //     setalert(true)

                    // }
                })

            //setloading(false)
            setclick(false)

        }

    }

    const handlesubmitclick = () => {

        //console.log("valid", validation())

        if (validation()) {

            const headers = {
                publicKey: KEY,

            };

            const data = new FormData();
            data.append("file", file);

            data.append(
                "model",
                JSON.stringify({
                    gallery_id: gid,
                    updated_by: userdata.faculty_sys_id
                })
            );

            axios
                .post(BASE_URL + "/updateimggallery", data, { headers: headers })
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data === "OK" && response.status == 202) {
                        setsnack("Image Updated")
                        setdialog(true);
                        setTimeout(function () {
                            window.location.reload(false);
                        }, 3000);

                    }
                })
                .catch(function (errors) { });

        }

    }

    function validation() {
        let isvalid = true

        if (file === undefined) {
            setbtncolor("error")
            seterrbtn("Please Upload Image")
            isvalid = false

        } else {
            setbtncolor("primary")
            seterrbtn("")


        }

        if (file === undefined) {
            setbtncolor("error")
            seterrbtn("Please Upload Image")
            setopenalertimg(true)
            setimagealert("Please Upload Image")
            isvalid = false

        } else if (file.size > 2097152) {

            setbtncolor("error")
            seterrbtn("Image Size is Greater than 2MB")
            setopenalertimg(true)
            setimagealert("Image Size is Greater than 2MB")
            //alert("Image size is greater than 500kb")
            isvalid = false

        } else {
            setbtncolor("primary")
            seterrbtn("")
            setopenalertimg(false)
            setimagealert("")

        }



        return isvalid
    }


    return (
        <>
            <NavBarAdmin
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="container">
                <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<FontAwesomeIcon icon={faPlus} className="me-1" />} onClick={handleAddBatch}>Gallery </Button>

                <h1>
                    <div className="abc text-white text-center col 10 p-1 mt-1">
                        <h3> Gallery Details </h3>
                    </div>
                </h1>
                <Box
                    justifyContent='center'
                    sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={gallery}
                        getRowId={(r) => r.gallery_id}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        components={{ Toolbar: GridToolbar }}

                    // onSelectionModelChange={(newSelectionModel) => {
                    //     setSelectedRows(newSelectionModel);
                    // }}

                    />
                </Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"ARE YOU SURE YOU WANT TO DELETE?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Once deleted it cannot be undone
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDelete}>YES</Button>
                        <Button onClick={handleClose} autoFocus>
                            NO
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={dialog}
                    autoHideDuration={6000}
                    onClose={handleCloseDialog}
                    message={snack}
                //action={action}
                />


            </div >
            <Modal
                open={modalopen}
                onClose={handlemodalClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style}>
                    <Avatar alt={iname}
                        src={BASE_URL + "/" + "gallery" + "/" + img}
                        sx={{ width: 300, height: 300, alignItems: 'center' }}
                        variant="square"

                    />
                    <Grid item xs={4}>
                        <div>
                            <Button
                                margin="normal"
                                fullWidth
                                required
                                component="label"
                                variant="outlined"
                                sx={{ mt: 2 }}
                                startIcon={<FontAwesomeIcon icon={faFileArrowUp} />}
                                color={btncolor}
                                helperText={errbtn}
                            >
                                Upload Image (jpg, jpeg & png ) Less than 2MB

                                <input
                                    type="file"
                                    accept=".jpg,.jpeg,.png"
                                    name={file}
                                    hidden
                                    onChange={handlefilechange}
                                />
                            </Button>
                        </div>
                        {filename} <img src={preview} hidden={hiddenb} alt="" width="300" height="300" />
                    </Grid>
                    <Grid item xs={4}>
                        <Collapse in={openalertimg}>
                            <Alert severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setopenalertimg(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                {imagealert}
                            </Alert>
                        </Collapse>
                    </Grid>
                    <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
                        <Button variant="contained" onClick={handlesubmitclick}>
                            Submit
                        </Button>
                    </Grid>

                </Box>
            </Modal>
        </>
    )
}
