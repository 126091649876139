import React from 'react'
import BaseNavBar from './BaseNavBar'
import Banner1 from '../images/Banner1.jpg'
import Banner2 from '../images/Banner2.jpg'
import team from '../images/team.jpg'
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import e1 from "../images/emblem.png"
import cdac from "../images/cdac.png"
import mission from "../images/mission.jpg"
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faPowerOff, faBullseye } from '@fortawesome/free-solid-svg-icons'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import config from '../components/config.json'
import Avatar from '@mui/material/Avatar';
import profile from '../images/profile.png'
import { Cookies } from 'react-cookie';
import makeStyles from '@mui/styles/makeStyles';
import akash from '../images/akash.jpeg'
import barsa from '../images/barsa.jpg'
import haina from '../images/haina.jpeg'
import hiramoni from '../images/hiramoni.jpeg'
import kamphu from '../images/kamphu.jpg'
import nabaraj from '../images/nabaraj.jpeg'
import nishanta from '../images/nishanta.jpeg'
import sansumwi from '../images/sansumwi.jpeg'

import samaresh from '../images/Samaresh Das.jpeg'
import raja from '../images/Raja Gupta.jpeg'
import manas from '../images/Manas Chakraborty.jpeg'
import manish from '../images/Manish Kumar Saha.jpeg'

import shimantika from '../images/Shimantika Shah.jpeg'
import indrajit from '../images/Indrajit Karmakar.jpeg'
import koustav from '../images/Koustav Chattopadhyay.jpeg'
import poushali from '../images/Poushali Chowdhury.jpg'
import shalony from '../images/Shalony Roy.jpeg'
import souvik from '../images/Souvik Nayak.png'
import kaustav from '../images/Kaustav Das.jpeg'
import indranil from '../images/Indranil Ganguly.jpeg'
import pranab from '../images/Pranab Chakraborty.jpg'

import Slide from 'react-reveal/Slide';



const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

// const useStyles = makeStyles((theme) => ({
//     avatar: {
//         boxShadow: theme.shadows[3],
//     }
// }));



export default function Team() {
    //const classes = useStyles();

    return (
        <>
            <BaseNavBar title={TITLE}></BaseNavBar>
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={team} className="d-block w-100" alt="image1" />
                            </div>
                            {/* <div className="carousel-item">
                                <img src={Banner2} className="d-block w-100" alt="image2" />
                            </div> */}

                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className='container'>

                <Slide bottom >
                    <div className=" text-black text-center col 10 p-1 mt-5 ">
                        <h3> CDAC KOLKATA TEAM </h3>
                    </div>
                    <Box
                        component="form"
                        display="flex"

                        sx={{
                            marginTop: 5,
                            marginBottom: 15,
                            alignItems: 'center'
                            // display: "flex",
                            // flexDirection: "column"


                        }}
                        noValidate
                        autoComplete="off"
                    >


                        <Grid container spacing={6} direction="row"
                            alignItems={"center"}
                            justifyContent={"center"}>

                            
                            
                            {/********************************************** 12 grid start*********************************** */}

                            <Grid item xs={1}  >
                                {/* <Avatar

                                    src={profile}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} /> */}

                            </Grid>
                            <Grid item xs={3}>

                                {/* <Typography sx={{ marginLeft: 4 }}>Indranil Ganguly </Typography> */}
                                
                                

                            </Grid>

                            <Grid item xs={1}  >
                                <Avatar

                                    src={profile}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={3}>

                                <Typography sx={{ marginLeft: 4 }}>Asit Kumar Singh </Typography>
                                <Typography sx={{ marginLeft: 4 }}>Principal Investigator </Typography>
                                
                                

                            </Grid>

                            <Grid item xs={1}  >
                                {/* <Avatar

                                    src={profile}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} /> */}

                            </Grid>
                            <Grid item xs={3}>

                                {/* <Typography sx={{ marginLeft: 4 }}>Manas Chakraborty </Typography> */}
                                
                                

                            </Grid>

                            {/********************************************** 12 grid end*********************************** */}

                            {/********************************************** 12 grid start*********************************** */}

                            <Grid item xs={1}  >
                                <Avatar

                                    src={samaresh}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={3}>

                                <Typography sx={{ marginLeft: 4 }}>Samaresh Das </Typography>
                                
                                

                            </Grid>

                            <Grid item xs={1}  >
                                <Avatar

                                    src={raja}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={3}>

                                <Typography sx={{ marginLeft: 4 }}>Raja Gupta </Typography>
                                
                                

                            </Grid>

                            <Grid item xs={1}  >
                                <Avatar

                                    src={manas}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={3}>

                                <Typography sx={{ marginLeft: 4 }}>Manas Chakraborty </Typography>
                                
                                

                            </Grid>

                            {/********************************************** 12 grid end*********************************** */}

                            {/********************************************** 12 grid start*********************************** */}

                            <Grid item xs={1}  >
                                <Avatar

                                    src={manish}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={3}>

                                <Typography sx={{ marginLeft: 4 }}>Manish Kumar Saha </Typography>
                                
                                

                            </Grid>

                            <Grid item xs={1}  >
                                <Avatar

                                    src={pranab}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={3}>

                                <Typography sx={{ marginLeft: 4 }}>Pranab Ranjan Chakraborty </Typography>
                                
                                

                            </Grid>

                            <Grid item xs={1}  >
                                <Avatar

                                    src={shimantika}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={3}>

                                <Typography sx={{ marginLeft: 4 }}>Shimantika Shah </Typography>
                                
                                

                            </Grid>

                            {/********************************************** 12 grid end*********************************** */}
                            {/********************************************** 12 grid start*********************************** */}

                            <Grid item xs={1}  >
                                <Avatar

                                    src={indrajit}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={3}>

                                <Typography sx={{ marginLeft: 4 }}>Indrajit Karmakar</Typography>
                                
                                

                            </Grid>

                            <Grid item xs={1}  >
                                <Avatar

                                    src={koustav}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={3}>

                                <Typography sx={{ marginLeft: 4 }}>Koustav Chattopadhyay </Typography>
                                
                                

                            </Grid>

                            <Grid item xs={1}  >
                                <Avatar

                                    src={poushali}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={3}>

                                <Typography sx={{ marginLeft: 4 }}>Poushali Chowdhury </Typography>
                                
                                

                            </Grid>

                            {/********************************************** 12 grid end*********************************** */}
                            {/********************************************** 12 grid start*********************************** */}

                            <Grid item xs={1}  >
                                <Avatar

                                    src={shalony}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={3}>

                                <Typography sx={{ marginLeft: 4 }}>Shalony Roy </Typography>
                                
                                

                            </Grid>

                            <Grid item xs={1}  >
                                <Avatar

                                    src={souvik}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={3}>

                                <Typography sx={{ marginLeft: 4 }}>Souvik Nayak </Typography>
                                
                                

                            </Grid>

                            <Grid item xs={1}  >
                                <Avatar

                                    src={kaustav}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={3}>

                                <Typography sx={{ marginLeft: 4 }}>Kaustav Das </Typography>
                                
                                

                            </Grid>

                            {/********************************************** 12 grid end*********************************** */}
                            {/********************************************** 12 grid start*********************************** */}

                            <Grid item xs={1}  >
                                {/* <Avatar

                                    src={profile}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} /> */}

                            </Grid>
                            <Grid item xs={3}>

                                {/* <Typography sx={{ marginLeft: 4 }}>Indranil Ganguly </Typography> */}
                                
                                

                            </Grid>

                            <Grid item xs={1}  >
                                <Avatar

                                    src={indranil}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={3}>

                                <Typography sx={{ marginLeft: 4 }}>Indranil Ganguly </Typography>
                                
                                

                            </Grid>

                            <Grid item xs={1}  >
                                {/* <Avatar

                                    src={profile}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} /> */}

                            </Grid>
                            <Grid item xs={3}>

                                {/* <Typography sx={{ marginLeft: 4 }}>Manas Chakraborty </Typography> */}
                                
                                

                            </Grid>

                            {/********************************************** 12 grid end*********************************** */}
                        </Grid>
                    </Box>
                </Slide>

                <Slide bottom >
                    <div className=" text-black text-center col 10 p-1 mt-5 ">
                        <h3> BAKSA TEAM </h3>
                    </div>
                    <Box
                        component="form"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                        sx={{
                            marginTop: 5,
                            marginBottom: 15
                            // display: "flex",
                            // flexDirection: "column"


                        }}
                        noValidate
                        autoComplete="off"
                    >


                        <Grid container spacing={4} direction="row"
                            alignItems={"center"}
                            justifyContent={"center"}>

                            <Grid item xs={3}>

                            </Grid>

                            <Grid item xs={2}   >
                                <Avatar

                                    src={profile}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'left' }} />

                            </Grid>
                            <Grid item xs={4}  >

                                <Typography sx={{ marginLeft: 4 }}>Sh. Uday Kumar Khakhlary </Typography>
                                <Typography sx={{ marginLeft: 4 }}>The Superintendent, Handloom & Textiles</Typography>
                                <Typography sx={{ marginLeft: 4 }}>Baksa, BTR, Assam</Typography>

                            </Grid>
                            <Grid item xs={3}>

                            </Grid>

                            <Grid item xs={2}  >
                                <Avatar

                                    src={barsa}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={4}>

                                <Typography sx={{ marginLeft: 4 }}>Barsa Sarma </Typography>
                                <Typography sx={{ marginLeft: 4 }}>Project Co-ordinator & Faculty</Typography>
                                <Typography sx={{ marginLeft: 4 }}>Baksa, BTR, Assam</Typography>

                            </Grid>

                            <Grid item xs={2}  >
                                <Avatar

                                    src={hiramoni}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={4}>

                                <Typography sx={{ marginLeft: 4 }}>Hiramoni Baro </Typography>
                                <Typography sx={{ marginLeft: 4 }}>Faculty</Typography>
                                <Typography sx={{ marginLeft: 4 }}>Baksa, BTR, Assam</Typography>

                            </Grid>

                            <Grid item xs={2}  >
                                <Avatar

                                    src={kamphu}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={4}>

                                <Typography sx={{ marginLeft: 4 }}>Kamphu Narzary </Typography>
                                <Typography sx={{ marginLeft: 4 }}>Faculty</Typography>
                                <Typography sx={{ marginLeft: 4 }}>Baksa, BTR, Assam</Typography>

                            </Grid>

                            <Grid item xs={2}  >
                                <Avatar

                                    src={nabaraj}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={4}>

                                <Typography sx={{ marginLeft: 4 }}>Nabaraj Narzary </Typography>
                                <Typography sx={{ marginLeft: 4 }}>Faculty</Typography>
                                <Typography sx={{ marginLeft: 4 }}>Baksa, BTR, Assam</Typography>

                            </Grid>
                        </Grid>
                    </Box>
                </Slide>

                <Slide bottom >
                    <div className=" text-black text-center col 10 p-1 mt-5">
                        <h3> KOKRAJHAR TEAM </h3>
                    </div>
                    <Box
                        component="form"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                        sx={{
                            marginTop: 5,
                            marginBottom: 15
                            // display: "flex",
                            // flexDirection: "column"


                        }}
                        noValidate
                        autoComplete="off"
                    >


                        <Grid container spacing={4} direction="row"
                            alignItems={"center"}
                            justifyContent={"center"}>

                            <Grid item xs={3}>

                            </Grid>

                            <Grid item xs={2}   >
                                <Avatar

                                    src={profile}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'left' }} />

                            </Grid>
                            <Grid item xs={4}  >

                                <Typography sx={{ marginLeft: 4 }}>Smt. Hiranya Devi</Typography>
                                <Typography sx={{ marginLeft: 4 }}>The Superintendent, Handloom & Textiles</Typography>
                                <Typography sx={{ marginLeft: 4 }}>Kokrajhar, BTR, Assam</Typography>

                            </Grid>
                            <Grid item xs={3}>

                            </Grid>

                            <Grid item xs={2}  >
                                <Avatar

                                    src={nishanta}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={4}>

                                <Typography sx={{ marginLeft: 4 }}>Nishanta Das </Typography>
                                <Typography sx={{ marginLeft: 4 }}>Project Co-ordinator & Faculty</Typography>
                                <Typography sx={{ marginLeft: 4 }}>Kokrajhar, BTR, Assam</Typography>

                            </Grid>

                            <Grid item xs={2}  >
                                <Avatar

                                    src={haina}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={4}>

                                <Typography sx={{ marginLeft: 4 }}>Haina Mahilary </Typography>
                                <Typography sx={{ marginLeft: 4 }}>Faculty</Typography>
                                <Typography sx={{ marginLeft: 4 }}>Kokrajhar, BTR, Assam</Typography>

                            </Grid>

                            <Grid item xs={2}  >
                                <Avatar

                                    src={akash}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={4}>

                                <Typography sx={{ marginLeft: 4 }}>Akash Dey </Typography>
                                <Typography sx={{ marginLeft: 4 }}>Faculty</Typography>
                                <Typography sx={{ marginLeft: 4 }}>Kokrajhar, BTR, Assam</Typography>

                            </Grid>

                            <Grid item xs={2}  >
                                <Avatar

                                    src={sansumwi}
                                    sx={{ width: 100, height: 100, boxShadow: 5 }}
                                    variant="circle"
                                    style={{ alignItems: 'center' }} />

                            </Grid>
                            <Grid item xs={4}>

                                <Typography sx={{ marginLeft: 4 }}>Sansumwi Basumatary </Typography>
                                <Typography sx={{ marginLeft: 4 }}>Faculty</Typography>
                                <Typography sx={{ marginLeft: 4 }}>Kokrajhar, BTR, Assam</Typography>

                            </Grid>

                        </Grid>



                    </Box>
                </Slide>

            </div>
        </>
    )
}
