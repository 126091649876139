import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../../components/NavbarUser';
import config from '../config.json';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';

import regex from '../regex.json'
import { decrypter } from "../../Util"



const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;
export default function AddBatch() {



    let userdata = decrypter(cookies.get("userdata"))

    const navigate = useNavigate();
    const [batchname, setbatchname] = useState('')
    const [errbatchname, seterrbatchname] = useState('')
    const [errbatchnameb, seterrbatchnameb] = useState(false)

    const [open, setOpen] = useState(false);
    

    useEffect(() => {
        if (userdata === "false") {

            navigate('/')

        }
    }, [])


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const handlesubmitclick = (e) => {

        if (validation()) {

            const headers = {
                'publicKey': KEY
            }

            const data = new FormData();
            data.append('model', JSON.stringify({
                batch_name: batchname
            }))

            axios.post(BASE_URL + '/addBatch', data, { headers: headers })
                .then(function (responses) {

                    console.log(responses.data)
                    if (responses.data === 'OK') {
                        setOpen(true);
                        setbatchname('')
                    }

                })
                .catch(function (errors) {

                })

        }
    }

    const handleAddBatch = (e) => {
        navigate('/manageBatch')
    }

    function validation() {
        let isvalid = true

        if (batchname == "") {
            seterrbatchnameb(true)
            seterrbatchname("Name Cannot be Empty")
            isvalid = false

        } else if (!batchname.match(regex.letternumberspacecoma)) {
            seterrbatchnameb(true)
            seterrbatchname("Please Enter Valid Data")
            isvalid = false

        } else {
            seterrbatchnameb(false)
            seterrbatchname("")


        }


        return isvalid
    }

    return (
        <>
            <NavbarUser title={TITLE} name={userdata.faculty_name} role={userdata.role_name} state={userdata.district_name} />
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="container">
                <Button variant="contained" sx={{ mt: 2, mb: 2 }} onClick={handleAddBatch}>Manage Batch</Button>
                <h1>
                    <div className="abc text-white text-center col 10 p-1 mt-1">
                        <h3> Add New Batch </h3>
                    </div>
                </h1>
                <Box
                    component="form"
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container
                        alignItems="center"
                        justifyContent="center"
                        justifyItems="center"
                        spacing={1}
                    >
                        <Grid item xs={8} >
                            <div>
                                <TextField
                                    error={errbatchnameb}
                                    helperText={errbatchname}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label="Enter Batch Name"
                                    value={batchname}
                                    onChange={(e) => setbatchname(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid container justifyContent='center' sx={{ mt: 3 }} >
                            <Button variant="contained" onClick={handlesubmitclick}>Submit</Button>
                        </Grid>

                    </Grid>
                </Box>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Batch Added"
                //action={action}
                />
            </div>
        </>
    )
}
