import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../../components/NavbarUser';
import config from '../config.json';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { decrypter } from "../../Util"
import NavBarAdmin from '../NavBarAdmin';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

export default function EditSession() {
    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [centers, setcenters] = useState([])

    const [id, setid] = useState(location.state.id)
    const [cid, setcid] = useState(location.state.cid)
    const [name, setname] = useState(location.state.name)
    const [startdate, setstartdate] = useState(location.state.start)
    const [enddate, setenddate] = useState(location.state.end)
    const [status, setstatus] = useState(location.state.status)

    const [backdrop, setbackdrop] = useState(false)


    const handlebackdropClose = () => {
        setbackdrop(false);
    };

    useEffect(() => {

        if (userdata === "false") {

            navigate('/')

        }

        const headers = {
            publicKey: KEY,
        };

        axios.post(
            BASE_URL + "/getCenter",
            {},
            { headers: headers }
        ).then(function (response) {

            setcenters(response.data.Centers);

        })
            .catch(function (error) {

                console.log("center error", error)

            })


    }, [])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleAddBatch = (e) => {
        navigate('/manageSession')
    }

    const handlesubmitclick = () => {

        const headers = {
            'publicKey': KEY
        }

        setbackdrop(true)

        console.log("json", JSON.stringify({
            session_id: id,
            center_id: cid,
            session_name: name,
            session_start_date: moment(new Date(startdate)).format("YYYY-MM-DD"),
            session_end_date: moment(new Date(enddate)).format("YYYY-MM-DD"),
            active_status: status
        }))

        const data = new FormData();
        data.append('model', JSON.stringify({
            session_id: id,
            center_id: cid,
            session_name: name,
            session_start_date: moment(new Date(startdate)).format("YYYY-MM-DD"),
            session_end_date: moment(new Date(enddate)).format("YYYY-MM-DD"),
            active_status: status
        }))

        axios.post(BASE_URL + '/updateCenterSession', data, { headers: headers })
            .then(function (response) {

                console.log(response.data)
                if (response.data === 'OK') {
                    setbackdrop(false)
                    setOpen(true);
                    setname('')
                    setstartdate(null)
                    setenddate(null)
                }
                else {
                    setbackdrop(false)
                }

            })
            .catch(function (errors) {
                setbackdrop(false)
            })

    }

    return (
        <>
            <NavBarAdmin
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="container">
                <Button variant="contained" sx={{ mt: 2, mb: 2 }} onClick={handleAddBatch}>Manage Training Session</Button>
                <h1>
                    <div className="abc text-white text-center col 10 p-1 mt-1">
                        <h3> Edit Training Session </h3>
                    </div>
                </h1>
                <Box
                    component="form"
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container
                        alignItems="center"
                        justifyContent="center"
                        justifyItems="center"
                        spacing={1}
                    >
                        <Grid item xs={3} >
                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label="Session ID"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={id}
                                    sx={{ mt: 3 }}
                                //onChange={(e) => setname(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-select-district"
                                    select
                                    label="Select Center"
                                    value={cid}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    sx={{ mt: 3 }}
                                    onChange={(e) => setcid(e.target.value)}
                                //onChange={handleChange}
                                >
                                    {centers.map((option) => (
                                        <MenuItem
                                            key={option.center_id}
                                            value={option.center_id}
                                        >
                                            {option.center_name} , ({option.center_code})
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Grid>
                        <Grid item xs={3} >
                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label="Enter Session Name"
                                    value={name}
                                    sx={{ mt: 3 }}
                                    onChange={(e) => setname(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3} >
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker sx
                                        label="Start Date"
                                        inputFormat="yyyy/MM/dd"
                                        utcOffset={0}
                                        value={startdate}

                                        onChange={(newValue) => {
                                            // const offsetDate = new Date(newValue.getTime() - (newValue.getTimezoneOffset() * 60000));
                                            // //const offsetDate = moment(new Date(newValue.getTime() - (newValue.getTimezoneOffset() * 60000))).format("YYYY-MM-DD");
                                            // setdate(offsetDate);
                                            setstartdate(newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth sx={{ mt: 2 }} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid item xs={3} >
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker sx
                                        label="End Date"
                                        inputFormat="yyyy/MM/dd"
                                        utcOffset={0}
                                        value={enddate}
                                        onChange={(newValue) => {
                                            // const offsetDate = new Date(newValue.getTime() - (newValue.getTimezoneOffset() * 60000));
                                            // //const offsetDate = moment(new Date(newValue.getTime() - (newValue.getTimezoneOffset() * 60000))).format("YYYY-MM-DD");
                                            // setdate(offsetDate);
                                            setenddate(newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} fullWidth sx={{ mt: 2 }} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </Grid>

                        <Grid container justifyContent='center' sx={{ mt: 3 }} >
                            <Button variant="contained" onClick={handlesubmitclick}>Submit</Button>
                        </Grid>

                    </Grid>
                </Box>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Training Session Altered"
                //action={action}
                />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdrop}
                    onClick={handlebackdropClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    )
}
