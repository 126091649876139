import React from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export default function DounoughtChart(props) {

    const pielabels = props.label;
    const data = {
        labels: pielabels,
        datasets: [
            {
                label: '',
                data: props.data,
                backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)'
                ],
                borderColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)'
                ],
                borderWidth: 3,
                hoverOffset: 10
            },
        ],
    };

    const options = {

        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2,
        layout: {
            padding: {
                
                bottom: 20
            }

        },

        plugins: {

            legend: {
                position: 'top',
                display: true,
            },
            
            title: {
                display: true,
                text: props.title,
                font: {
                    size: 20
                }
            },
            datalabels: {
                color: ['rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)'],
                anchor: 'end',
                align: 'end',
            },


        },
    };

    
    return (
        <div><Doughnut data={data} options={options} /></div>
    )
}
