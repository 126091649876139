import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../images/login.jpg';
import config from "../components/config.json";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";


import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileOpenIcon from '@mui/icons-material/FileOpen';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan, faPlus, faFilePen } from '@fortawesome/free-solid-svg-icons'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import {
    DataGrid, GridToolbar,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    gridPageSizeSelector
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Avatar from '@mui/material/Avatar';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from "@mui/material";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
//import { Document, Page, ReactPDF } from "react-pdf";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import Alert from '@mui/material/Alert';

import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { decrypter } from "../Util"
import moment from 'moment';
import NavBarAdmin from "../components/NavBarAdmin";

import Pagination from '@mui/material/Pagination';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';

import MediaQuery from 'react-responsive'
import { useMediaQuery } from 'react-responsive'

import Workbook from 'react-excel-workbook'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';




const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

};

const style2 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    maxWidth: '100%',
    maxHeight: '100vh',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    margin: 'auto'


}

const stylepdf = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

};

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDataGrid-footerContainer": {
            display: "none"
        }
    }
}));

export default function Reports() {

    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();

    const [bens, setbens] = useState([]);
    const [centers, setcenters] = useState([])
    const [sessions, setsessions] = useState([])

    const [cid, setcid] = useState('')
    const [sid, setsid] = useState('')
    const [bid, setbid] = useState('')

    const [sessions1, setsessions1] = useState([])
    const [ben1, setben1] = useState([])
    const [ben2, setben2] = useState([])
    const [ben3, setben3] = useState([])

    const [errsession, seterrsession] = useState('')
    const [errsessionb, seterrsessionb] = useState(false)

    const [errcenter, seterrcenter] = useState('')
    const [errcenterb, seterrcenterb] = useState(false)
    const [btn, setbtn] = useState(true)
    const [filename, setfilename] = useState('')

    var [text2, settext2] = useState('')

    const [reportlink, setreportlink] = useState('')

    var count = 1;

    useEffect(() => {

        if (userdata === "false") {

            navigate('/')

        }

        const headers = {
            'publicKey': KEY

        }

        const params = new URLSearchParams();
        params.append("center_id", userdata.center_id);

        const getbensadmin = axios.post(
            BASE_URL + "/getAllBeneficiariesAdmin",
            {},
            { headers: headers }
        );

        const getcenter = axios.post(
            BASE_URL + "/getCenter",
            {},
            { headers: headers }
        );

        const getsessions = axios.post(
            BASE_URL + "/getTSession",
            {},
            { headers: headers }
        );



        Promise.all([getbensadmin, getcenter, getsessions])
            .then(function (responses) {
                console.log("first", responses[0].data.Beneficiaries)
                setbens(responses[0].data.Beneficiaries.sort(function (a, b) {
                    return parseFloat(a.ben_id) - parseFloat(b.ben_id);
                }));
                setcenters(responses[1].data.Centers);
                setsessions(responses[2].data.CenterSession);
            })
            .catch(function (errors) {
                console.log("state errors", errors[0]);
            });
    }, [])

    const handlecenter = (e) => {
        setcid(e.target.value)
        setbid('')
        setbtn(true)
        setsessions1(sessions.filter(op => op.center_id === e.target.value))
        //setben1(bens.filter(op => op.center_id == e.target.value))
        


    }

    const handlesession = (e) => {
        setsid(e.target.value)
        setbid('')
        setbtn(true)
        //setben2(ben1.filter(op => op.session_id == e.target.value))
        
    }

    const handlebutton = () => {

        
        setcid("")
        setsid("")
        setbid("")
        setbtn(true)


        const ab = BASE_URL + '/getReport' + '/' + cid + '/' + sid + '/' + bid;
        setreportlink(ab)
        


    }

    const handlebtype = (event) => {
        setbid(event.target.value);
        setbtn(false)


    };

    




    return (
        <>
            <NavBarAdmin
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="row mx-5 mb-5">
                <div className="container">
                    <h1>
                        <div className="abc text-white text-center col 10 p-1 mt-1">
                            <h3> Generate Beneficiary Reports </h3>
                        </div>
                    </h1>

                    <Box
                        component="form"
                        sx={{
                            marginTop: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <div>
                                    <TextField
                                        error={errcenterb}
                                        helperText={errcenter}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="outlined-select-district"
                                        select
                                        label="Center"
                                        value={cid}
                                        onChange={
                                            handlecenter
                                            //setdistrict(centers.filter(op => op.center_dist_id === district))

                                        }
                                    // InputProps={{
                                    //   readOnly: true,
                                    // }}
                                    //onChange={handleChange}
                                    >
                                        {centers.map((option) => (
                                            <MenuItem
                                                key={option.center_id}
                                                value={option.center_id}
                                            >
                                                {option.center_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <TextField
                                        error={errsessionb}
                                        helperText={errsession}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="outlined-select-district"
                                        select
                                        label="Select Session"
                                        value={sid}
                                        onChange={handlesession}
                                    //onChange={handleChange}
                                    >
                                        {sessions1.map((option) => (
                                            <MenuItem
                                                key={option.session_id}
                                                value={option.session_id}
                                            >
                                                {option.center_name} , {option.session_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                            <Grid item xs={4}>

                                <div>
                                    <TextField

                                        margin="normal"
                                        required
                                        fullWidth
                                        id="outlined-select-district"
                                        select
                                        label="Beneficiary Type"
                                        value={bid}
                                        onChange={handlebtype}
                                    //onChange={handleChange}
                                    >
                                        <MenuItem key={1} value={1}>
                                            All Beneficiaries
                                        </MenuItem>
                                        <MenuItem key={2} value={2}>
                                            Beneficiary With Certificate
                                        </MenuItem>

                                    </TextField>
                                </div>

                            </Grid>
                            <Grid item xs={4}>


                                <Button variant='contained' disabled={btn} onClick={handlebutton} >
                                    {/* <Workbook filename={filename} >
                                        <Workbook.Sheet data={ben3} name="Sheet A">
                                            <Workbook.Column label="SL" value={(col) => count++} />
                                            <Workbook.Column label="Enrollment ID" value="enrollment_id" />
                                            <Workbook.Column label="Name" value="ben_name" />
                                            <Workbook.Column label="Gender" value="gender" />
                                            <Workbook.Column label="Father/Husband Name" value="ben_fname" />
                                            <Workbook.Column label="Relation" value={(col) => col.relation === "F" ? "Father" : (col.relation === "H" ? "Husband" : "Mother")} />
                                            <Workbook.Column label="Proof Type" value="proof_type" />
                                            <Workbook.Column label="Proof Number" value="ben_id_proof_no" />
                                            <Workbook.Column label="Mobile Number" value="ben_mobile_no" />



                                        </Workbook.Sheet>
                                    </Workbook> */}
                                    <a href={reportlink} style={{color: "white"}}>Export to Excel</a>
                                </Button>

                                


                            </Grid>
                        </Grid>

                    </Box>


                </div>


            </div>
        </>
    )
}
