import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export default function MultiBarChart(props) {


    const labels = props.label;
    const data = {
        labels,
        datasets: [
            {
                label: 'Total',
                data: props.data1,
                backgroundColor: 'rgba(255, 0, 0,0.8)',

                borderColor: 'rgb(255, 0, 0)',
                borderWidth: 2,
                datalabels: {
                    color: ['rgb(255, 0, 0)'
                    ],
                    anchor: 'end',
                    align: 'end',
                },
            },
            {
                label: 'Male',
                data: props.data2,
                backgroundColor:
                    'rgba(255, 136, 0,0.8)',

                borderColor:
                    'rgb(255, 136, 0)',
                borderWidth: 2,
                datalabels: {
                    color: [
                        'rgb(255, 136, 0)'
                    ],
                    anchor: 'end',
                    align: 'end',
                },
            },
            {
                label: 'Female',
                data: props.data3,
                backgroundColor:
                    'rgba(28, 55, 145,0.8)',
                borderColor:
                    'rgb(28, 55, 145)',
                borderWidth: 2,
                datalabels: {
                    color: [
                        'rgb(28, 55, 145)',
                    ],
                    anchor: 'end',
                    align: 'end',
                },
            }
        ],
    };

    const options = {

        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'top',
                display: true,
            },
            title: {
                display: true,
                text: props.title,
                font: {
                    size: 20,

                },
                padding: {
                    top: 10,
                    bottom: 5
                }
            },
            datalabels: {
                color: ['rgb(255, 0, 0)',
                    'rgb(255, 136, 0)',
                    'rgb(28, 55, 145)',
                ],
                anchor: 'end',
                align: 'end',
            },


        },
    };

    

    return (
        <div><Bar data={data} options={options} /></div>
    )
}
