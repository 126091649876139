import config from "./components/config.json";
const SECRET = config.SECRET;
var CryptoJS = require("crypto-js");



export const encrypter = () => {

}


export const decrypter = (data) => {

    //console.log("data ", data)

    if (data === undefined) {
        return "false"

    } else {
        try {
            let bytes = CryptoJS.AES.decrypt(data, SECRET);
            let originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            return originalText;
        } catch (error) {

            return "false"

        }

    }

}