import React from 'react'
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function GoToTop() {
    const routePath = useLocation();
    const navigate = useNavigate();
    const onTop = () => {
      window.scrollTo(0, 0);
    }
    useEffect(() => {
      onTop()
    }, [routePath,navigate]);
    
    return null;
}
