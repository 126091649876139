import React from 'react'
import PropTypes from 'prop-types'
import profile from '../images/profile.png';
import user from '../images/usershield.svg';
import headdrop from '../css/headdrop.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faPowerOff, faUserEdit } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export default function BaseNavBar(props) {
    
    let loginstatus = sessionStorage.getItem("loginstatus");
    const navigate = useNavigate();

    const handleClick = (e) =>{
        e.preventDefault();

        if(loginstatus === "true"){
            navigate('/dashboard')
        }
        else{
            navigate('/login')
        }
    }

    return (
        <>

            <nav className="navbar  navbar-expand-lg navbar-dark  " >

                <div className="container-fluid">

                    <a className="navbar-brand ms-5" href="#">{props.title}</a>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto pe-3">
                            
                            {/* <li className="nav-item">
                            <Link className="nav-link " to="/about">About</Link>
                        </li> */}

                            <li className="nav-item">
                                <a className="nav-link" href="#" onClick={handleClick}>Login</a>
                            </li>
                        </ul>
                    </div>

                </div>
            </nav>
            <nav className="user navbar navbar-expand-lg  text-center  "  >
                <div className="container-fluid ">
                    <div className="collapse navbar-collapse " id="navbarSupportedContent">
                        <ul className=" navbar-nav mx-auto mb-2 mb-lg-0 ">
                            <li className="nav-item ">
                                <Link className="nav-link" aria-current="page" to="/">Home</Link>
                            </li>
                            <li className="nav-item ">
                                <Link className="nav-link" aria-current="page" to="/objective">Objective</Link>
                            </li>
                            <li className="nav-item">
                                {/* <Link className="nav-link" aria-current="page" to="/homegallery">GALLERY</Link> */}
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="">News</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/team">Meet Our Team</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/training">Training</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>

    )
}
BaseNavBar.propTypes = {
    title: PropTypes.string
}
