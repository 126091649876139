import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../NavbarUser';
import config from '../config.json';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import Chip from '@mui/material/Chip';
import { CheckCircle, Cancel } from '@mui/icons-material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan, faPlus, faFilePen } from '@fortawesome/free-solid-svg-icons'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Avatar from '@mui/material/Avatar';
import NavBarFaculty from '../NavBarFaculty';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from "@mui/material";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
//import { Document, Page, ReactPDF } from "react-pdf";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import Alert from '@mui/material/Alert';

import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { decrypter } from "../../Util"
import moment from 'moment';
import NavBarAdmin from '../NavBarAdmin'

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

export default function ViewBenCertificate() {
    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();
    const [bens, setbens] = useState([]);

    const [pageSize, setPageSize] = useState(10);

    const columns = [
        {
            field: 'ben_id', headerName: 'ID', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: 'enrollment_id', headerName: 'Enrollment ID', flex: 1, align: 'center', headerAlign: 'center'
        },

        {
            field: 'Image', headerName: 'Image', flex: 0.5, align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.ben_image === null) {
                    return (
                        <Avatar alt={cellValues.row.ben_name}
                            src={cellValues.row.ben_name}
                            sx={{ width: 40, height: 40 }}
                            variant="circular"
                        />
                    );
                }
                else {
                    return (
                        <Avatar alt={cellValues.row.ben_name}
                            src={BASE_URL + "/" + "images" + "/" + cellValues.row.ben_image}
                            sx={{ width: 40, height: 40 }}
                            variant="circular"
                        />
                    );
                }
            }
        },

        {
            field: 'center_name', headerName: 'Center Name', flex: 1.2, align: 'center', headerAlign: 'center'
        },
        {
            field: 'ben_name', headerName: 'Name', flex: 1.5, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (<Typography variant="string" textTransform="uppercase">
                    {params.row.ben_name}
                </Typography>)
            }
        },
        {
            field: 'ben_dob', headerName: 'DOB', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return (<Typography variant="string" textTransform="uppercase">
                    {moment(params.row.ben_dob).format('DD/MM/YYYY')}
                </Typography>)
            }
        },

        {
            field: 'session_name', headerName: 'Session Name', flex: 1, align: 'center', headerAlign: 'center'
        },
        // {
        //     field: 'ben_email_id', headerName: 'Email ID', flex: 1.5, align: 'center', headerAlign: 'center',
        //     valueGetter: (cellValues) => {
        //         let result = [];
        //         if (cellValues.row.ben_email_id) {
        //             if (cellValues.row.ben_email_id) {

        //                 result.push(cellValues.row.ben_email_id);
        //             }
        //         }
        //         else {
        //             result = ["Email Not Provided"]
        //         }
        //         return result;
        //     }
        // },

        {
            field: 'certificate_issued', headerName: 'Certificate Status', flex: 1, align: 'center', headerAlign: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.certificate_issued === "Y") {
                    return (
                        // <IconButton
                        // //   variant="contained"
                        // //   color="primary"
                        // //   size='medium'
                        // //   startIcon={<EditIcon></EditIcon>}

                        // // onClick={(event) => {
                        // //     handleClickOpen(event, cellValues);
                        // // }}
                        // >
                        //     <CheckCircle sx={{ color: "green" }}></CheckCircle>
                        // </IconButton>
                        <>
                            <Chip icon={<CheckCircle></CheckCircle>} color="success" label="Issued" variant="outlined" />

                        </>

                    );
                }
                else {
                    return (
                        // <IconButton
                        // //   variant="contained"
                        // //   color="primary"
                        // //   size='medium'
                        // //   startIcon={<EditIcon></EditIcon>}

                        // // onClick={(event) => {
                        // //     handleClickOpen(event, cellValues);
                        // // }}
                        // >
                        //     <Cancel sx={{color: "red"}}></Cancel>
                        // </IconButton>
                        <>
                            <Chip icon={<Cancel ></Cancel>} color="error" label="Not Issued" variant="outlined" />
                        </>

                    );
                }
            }
        }

    ]

    useEffect(() => {

        if (userdata === "false") {

            navigate('/')

        }

        const headers = {
            'publicKey': KEY

        }

        if (userdata.role_id == 5) {


            axios.post(BASE_URL + '/getAllBeneficiariesAdmin', {}, { headers: headers })
                .then(function (responses) {

                    console.log("Beneficiaries", responses.data.Beneficiaries)
                    //setunmapped(responses.data.Beneficiaries)
                    setbens(responses.data.Beneficiaries)

                })
                .catch(function (errors) {

                })

        } else {

            const params = new URLSearchParams();
            params.append("center_id", userdata.center_id);

            axios.post(BASE_URL + '/getAllBeneficiaries', params, { headers: headers })
                .then(function (responses) {

                    console.log("Beneficiaries", responses.data.Beneficiaries)
                    //setunmapped(responses.data.Beneficiaries)
                    setbens(responses.data.Beneficiaries)

                })
                .catch(function (errors) {

                })

        }
    }, [])

    const handleAddBatch = (e) => {

        e.preventDefault();
        navigate('/updateCertificate')

    }


    return (
        <>
            {userdata.role_id == 1 ? <NavbarUser
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
            /> : userdata.role_id == 5 ? <NavBarAdmin
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
            /> : <NavBarFaculty
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />}
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="row mx-5 mb-5">
                <div className="container">
                    <h1>
                        <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<FontAwesomeIcon icon={faPlus} className="me-1" />} onClick={handleAddBatch} disabled={userdata.role_id == 5 ? true : false}>Update Certificate</Button>
                        <div className="abc text-white text-center col 10 p-1 mt-1">
                            <h3> Beneficiary Certificate Status </h3>
                        </div>
                    </h1>
                    <Box
                        justifyContent='center'
                        //</div>sx={{ height: 700, width: '100%' }}
                        >
                        <DataGrid
                            sx={{
                                boxShadow: 2,
                                '& .MuiTablePagination-selectLabel': {
                                    marginTop: 'auto'
                                },
                                '& .MuiTablePagination-displayedRows': {
                                    marginTop: 'auto'
                                }
                            }}
                            rows={bens}
                            getRowId={(r) => r.ben_id}
                            columns={columns}
                            components={{ Toolbar: GridToolbar }}
                            autoHeight
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            pagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                        //checkboxSelection={true}
                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setSelectedRows(newSelectionModel);
                        // }}

                        />
                    </Box>
                </div>
            </div>
        </>
    )
}
