import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export default function Barchart(props) {


    const labels = props.label;
    const data = {
        labels,
        datasets: [
            {

                data: props.data,
                backgroundColor: ['rgba(255, 0, 0,0.8)',
                    'rgba(255, 136, 0,0.8)',
                    'rgba(28, 55, 145,0.8)',
                    'rgba(3, 145, 78,0.8)',
                    'rgba(245, 105, 84,0.8)',
                    'rgba(0, 166, 90,0.8)',
                    'rgba(243, 156, 18,0.8)',
                    'rgba(0, 192, 239,0.8)',
                    'rgba(60, 60, 60,0.8)',
                    'rgba(60, 141, 188,0.8)',
                    'rgba(153, 153, 102,0.8)',
                    'rgba(153, 51, 102,0.8)',
                    'rgba(0, 143, 179,0.8)'],
                borderColor: ['rgb(255, 0, 0)',
                    'rgb(255, 136, 0)',
                    'rgb(28, 55, 145)',
                    'rgb(3, 145, 78)',
                    'rgb(245, 105, 84)',
                    'rgb(0, 166, 90)',
                    'rgb(243, 156, 18)',
                    'rgb(0, 192, 239)',
                    'rgb(60, 60, 60)',
                    'rgb(60, 141, 188)',
                    'rgb(153, 153, 102)',
                    'rgb(153, 51, 102)',
                    'rgb(0, 143, 179)'],
                borderWidth: 2,
            }
        ],
    };

    const options = {

        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'top',
                display: false
            },
            title: {
                display: true,
                text: props.title,
                font: {
                    size: 20,
                    
                },
                padding:{
                    top: 10,
                    bottom: 20
                }
            },
            datalabels: {
                color: ['rgb(255, 0, 0)',
                    'rgb(255, 136, 0)',
                    'rgb(28, 55, 145)',
                    'rgb(3, 145, 78)',
                    'rgb(245, 105, 84)',
                    'rgb(0, 166, 90)',
                    'rgb(243, 156, 18)',
                    'rgb(0, 192, 239)',
                    'rgb(60, 60, 60)',
                    'rgb(60, 141, 188)',
                    'rgb(153, 153, 102)',
                    'rgb(153, 51, 102)',
                    'rgb(0, 143, 179)'],
                anchor: 'end',
                align: 'end',
            },


        },
    };
    return (
        <div><Bar data={data} options={options} /></div>
    )
}
