import React from 'react'
import PropTypes from 'prop-types'
import profile from '../images/profile.png';
import user from '../images/usershield.svg';
import headdrop from '../css/headdrop.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faPowerOff, faUserEdit } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import { Cookies } from 'react-cookie';
import Avatar from '@mui/material/Avatar';
const cookies = new Cookies();


export default function Navbar(props) {

    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();


        navigate('/')
        cookies.remove("userdata")
        cookies.remove("loginstatus")
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark">

                <div className="container-fluid">

                    <a className="navbar-brand ms-5" href="#">{props.title}</a>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-nav" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto pe-3">
                            <li className="nav-item active">
                                {/* <Link className="nav-link " aria-current="page" to="/">Homee</Link> */}
                                <a className="navbar-brand ms-2" href="#">Welcome: {props.name}</a>
                            </li>
                            <li className="nav-item active">
                                {/* <Link className="nav-link " aria-current="page" to="/">Homee</Link> */}
                                <a className="navbar-brand ms-2" href="#">|</a>
                            </li>
                            <li className="nav-item">
                                {/* <Link className="nav-link " to="/about">Aboutt</Link> */}
                                <a className="navbar-brand ms-2" href="#">{props.role} : {props.state}</a>
                            </li>
                            <li className="nav-item dropdown " data-bs-toggle="dropdown" id="navbarDropdown">
                                <a className="nav-link" href="#" data-toggle="dropdown" aria-labelledby="navbarDropdown" aria-expanded="false">
                                    <img src={user} style={{ width: '30px', alignItems: "center" }}></img>
                                    {/* <i class="fas fa-user-shield"></i> */}
                                    {/* <FontAwesomeIcon icon="fas fa-user-shield" /> */}

                                </a>
                                <div className="center dropdown-menu dropdown-menu-lg dropdown-menu-end" >
                                    <div className="center1">
                                        {/* <img src={profile} style={{ width: "200px" }} alt="profile" /> */}
                                        <Avatar 
                                            src={profile}
                                            sx={{ width: 100, height: 100 }}
                                            variant="circle"
                                            style={{ alignItems: 'center' }} />
                                    </div>
                                    {/* <a href="/collector_profile/" className="dropdown-item " style={{ alignItems: "center" }}>
                                        <FontAwesomeIcon icon={faUserEdit} /> Update Profile
                                    </a> */}
                                    <a href="/logout" className="dropdown-item" onClick={handleClick}>
                                        <FontAwesomeIcon icon={faPowerOff} className="me-1" /> Logout
                                    </a>
                                    
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <nav className="user navbar navbar-expand-lg  text-center">
                <div className="container-fluid ">
                    <div className="collapse navbar-collapse " id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/dashboard">Dashboard</Link>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" aria-expanded="false">
                                    Beneficiary Management
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item" to="/addbeneficiary">Add Beneficiary</Link></li>
                                    <li><Link className="dropdown-item" to="/manageBeneficiary">Manage Beneficiary</Link></li>
                                    <li><Link className="dropdown-item" to="/manageCertificate">Manage Certificate</Link></li>
                                    {/* <li><Link className="dropdown-item" to="/viewmapBeneficiary">Map Beneficiary</Link></li> */}
                                </ul>
                            </li>
                            
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" aria-expanded="false">
                                    Faculty Management
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item" to="/addFaculty">Add Faculty</Link></li>
                                    <li><Link className="dropdown-item" to="/manageFaculty">Manage Faculty</Link></li>
                                    <li><Link className="dropdown-item" to="/manageRole">Manage Roles</Link></li>
                                </ul>
                            </li>
                            {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" aria-expanded="false">
                                    Mapping
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item" to="/batchcourseMap">Map Batches and Courses</Link></li>
                                    <li><Link className="dropdown-item" to="/batchsessioncenterMap">Map Center Session and Batch</Link></li>
                                </ul>
                            </li> */}
                            {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" aria-expanded="false">
                                    CMS
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item" to="/galleryManagement">Gallery Management</Link></li>
                                    
                                </ul>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </>

    )
}

Navbar.propTypes = {
    title: PropTypes.string
}
