import React, { useState, useEffect } from 'react';
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../../components/NavbarUser';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowUp } from '@fortawesome/free-solid-svg-icons'
import config from '../config.json';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from 'react-router-dom'
import regex from '../regex.json';

import NavBarFaculty from '../NavBarFaculty';

import { decrypter } from "../../Util"



const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

export default function AddFaculty() {

    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();

    const [centers, setcenters] = useState([])

    const [fqual, setfqual] = useState('');
    const [errfqual, seterrfqual] = useState('')
    const [errfqualb, seterrfqualb] = useState(false)

    const [fid, setfid] = useState('');
    const [errfid, seterrfid] = useState('')
    const [errfidb, seterrfidb] = useState(false)

    const [fname, setfname] = useState('');
    const [errfname, seterrfname] = useState('')
    const [errfnameb, seterrfnameb] = useState(false)

    const [fdesig, setfdesig] = useState('');
    const [errfdesig, seterrorfdesig] = useState('')
    const [errfdesigb, seterrorfdesigb] = useState(false)

    const [fmob, setfmob] = useState('');
    const [errfmob, seterrfmob] = useState('')
    const [errfmobb, seterrfmobb] = useState(false)

    const [address, setaddress] = useState('');
    const [erraddress, seterraddress] = useState('')
    const [erraddressb, seterraddressb] = useState(false)

    const [email, setemail] = useState('');
    const [erremail, seterremail] = useState('')
    const [erremailb, seterremailb] = useState(false)

    const [filename, setFilename] = useState('');
    const [file, setfile] = useState();

    const [btncolor, setbtncolor] = useState("primary")
    const [errbtn, seterrbtn] = useState("")

    const [cid, setcid] = useState(userdata.center_id);
    const [errcid, seterrcid] = useState('')
    const [errcidb, seterrcidb] = useState(false)

    const [open, setOpen] = useState(false);
    const [click, setclick] = useState(false)
    const [preview, setpreview] = useState()

    useEffect(() => {

        if (userdata === "false") {

            navigate('/')

        }

        const headers = {
            publicKey: KEY,
        };

        //console.log("params",params)
        // axios.post(BASE_URL + '/district', params)
        //   .then(function (response) {

        //     //let districts = [];

        //     //console.log(JSON.stringify(response.data.districts));
        //     //districts.push()
        //     console.log(response.data.districts)
        //     setdistricts(response.data.districts)

        //   }).catch(function (error) {

        //     console.log(error)

        //   });

        const getcenter = axios.post(
            BASE_URL + "/getCenter",
            {},
            { headers: headers }
        );


        Promise.all([getcenter])
            .then(function (responses) {
                // setdistricts(responses[0].data.districts);
                // setcastes(responses[1].data.Castes);
                // setproofs(responses[2].data.Proofs);
                // setsessions(responses[3].data.CenterSession);
                setcenters(responses[0].data.Centers);
                setclick(false)
            })
            .catch(function (errors) {
                // console.log("state errors", errors[0]);
                // console.log("caste errors", errors[1]);
                // console.log("proof errors", errors[2]);
                // console.log("session errors", errors[3]);
                console.log("center errors", errors[0]);
            });


    }, [click]);

    useEffect(() => {
        if (!file) {
            setpreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(file)
        setpreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [file])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handlefilechange = (e) => {

        //const [files] = e.target.files;
        //console.log("data", files);
        setFilename(e.target.files[0].name)
        setfile(e.target.files[0])

        //setFilename(e.target.files);

    };





    console.log("data from file value", file);

    const handlesubmitclick = (e) => {
        e.preventDefault()

        if (validation()) {
            const headers = {
                'publicKey': KEY
            }

            console.log("faculty", JSON.stringify({
                faculty_emp_id: fid,
                faculty_name: fname,
                faculty_designation: fdesig,
                faculty_email_id: email,
                faculty_mobile_no: fmob,
                faculty_Qualification: fqual,
                faculty_address: address,
                updated_by: userdata.faculty_sys_id,
                centerId: cid

            }))

            const data = new FormData();
            data.append('file', file)
            data.append('model', JSON.stringify({
                faculty_emp_id: fid,
                faculty_name: fname,
                faculty_designation: fdesig,
                faculty_email_id: email,
                faculty_mobile_no: fmob,
                faculty_Qualification: fqual,
                faculty_address: address,
                updated_by: userdata.faculty_sys_id,
                centerId: cid

            }))

            axios.post(BASE_URL + '/addFaculty', data, { headers: headers })
                .then(function (responses) {

                    console.log(responses.data)
                    if (responses.data === 'OK') {
                        setOpen(true);
                        clearfields();
                        setclick(true)
                        setTimeout(function () {
                            window.location.reload(false);
                        }, 3000);


                    }

                })
                .catch(function (errors) {

                })
        }




    }

    function validation() {
        let isvalid = true

        if (fid === "") {
            seterrfidb(true)
            seterrfid("Employee ID cannot be empty")
            isvalid = false


        } else if (!fid.match(regex.letternumberspace)) {
            seterrfidb(true)
            seterrfid("Please Enter Valid ID")
            isvalid = false

        } else {
            seterrfidb(false)
            seterrfid("")


        }

        if (fname === "") {
            seterrfnameb(true)
            seterrfname("Name Cannot be Empty")
            isvalid = false

        } else if (!fname.match(regex.letterandspace)) {
            seterrfnameb(true)
            seterrfname("Please Enter Valid Data")
            isvalid = false

        } else {
            seterrfnameb(false)
            seterrfname("")


        }

        if (fdesig === "") {
            seterrorfdesigb(true)
            seterrorfdesig("Designation Cannot be Empty")
            isvalid = false

        } else if (!fdesig.match(regex.letternumberspacecoma)) {
            seterrorfdesigb(true)
            seterrorfdesig("Please Enter Valid Data")
            isvalid = false
        } else {
            seterrorfdesigb(false)
            seterrorfdesig("")
        }

        if (email === "") {
            seterremailb(true)
            seterremail("Email Cannot be Empty")
            isvalid = false

        } else if (!email.match(regex.emailpattern)) {
            seterremailb(true)
            seterremail("Please Enter Valid Data")
            isvalid = false

        } else {
            seterremailb(false)
            seterremail("")
        }

        if (fmob === "") {
            seterrfmobb(true)
            seterrfmob("Mobile Number Cannot be Empty")
            isvalid = false

        } else if (!fmob.match(regex.mobile)) {
            seterrfmobb(true)
            seterrfmob("Plese Enter Valid Mobile Number")
            isvalid = false
        } else {
            seterrfmobb(false)
            seterrfmob("")

        }

        if (fqual === "") {
            seterrfqualb(true)
            seterrfqual("Qualification Cannot be Empty")
            isvalid = false

        } else if (!fqual.match(regex.letternumberspacecoma)) {
            seterrfqualb(true)
            seterrfqual("Please Enter Valid Data")
            isvalid = false

        } else {
            seterrfqualb(false)
            seterrfqual("")

        }

        if (address === "") {
            seterraddressb(true)
            seterraddress("Address Cannot be Empty")
            isvalid = false

        } else if (!address.match(regex.letternumberspacecoma)) {
            seterraddressb(true)
            seterraddress("Please Enter Valid Data")
            isvalid = false

        } else {
            seterraddressb(false)
            seterraddress("")

        }

        if (file === undefined) {
            setbtncolor("error")
            seterrbtn("Please Upload Image")
            isvalid = false

        } else {
            setbtncolor("primary")
            seterrbtn("")


        }

        return isvalid

    }

    function clearfields() {

        setfid('')
        setfname('')
        setfdesig('')
        setemail('')
        setfmob('')
        setfqual('')
        setaddress('')
        setcid('')
        setFilename('')
        setfile()


    }

    return (
        <>
            {userdata.role_id == 1 ? <NavbarUser
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
            /> : <NavBarFaculty
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />}
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="container">
                <h1>
                    <div className="abc text-white text-center col 10 p-1 mt-1">
                        <h3> Add Faculty Details </h3>
                    </div>
                </h1>
                <Box
                    component="form"
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container
                        spacing={1}


                    >
                        <Grid item xs={6}>
                            <div>
                                <TextField
                                    error={errcidb}
                                    helperText={errcid}
                                    margin="normal"
                                    required
                                    fullWidth

                                    id="outlined-select-district"
                                    select
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    label="Select Center"
                                    value={cid}
                                    onChange={(e) => setcid(e.target.value)}
                                //onChange={handleChange}
                                >
                                    {centers.map((option) => (
                                        <MenuItem
                                            key={option.center_id}
                                            value={option.center_id}
                                        >
                                            {option.center_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div>
                                <TextField
                                    error={errfidb}
                                    helperText={errfid}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label="Enter Faculty Employee Id"
                                    //defaultValue={userdata.state_name}
                                    value={fid}
                                    onChange={(e) => setfid(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                <TextField
                                    error={errfnameb}
                                    helperText={errfname}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label="Enter Faculty Name"
                                    //defaultValue={userdata.state_name}
                                    value={fname}
                                    onChange={(e) => setfname(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                <TextField
                                    error={errfdesigb}
                                    helperText={errfdesig}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label="Enter Faculty Designation"
                                    //defaultValue={userdata.state_name}
                                    value={fdesig}
                                    onChange={(e) => setfdesig(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                <TextField
                                    error={erremailb}
                                    helperText={erremail}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label="Enter Faculty Email ID"
                                    //defaultValue={userdata.state_name}
                                    value={email}
                                    onChange={(e) => setemail(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                <TextField
                                    error={errfmobb}
                                    helperText={errfmob}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-number"
                                    label="Enter Faculty Mobile Number"
                                    type="number"
                                    InputLabelProps={{

                                        maxLength: 10
                                    }}
                                    value={fmob}
                                    onChange={(e) => setfmob(e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                <TextField
                                    error={errfqualb}
                                    helperText={errfqual}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label="Enter Qualification"
                                    //defaultValue={userdata.state_name}
                                    value={fqual}
                                    onChange={(e) => setfqual(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div>
                                <TextField
                                    error={erraddressb}
                                    helperText={erraddress}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label="Enter Address"
                                    //defaultValue={userdata.state_name}
                                    value={address}
                                    onChange={(e) => setaddress(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <Button
                                    margin="normal"
                                    fullWidth
                                    required
                                    component="label"
                                    variant="outlined"
                                    sx={{ mt: 2 }}
                                    startIcon={<FontAwesomeIcon icon={faFileArrowUp}
                                    />}
                                    color={btncolor}
                                    helperText={errbtn}
                                >
                                    Upload Image (jpg, jpeg & png )
                                    less than 1 MB
                                    <input type="file" accept='.jpg,.jpeg,.png' name={file} hidden onChange={handlefilechange} />
                                </Button>
                            </div>
                            {filename} <img src={preview} alt="" width="100" height="100" />

                        </Grid>
                        <Grid container justifyContent='center' sx={{ mt: 2 }}>

                            <Button variant="contained" onClick={handlesubmitclick}>Submit</Button>

                        </Grid>
                    </Grid>
                </Box>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Faculty Added"
                //action={action}
                />
            </div>


        </>
    )
}
