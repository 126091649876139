import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../../components/NavbarUser';
import config from '../config.json';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import moment from 'moment';
import NavBarFaculty from '../NavBarFaculty';
import NavBarAdmin from '../NavBarAdmin';
import { decrypter } from "../../Util"

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

export default function ViewFaculty() {

    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (userdata === "false") {

            navigate('/')

        }
    }, [])



    return (
        <>
            {userdata.role_id == 1 ? <NavbarUser
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
            /> : userdata.role_id == 5 ? <NavBarAdmin
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
            /> : <NavBarFaculty
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />}
            <div className="container">
                <div className="row mt-4 justify-content-center">
                    <Card sx={{ width: 1000, alignItems: 'center', boxShadow: 5 }}>
                        <CardHeader
                            avatar={
                                location.state.data.faculty_image === null ? <Avatar alt={location.state.data.faculty_name}
                                    src={location.state.data.faculty_name}
                                    sx={{ width: 100, height: 100 }}
                                    variant="rounded"
                                    style={{ alignItems: 'center' }} /> : <Avatar alt={location.state.data.faculty_name}
                                        src={BASE_URL + "/" + "faculty" + "/" + location.state.data.faculty_image}
                                        sx={{ width: 100, height: 100 }}
                                        variant="rounded"
                                        style={{ alignItems: 'center' }} />
                            }
                            title={location.state.data.faculty_name}
                            subheader={location.state.data.faculty_email_id}
                            style={{ textAlign: 'center' }}
                            titleTypographyProps={{ variant: 'h3' }}
                            subheaderTypographyProps={{ variant: 'h5' }}
                        >
                        </CardHeader>
                        <Divider >DETAILS</Divider>
                        <CardContent>
                            <Box
                                component="form"
                                sx={{
                                    marginTop: 4,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",

                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} >
                                    <Grid item xs={2} >

                                        <Typography variant="subtitle1">
                                            Faculty ID:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.faculty_sys_id}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={1}>

                                        <Typography variant="subtitle1">

                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography variant="subtitle1" >
                                            Employee ID:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3} >

                                        <Typography variant="subtitle1">
                                            {location.state.data.faculty_emp_id}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography variant="subtitle1">
                                            Designation:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.faculty_designation}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={1}>

                                        <Typography variant="subtitle1">

                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography variant="subtitle1">
                                            Mobile:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.faculty_mobile_no}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography variant="subtitle1">
                                            Qualification:
                                        </Typography>

                                    </Grid>

                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.faculty_Qualification}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={1}>

                                        <Typography variant="subtitle1">

                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography variant="subtitle1">
                                            Address:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.faculty_address}
                                        </Typography>

                                    </Grid>

                                    <Grid item xs={1}>

                                        <Typography variant="subtitle1">

                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography variant="subtitle1">
                                            Updated Date:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">

                                            {
                                                moment(location.state.data.updated_date).format('MMMM Do YYYY, h:mm:ss a')
                                            }
                                        </Typography>

                                    </Grid>

                                </Grid>
                            </Box>
                        </CardContent>

                    </Card>
                </div>
            </div>
        </>
    )
}
