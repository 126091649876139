import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../../components/NavbarUser';
import config from '../config.json';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import { decrypter } from "../../Util"
import NavBarAdmin from '../NavBarAdmin';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;
export default function AddCourse() {

    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();

    const [name, setname] = useState('');
    const [code, setcode] = useState('');
    const [desc, setdesc] = useState('');
    const [open, setOpen] = useState(false);

    const [backdrop, setbackdrop] = useState(false)


    const handlebackdropClose = () => {
        setbackdrop(false);
    };

    useEffect(() => {
        if (userdata === "false") {

            navigate('/')

        }
    }, [])


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const handleAddBatch = (e) => {
        navigate('/manageCourse')
    }

    const handlesubmitclick = (e) => {

        const headers = {
            'publicKey': KEY
        }

        setbackdrop(true)

        console.log("json", JSON.stringify({
            course_name: name,
            course_code: code,
            course_description: desc
        }))

        const data = new FormData();
        data.append('model', JSON.stringify({
            course_name: name,
            course_code: code,
            course_description: desc
        }))

        axios.post(BASE_URL + '/addCourse', data, { headers: headers })
            .then(function (responses) {

                console.log(responses.data)
                if (responses.data === 'OK') {
                    setbackdrop(false)
                    setOpen(true);
                    //setbatchname('')
                    setname('')
                    setcode('')
                    setdesc('')
                }
                else{
                    setbackdrop(false)
                }

            })
            .catch(function (errors) {
                setbackdrop(false)
            })

    }

    return (
        <>
            <NavBarAdmin
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="container">
                <Button variant="contained" sx={{ mt: 2, mb: 2 }} onClick={handleAddBatch}>Manage Course</Button>
                <h1>
                    <div className="abc text-white text-center col 10 p-1 mt-1">
                        <h3> Add New Course </h3>
                    </div>
                </h1>
                <Box
                    component="form"
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container
                        alignItems="center"
                        justifyContent="center"
                        justifyItems="center"
                        spacing={1}
                    >
                        <Grid item xs={2} >
                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label="Course Name"
                                    value={name}
                                    onChange={(e) => setname(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={2} >
                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label="Course Code"
                                    value={code}
                                    onChange={(e) => setcode(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={2} >
                            <div>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-name"
                                    label="Course Description"
                                    value={desc}
                                    onChange={(e) => setdesc(e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid container justifyContent='center' sx={{ mt: 3 }} >
                            <Button variant="contained" onClick={handlesubmitclick}>Submit</Button>
                        </Grid>

                    </Grid>
                </Box>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Course Altered"
                //action={action}
                />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdrop}
                    onClick={handlebackdropClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

            </div>
        </>
    )
}
