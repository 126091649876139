import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../NavbarUser';
import config from '../config.json';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import { decrypter } from "../../Util"

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;


export default function ManageBatchSessionCentre() {

    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();
    const [cbsmap, setcbsmap] = useState([]);
    const [open, setOpen] = useState(false);
    const [click, setclick] = useState(false);
    const [del, setdel] = useState('')
    const [dialog, setdialog] = useState(false)

    const columns = [
        {
            field: 'session_batch_id', headerName: 'ID', flex: 0.5
        },

        {
            field: 'center_name', headerName: 'Center Name', flex: 1
        },

        {
            field: 'session_name', headerName: 'Session Name', flex: 1
        },
        {
            field: 'session_start_date', headerName: 'Session Start Date', flex: 1
        },
        {
            field: 'session_end_date', headerName: 'Session End Date', flex: 1
        },
        {
            field: 'batch_name', headerName: 'Batch Name', flex: 1
        },
        {
            field: 'start_time', headerName: 'Start Time', flex: 1
        },
        {
            field: 'end_time', headerName: 'End Time', flex: 1
        },
        {
            field: "Action",
            renderCell: (cellValues) => {
                return (
                    <>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleEditClick(event, cellValues);
                            }}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleClickOpen(event, cellValues);
                            }}
                        >
                            <DeleteIcon></DeleteIcon>
                        </IconButton>
                    </>
                );
            }, flex: 1
        }

    ]

    useEffect(() => {

        if (userdata === "false") {

            navigate('/')

        }

        const headers = {
            'publicKey': KEY

        }

        const params1 = new URLSearchParams();
        params1.append("center_id", userdata.center_id);

        axios.post(BASE_URL + '/getBatchSessionCenterDetails', params1, { headers: headers })
            .then(function (responses) {

                console.log("BatchCourseDetails", responses.data.BatchSessionCenterDetails)
                //setunmapped(responses.data.Beneficiaries)
                setcbsmap(responses.data.BatchSessionCenterDetails)

            })
            .catch(function (errors) {

            })
    }, [click])

    const handleCloseDialog = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setdialog(false);
    };

    const handleClickOpen = (event, cellValues) => {
        setOpen(true);
        setdel(cellValues.row.session_batch_id)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEditClick = (event, cellValues) => {
        navigate('/editbatchsessioncenterMap', {
            state: {
                id: cellValues.row.session_batch_id,
                sid: cellValues.row.session_id, bid: cellValues.row.batch_id,
                st: cellValues.row.start_time, et: cellValues.row.end_time
            }
        })

    }

    const handleDelete = () => {

        const headers = {
            'publicKey': KEY
        }
        console.log("del", del)

        const data = new FormData();
        data.append('model', JSON.stringify({
            session_batch_id: del
        }))

        axios.post(BASE_URL + '/delMapBatchSessionCenter', data, { headers: headers })
            .then(function (responses) {

                console.log(responses.data)
                if (responses.data === 'OK') {

                    setclick(true)
                    setdialog(true);
                }
            })
            .catch(function (errors) {

            })
        setOpen(false);
        setclick(false)

    }

    const handleAddBatch = (e) => {
        e.preventDefault();
        navigate('/addbatchsessioncenterMap')

    }

    return (
        <>
            <NavbarUser title={TITLE} name={userdata.faculty_name} role={userdata.role_name} state={userdata.district_name} />
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}

            <div className="row mx-5">
                <div className="container">
                    <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<FontAwesomeIcon icon={faPlus} className="me-1" />} onClick={handleAddBatch}>Map New Center, Session and Batch</Button>

                    <h1>
                        <div className="abc text-white text-center col 10 p-1 mt-1">
                            <h3> Manage Center Session Batch Mapping </h3>
                        </div>
                    </h1>
                    <Box
                        justifyContent='center'
                        sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={cbsmap}
                            getRowId={(r) => r.session_batch_id}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection={true}
                        // onSelectionModelChange={(newSelectionModel) => {
                        //     setSelectedRows(newSelectionModel);
                        // }}

                        />
                    </Box>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"ARE YOU SURE YOU WANT TO DELETE?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Once deleted it cannot be undone
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDelete}>YES</Button>
                            <Button onClick={handleClose} autoFocus>
                                NO
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={dialog}
                        autoHideDuration={6000}
                        onClose={handleCloseDialog}
                        message="Mapping Deleted"
                    //action={action}
                    />


                </div >
            </div>
        </>
    )
}
