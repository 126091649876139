
import './App.css';
import Navbar from './components/Navbar';
import AddBeneficiary from './components/Beneficiary Management/AddBeneficiary';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { Cookies } from 'react-cookie';
import React, { useState, } from 'react'
import axios from 'axios';
import login from './images/loginpage.jpg';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { Close } from '@mui/icons-material';

import AddFaculty from './components/Faculty Management/AddFaculty';
import config from './components/config.json';
import MapBeneficiary from './components/Beneficiary Management/MapBeneficiary';
import ManageBatch from './components/Batch Management/ManageBatch';
import AddBatch from './components/Batch Management/AddBatch';
import EditBatch from './components/Batch Management/EditBatch'
import ManageBeneficiary from './components/Beneficiary Management/ManageBeneficiary';
import ManageCourses from './components/Course Management/ManageCourses';
import EditCourse from './components/Course Management/EditCourse';
import AddCourse from './components/Course Management/AddCourse';
import ManageSession from './components/Session Management/ManageSession';
import AddSession from './components/Session Management/AddSession';
import EditSession from './components/Session Management/EditSession';
import ManageState from './components/State Management/ManageState';
import AddState from './components/State Management/AddState';
import EditState from './components/State Management/EditState';
import BatchCourseMap from './components/Mapping Management/BatchCourseMap';
import AddBatchCourseMap from './components/Mapping Management/AddBatchCourseMap';
import EditBatchCourseMap from './components/Mapping Management/EditBatchCourseMap';
import ManageBatchSessioncenter from './components/Mapping Management/ManageBatchSessionCentre';
import AddBatchSessionCenter from './components/Mapping Management/AddBatchSessionCenter';
import EditBatchSessionCenter from './components/Mapping Management/EditBatchSessionCenter';
import ManageDistrict from './components/District Management/ManageDistrict';
import AddDistrict from './components/District Management/AddDistrict';
import EditDistrict from './components/District Management/EditDistrict';
import ManageCenter from './components/Center Management/ManageCenter';
import EditCenter from './components/Center Management/EditCenter';
import AddCenter from './components/Center Management/AddCenter';
import ViewBeneficiary from './components/Beneficiary Management/ViewBeneficiary';
import ManageFaculty from './components/Faculty Management/ManageFaculty';
import ViewFaculty from './components/Faculty Management/ViewFaculty';
import ManageRole from './components/Role Management/ManageRole';
import MapRole from './components/Role Management/MapRole';
import EditRole from './components/Role Management/EditRole'
import ViewMapBeneficiary from './components/Beneficiary Management/ViewMapBeneficiary';
import BasePage from './Base Page/BasePage';
import Objective from './Base Page/Objective';
import BaseNavBar from './Base Page/BaseNavBar';
import EditBeneficiary from './components/Beneficiary Management/EditBeneficiary';
import EditFaculty from './components/Faculty Management/EditFaculty';
import Team from './Base Page/Team';
import EditMapBeneficiary from './components/Beneficiary Management/EditMapBeneficiary';
import Gallery from './Admin Page/Gallery';
import AddGallery from './Admin Page/AddGallery';
import AdminDashBoard from './Admin Page/AdminDashBoard';
import EditGallery from './Admin Page/EditGallery';
import BaseGallery from './Base Page/BaseGallery';
import AdminBeneficiary from './Admin Page/AdminBeneficiary';
import ViewBenCertificate from './components/Certificate Management/ViewBenCertificate';
import UpdateBenCertificate from './components/Certificate Management/UpdateBenCertificate';

import { decrypter } from "./Util"
import Reports from './Admin Page/Reports';

import GlobalStyles from '@mui/material/GlobalStyles';
import Certificate from './Admin Page/Certificate';



const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const SECRET = config.SECRET;
const TITLE = config.TITLE;
var CryptoJS = require("crypto-js");

function App() {

  //let loginstatus = sessionStorage.getItem("loginstatus") 
  const navigate = useNavigate();

  // var bytes = CryptoJS.AES.decrypt(cookies.get("loginstatus"), SECRET);
  // var status = bytes.toString(CryptoJS.enc.Utf8);

  if (cookies.get("loginstatus") != undefined) {
    var bytes = CryptoJS.AES.decrypt(cookies.get("loginstatus"), SECRET);
    var status = bytes.toString(CryptoJS.enc.Utf8);

  }

  if (cookies.get("userdata") != undefined) {
    var userdata = decrypter(cookies.get("userdata"))
    var role = userdata.role_id;
  }




  return (
    <>
      <Routes>
        <Route path="/" element={<BasePage />} />
        <Route path="/objective" element={<Objective />} />
        <Route path="/homegallery" element={<BaseGallery />} />
        <Route path="/team" element={<Team />} />
        <Route path="/training" element={<Home />} />
        <Route path="/about" element={<About />} />

        <Route path="/login" element={status === 'true' ? (role == 5 ? <Navigate to="/admindashboard"></Navigate> : <Navigate to="/dashboard"></Navigate>) : <Login></Login>} />
        {/* <Route path="/dashboard" element={<Dashboard email="Email" />} /> */}
        <Route path="/dashboard" element={status ? <Dashboard></Dashboard> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/addbeneficiary' element={status === 'true' ? <AddBeneficiary></AddBeneficiary> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/addFaculty' element={status === 'true' ? <AddFaculty></AddFaculty> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/editFaculty' element={status === 'true' ? <EditFaculty></EditFaculty> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/viewmapBeneficiary' element={status === 'true' ? <ViewMapBeneficiary></ViewMapBeneficiary> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/mapBeneficiary' element={status === 'true' ? <MapBeneficiary></MapBeneficiary> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/editmapBeneficiary' element={status === 'true' ? <EditMapBeneficiary></EditMapBeneficiary> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/manageBatch' element={status === 'true' ? <ManageBatch></ManageBatch> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/addBatch' element={status === 'true' ? <AddBatch></AddBatch> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/editBatch' element={status === 'true' ? <EditBatch></EditBatch> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/manageBeneficiary' element={status === 'true' ? <ManageBeneficiary></ManageBeneficiary> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/editBeneficiary' element={status === 'true' ? <EditBeneficiary></EditBeneficiary> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/manageCourse' element={status === 'true' ? <ManageCourses></ManageCourses> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/editCourse' element={status === 'true' ? <EditCourse></EditCourse> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/addCourse' element={status === 'true' ? <AddCourse></AddCourse> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/manageSession' element={status === 'true' ? <ManageSession></ManageSession> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/addSession' element={status === 'true' ? <AddSession></AddSession> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/editSession' element={status === 'true' ? <EditSession></EditSession> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/manageState' element={status === 'true' ? <ManageState></ManageState> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/addState' element={status === 'true' ? <AddState></AddState> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/editState' element={status === 'true' ? <EditState></EditState> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/batchcourseMap' element={status === 'true' ? <BatchCourseMap></BatchCourseMap> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/addbatchcourseMap' element={status === 'true' ? <AddBatchCourseMap></AddBatchCourseMap> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/editbatchcourseMap' element={status === 'true' ? <EditBatchCourseMap></EditBatchCourseMap> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/batchsessioncenterMap' element={status === 'true' ? <ManageBatchSessioncenter></ManageBatchSessioncenter> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/addbatchsessioncenterMap' element={status === 'true' ? <AddBatchSessionCenter></AddBatchSessionCenter> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/editbatchsessioncenterMap' element={status === 'true' ? <EditBatchSessionCenter></EditBatchSessionCenter> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/manageDistrict' element={status === 'true' ? <ManageDistrict></ManageDistrict> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/editDistrict' element={status === 'true' ? <EditDistrict></EditDistrict> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/addDistrict' element={status === 'true' ? <AddDistrict></AddDistrict> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/manageCenter' element={status === 'true' ? <ManageCenter></ManageCenter> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/addCenter' element={status === 'true' ? <AddCenter></AddCenter> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/editCenter' element={status === 'true' ? <EditCenter></EditCenter> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/viewBen' element={status === 'true' ? <ViewBeneficiary></ViewBeneficiary> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/manageFaculty' element={status === 'true' ? <ManageFaculty></ManageFaculty> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/viewFaculty' element={status === 'true' ? <ViewFaculty></ViewFaculty> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/manageRole' element={status === 'true' ? <ManageRole></ManageRole> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/maprole' element={status === 'true' ? <MapRole></MapRole> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/editRole' element={status === 'true' ? <EditRole></EditRole> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/galleryManagement' element={status === 'true' ? <Gallery></Gallery> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/addGallery' element={status === 'true' ? <AddGallery></AddGallery> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/admindashboard' element={status === 'true' ? <AdminDashBoard></AdminDashBoard> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/editgallery' element={status === 'true' ? <EditGallery></EditGallery> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/manageBeneficiaryAdmin' element={status === 'true' ? <AdminBeneficiary></AdminBeneficiary> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/manageCertificate' element={status === 'true' ? <ViewBenCertificate></ViewBenCertificate> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/updateCertificate' element={status === 'true' ? <UpdateBenCertificate></UpdateBenCertificate> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/reports' element={status === 'true' ? <Reports></Reports> : <Navigate to="/login"></Navigate>}></Route>
        <Route path='/generateCertificate' element={status === 'true' ? <Certificate></Certificate> : <Navigate to="/login"></Navigate>}></Route>

      </Routes>
      <GlobalStyles styles={{
        "& .MuiTextField-root": {

          "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
            color: "red",
            "&::after": {

            }
          }
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          textOverflow: "clip",
          whiteSpace: "pre-wrap",
          lineHeight: 1,
          textAlign: "center"
        },
        "& .MuiDataGrid-cellContent": {
          textOverflow: "clip",
          whiteSpace: "pre-wrap",
          lineHeight: 2,
          textAlign: "center"
        },
        boxShadow: 2,
        '& .MuiTablePagination-selectLabel': {
          marginTop: 'auto'
        },
        '& .MuiTablePagination-displayedRows': {
          marginTop: 'auto'
        },
        "& .MuiTypography-string": {
          textOverflow: "clip",
          whiteSpace: "pre-wrap",
          lineHeight: 2,
          textAlign: "center"
        },

      }} />

    </>
  );
}

export default App;

function About() {
  return (
    <>
      <div>
        <Navbar title={TITLE} />
        <h2>About</h2>
      </div>
    </>
  )
}


function Login() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailErrors, setEmailErrors] = useState([]);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const navigate = useNavigate();

  const [alertmsg, setalertmsg] = useState('')

  const [alert, setalert] = useState(false);


  const handleClick = (e) => {
    e.preventDefault();
    const user = { email, password }
    //console.log(user)

    // axios({
    //   method: 'post',
    //   url: 'http://localhost:8888/test',
    //   data: {
    //     email: user.email,
    //     password: user.password
    //   },
    // });

    const headers = {
      'publicKey': KEY
    }

    const md5 = require('md5')

    axios.post(BASE_URL + '/login', {
      email: user.email,
      password: md5(user.password)
    }, { headers: headers })
      .then(function (response) {
        //console.log(response.data);
        //setResponse(response.data);
        //let a = response;
        //console.log(a);

        // navigate('/dashboard', { state: { email: response.data } });
        //sessionStorage.setItem("loginstatus", true);


        if (response.status == 200) {

          if (response.data.Data.active_status === "Y") {

            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(response.data.Data), SECRET).toString();
            var ciphertext2 = CryptoJS.AES.encrypt('true', SECRET).toString();


            cookies.set("loginstatus", ciphertext2, { sameSite: 'strict', path: '/' })

            cookies.set("userdata", ciphertext, { sameSite: 'strict', path: '/' })

            if (response.data.Data.role_id == 5) {
              navigate('/admindashboard');

            } else {
              navigate('/dashboard');
            }

            //navigate('/dashboard');
            //console.log(JSON.stringify(response));

          }
          else {
            setalert(true)
            setalertmsg('Login is Disabled for this User')
          }

        } else {

          setalert(true)
          setalertmsg('Error While Logging In')
        }

        //console.log(response.headers['Set-Cookie'])
      })
      .catch(function (error) {
        //console.log(error);
        //console.log("I am at error ", error.response.data.errors)
        //console.log("first", error.response.status)

        if (error.response.status === 401) {

          setalert(true)
          setalertmsg('Email or Password Is Incorrect')

        }


        //console.log(error.response.data.errors[0].defaultMessage)
        //let i = error.response.data.errors.length;
        let emailtext = [];
        let passtext = [];
        if (error.response.data.errors != null) {

          error.response.data.errors.forEach(error => {

            if (error.field === "email") {
              //setEmailError(error.defaultMessage)
              // printertext = printertext + " " + error.defaultMessage;
              // setEmailErrors(error.defaultMessage)
              emailtext.push(error.defaultMessage)
              emailtext.push(<br></br>)
            }
            if (error.field === "password") {
              //setEmailError(error.defaultMessage)
              // printertext = printertext + " " + error.defaultMessage;
              // setEmailErrors(error.defaultMessage)
              passtext.push(error.defaultMessage)
              passtext.push(<br></br>)
            }

          });

        }

        setEmailErrors(emailtext)
        setPasswordErrors(passtext)

        //console.log("I am at error "+error.status+" "+error.response.data.errors);
      });
  }

  return (
    <>
      <div>
        <BaseNavBar title={TITLE} />
        {/* carousel starts */}
        <div className="row">
          <div className="container ">
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={login} className="d-block w-100" alt="Max-width 100%" />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </button>
            </div>
          </div>
        </div>
        {/* carousel ends */}

        {/* form starts */}
        <div className="container ">
          <div className="row mt-2">
            <div className="card col-md-4 offset-md-4">
              <Collapse in={alert}>
                <Alert
                  severity='error'
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setalert(false);
                      }}
                    >
                      <Close fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mt: 2 }}
                >
                  {alertmsg}
                </Alert>
              </Collapse>
              <form method="post" className="bg-white p-4">
                <h3 className="text-center text-info">Login</h3>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" id="email" />
                  {

                    emailErrors.map((error, i) =>
                      <span key={i} style={{ color: 'red', fontSize: '12px' }}>{error}</span>

                    )
                  }

                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control " id="password" aria-describedby="emailHelp" />
                  {

                    passwordErrors.map((passerror, j) =>
                      <span key={j} style={{ color: 'red', fontSize: '12px' }}>{passerror}</span>)}
                </div>
                <button type="submit" className="btn btn-primary" onClick={handleClick}>Login</button>
              </form>
            </div>
          </div>
        </div>
        {/* form ends */}
      </div>
    </>
  )
}









