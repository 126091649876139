import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../../components/NavbarUser';
import config from '../config.json';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import moment from 'moment';
import NavBarFaculty from '../NavBarFaculty';
import NavBarAdmin from '../NavBarAdmin';

import { decrypter } from "../../Util"

const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;

export default function ViewBeneficiary() {

    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();
    const location = useLocation();

    const [img, setimg] = useState();



    console.log("data", location.state.data)

    useEffect(() => {
        if (userdata === "false") {

            navigate('/')

        }
    }, [])

    const handleAddBatch = (e) => {

        e.preventDefault();

        if(userdata.role_id == 5){
            navigate('/manageBeneficiaryAdmin')

        }
        else{
            navigate('/manageBeneficiary')
        }
        

    }


    return (
        <>

            {userdata.role_id == 1 ? <NavbarUser
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
            /> : userdata.role_id == 5 ? <NavBarAdmin
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
            /> : <NavBarFaculty
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />}
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}

            <div className="container">

                <div className="row mt-4 justify-content-center">
                    
                    <Card sx={{ width: 1000, alignItems: 'center', boxShadow: 10 }}>
                        <CardHeader
                            avatar={
                                location.state.data.ben_image === null ? <Avatar alt={location.state.data.ben_name}
                                    src={location.state.data.ben_name}
                                    sx={{ width: 100, height: 100 }}
                                    variant="rounded"
                                    style={{ alignItems: 'center' }} /> : <Avatar alt={location.state.data.ben_name}
                                        src={BASE_URL + "/" + "images" + "/" + location.state.data.ben_image}
                                        //src={"C:/benImg" + "/" + location.state.data.ben_image}
                                        sx={{ width: 100, height: 100 }}
                                        variant="rounded"
                                        style={{ alignItems: 'center' }} />
                            }
                            title={location.state.data.ben_name}
                            subheader={location.state.data.ben_email_id}
                            style={{ textAlign: 'left' }}
                            titleTypographyProps={{ variant: 'h3', textTransform: 'uppercase' }}
                            subheaderTypographyProps={{ variant: 'h5', }}

                        >
                        </CardHeader>
                        <Divider >DETAILS</Divider>
                        <CardContent>
                            <Box
                                component="form"
                                sx={{
                                    marginTop: 4,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",

                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} >
                                    <Grid item xs={2} >

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                            Beneficiary ID:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1" >
                                            {location.state.data.ben_id}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={1}>

                                        <Typography variant="subtitle1">

                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1" >
                                            State Name:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3} >

                                        <Typography variant="subtitle1" >
                                            {location.state.data.state_name}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                            District Name:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.district_name}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={1}>

                                        <Typography variant="subtitle1">

                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                            Date of Birthhh:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {/* {moment(location.state.data.ben_dob).format('DD/MM/YYYY')} */}
                                            {/* {location.state.data.ben_dob === null ? "Not Provided" : moment(location.state.data.ben_dob).format('DD/MM/YYYY')} */}
                                            {/* {location.state.data.ben_dob} */} 
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                            Guardian Name:
                                        </Typography>

                                    </Grid>

                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.ben_fname}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={1}>

                                        <Typography variant="subtitle1">

                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                            Relation:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.relation == "F" ? "Father" : (location.state.data.relation == "M" ? "Mother" : "Husband")}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={1}>

                                        <Typography variant="subtitle1">

                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                            Address:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.ben_address}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={1}>

                                        <Typography variant="subtitle1">

                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                            Mobile:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.ben_mobile_no === null ? "Not Provided" : location.state.data.ben_mobile_no }
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                            Proof ID Type:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.proof_type}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={1}>

                                        <Typography variant="subtitle1">

                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} s>

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                            ID Number:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.ben_id_proof_no}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                            Caste:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.caste_name}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={1}>

                                        <Typography variant="subtitle1">

                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                            APL/BPL:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.apl_bpl}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                            Qualification:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.qual_name}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={1}>

                                        <Typography variant="subtitle1">

                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                            Course Opted:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.course_name}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                            Updated By:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">
                                            {location.state.data.faculty_name}
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={1}>

                                        <Typography variant="subtitle1">

                                        </Typography>

                                    </Grid>
                                    <Grid item xs={2} >

                                        <Typography sx={{ fontWeight: 500 }} variant="subtitle1">
                                            Updated Date:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography variant="subtitle1">

                                            {
                                                moment(location.state.data.updated_date).format('MMMM Do YYYY')
                                            }
                                        </Typography>

                                    </Grid>

                                </Grid>

                            </Box>
                        </CardContent>

                    </Card>
                    <Button variant="contained" sx={{ mt: 2, mb: 2, width: 400 }} onClick={handleAddBatch}>Back</Button>
                </div>
            </div>
        </>
    )
}
