import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../../components/NavbarUser';
import config from '../config.json';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import { decrypter } from "../../Util"



const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;


export default function ManageBatch() {

  let userdata = decrypter(cookies.get("userdata"))
  const navigate = useNavigate();
  const [batches, setbatches] = useState([]);
  const [open, setOpen] = useState(false);
  const [click, setclick] = useState(false);
  const [del, setdel] = useState('')
  const [dialog, setdialog] = useState(false)

  const columns = [
    {
      field: 'batch_id', headerName: 'ID', flex: 1, align: 'center', headerAlign: 'center'
    },

    {
      field: 'batch_name', headerName: 'Batch Name', flex: 1, align: 'center', headerAlign: 'center'
    },
    {
      field: "Action",
      renderCell: (cellValues) => {
        return (
          <>
            <IconButton
              //   variant="contained"
              //   color="primary"
              //   size='medium'
              //   startIcon={<EditIcon></EditIcon>}

              onClick={(event) => {
                handleEditClick(event, cellValues);
              }}
            >
              <EditIcon></EditIcon>
            </IconButton>
            <IconButton
              //   variant="contained"
              //   color="primary"
              //   size='medium'
              //   startIcon={<EditIcon></EditIcon>}

              onClick={(event) => {
                handleClickOpen(event, cellValues);
              }}
            >
              <DeleteIcon></DeleteIcon>
            </IconButton>
          </>
        );
      }, flex: 1, align: 'center', headerAlign: 'center'
    }

  ]

  useEffect(() => {

    if (userdata === "false") {

      navigate('/')

    }

    const headers = {
      'publicKey': KEY

    }

    axios.post(BASE_URL + '/getBatch', {}, { headers: headers })
      .then(function (responses) {

        console.log("batches", responses.data.Batches)
        //setunmapped(responses.data.Beneficiaries)
        setbatches(responses.data.Batches)

      })
      .catch(function (errors) {

      })
  }, [click])

  const handleCloseDialog = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setdialog(false);
  };

  // dialogbox open close functions
  const handleClickOpen = (event, cellValues) => {
    setOpen(true);
    setdel(cellValues.row.batch_id)
  };

  const handleClose = () => {
    setOpen(false);
  };

  // dialogbox open close functions

  //edit delete button onclick functions//

  const handleEditClick = (event, cellValues) => {
    navigate('/editBatch', { state: { id: cellValues.row.batch_id, name: cellValues.row.batch_name } })

  }


  const handleDelete = () => {
    console.log("The del ", del)


    const headers = {
      'publicKey': KEY
    }

    const data = new FormData();
    data.append('model', JSON.stringify({
      batch_id: del
    }))

    axios.post(BASE_URL + '/delBatch', data, { headers: headers })
      .then(function (responses) {

        console.log(responses.data)
        if (responses.data === 'OK') {

          setclick(true)
          setdialog(true);

        }




      })
      .catch(function (errors) {

      })
    setOpen(false);
    setclick(false)
    //navigate('/manageBatch')
    //setbatches(batches.filter(item => item.batch_id !== del))


  }

  //edit delete button onclick functions//

  //add batch onclick function///////

  const handleAddBatch = (e) => {
    e.preventDefault();
    navigate('/addBatch')

  }

  //add batch onclick function///////


  return (
    <>
      <NavbarUser title={TITLE} name={userdata.faculty_name} role={userdata.role_name} state={userdata.district_name} />
      {/* carousel starts */}
      <div className="row">
        <div className="container ">
          <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={login} className="d-block w-100" alt="Max-width 100%" />
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </button>
          </div>
        </div>
      </div>
      {/* carousel ends */}

      <div className="container">
        <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<FontAwesomeIcon icon={faPlus} className="me-1" />} onClick={handleAddBatch}>Add Batch</Button>

        <h1>
          <div className="abc text-white text-center col 10 p-1 mt-1">
            <h3> Manage Batch Details </h3>
          </div>
        </h1>
        <Box
          justifyContent='center'
          sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={batches}
            getRowId={(r) => r.batch_id}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection={true}
          // onSelectionModelChange={(newSelectionModel) => {
          //     setSelectedRows(newSelectionModel);
          // }}

          />
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"ARE YOU SURE YOU WANT TO DELETE?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Once deleted it cannot be undone
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete}>YES</Button>
            <Button onClick={handleClose} autoFocus>
              NO
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={dialog}
          autoHideDuration={6000}
          onClose={handleCloseDialog}
          message="Batch Deleted"
        //action={action}
        />


      </div >
    </>
  )
}
