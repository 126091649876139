import NavbarUser from "../NavbarUser";
import React, { useState, useEffect } from "react";
import login from "../../images/login.jpg";
import { Cookies } from "react-cookie";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import config from "../config.json";
import Typography from "@mui/material/Typography"
import { isValid, parseISO } from "date-fns";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import NavBarFaculty from "../NavBarFaculty"
import CloseIcon from '@mui/icons-material/Close';
import regex from "../regex.json";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { decrypter } from "../../Util"
import { useNavigate } from 'react-router-dom'

const cookies = new Cookies();
//const BASE_URL = "http://10.240.13.181:8888";
const BASE_URL = config.SERVER_URL;

const KEY = config.KEY;
const TITLE = config.TITLE;

export default function AddBeneficiary() {
  let userdata = decrypter(cookies.get("userdata"))
  const navigate = useNavigate();
  const [did, setdid] = useState();
  const [districts, setdistricts] = useState([]);
  const [proofs, setproofs] = useState([]);
  const [castes, setcastes] = useState([]);
  const [sessions, setsessions] = useState([]);
  const [centers, setcenters] = useState([]);
  const [getcourses, setgetcourses] = useState([]);
  const [getquals, setgetquals] = useState([]);

  const [fcenter, setfcenter] = useState([]);

  const [d, setd] = useState([]);

  const [district, setdistrict] = useState("");
  const [errdistrict, seterrdistrict] = useState('')
  const [errdistrictb, seterrdistrictb] = useState(false)

  const [bname, setbname] = useState("");
  const [errbname, seterrbname] = useState('')
  const [errbn, seterrbn] = useState(false)

  const [fname, setfname] = useState("");
  const [errfname, seterrfname] = useState("")
  const [errfnameb, seterrfnameb] = useState(false)

  const [mname, setmname] = useState("");

  const [date, setdate] = useState(null);
  const [errdate, seterrdate] = useState("")
  const [errdateb, seterrdateb] = useState(false)

  const [address, setaddress] = useState("");
  const [erradd, seterradd] = useState("")
  const [erraddb, seterraddb] = useState(false)

  const [email, setemail] = useState("");
  const [erremail, seterremail] = useState("")
  const [erremailb, seterremailb] = useState(false)

  const [mobile, setmobile] = useState("");
  const [errmob, seterrmob] = useState("")
  const [errmobb, seterrmobb] = useState(false)

  const [apl, setapl] = useState("");
  const [errapl, seterrapl] = useState("")
  const [erraplb, seterraplb] = useState(false)

  const [qual, setqual] = useState("");
  const [errqual, seterrqual] = useState("")
  const [errqualb, seterrqualb] = useState(false)

  const [courses, setcourses] = useState("");
  const [errcourses, seterrcourses] = useState("")
  const [errcoursesb, seterrcoursesb] = useState(false)

  const [pid, setpid] = useState();
  const [errpid, seterrpid] = useState("")
  const [errpidb, seterrpidb] = useState("")

  const [pnum, setpnum] = useState("");
  const [errpnum, seterrpnum] = useState("")
  const [errpnumb, seterrpnumb] = useState(false)

  const [bcaste, setbcaste] = useState("");
  const [errbcaste, seterrbcaste] = useState("")
  const [errbcasteb, seterrbcasteb] = useState(false)

  const [sid, setsid] = useState("");
  const [errsid, seterrsid] = useState('')
  const [errsidb, seterrsidb] = useState(false)

  const [gender, setgender] = useState("");
  const [errgender, seterrgender] = useState("")
  const [errgenderb, seterrgenderb] = useState(false)

  const [relation, setrelation] = useState("")
  const [errrelation, seterrrelation] = useState("")
  const [errrelationb, seterrrelationb] = useState(false)

  const [cid, setcid] = useState(userdata.center_id);

  const [filename, setFilename] = useState("");
  const [file, setfile] = useState();

  const [formfilename, setformfilename] = useState("")
  const [formfile, setformfile] = useState()

  const [open, setOpen] = useState(false);

  const [btncolor, setbtncolor] = useState("primary")
  const [errbtn, seterrbtn] = useState("")

  const [formbtncolor, setformbtncolor] = useState("primary")
  const [errformbtn, seterrformbtn] = useState("")

  const [preview, setpreview] = useState()
  const [hiddenb, sethiddenb] = useState(true)

  const [openalertimg, setopenalertimg] = useState(false)
  const [openalertform, setopenalertform] = useState(false)
  const [imagealert, setimagealert] = useState("")
  const [formalert, setformalert] = useState("")

  const [backdrop, setbackdrop] = useState(false)

  const [snackmsg, setsnackmsg] = useState("")

  var zeroFill = require('zero-fill')




  console.log("i am in add");

  useEffect(() => {

    if (userdata === "false") {

      navigate('/')

    }

    const headers = {
      publicKey: KEY,
    };
    const params = new URLSearchParams();
    params.append("stateId", userdata.state_id);
    console.log("did", userdata.district_id)
    setdid(userdata.district_id)

    const params1 = new URLSearchParams();
    params1.append("cid", userdata.center_id);
    //console.log("params",params)
    // axios.post(BASE_URL + '/district', params)
    //   .then(function (response) {

    //     //let districts = [];

    //     //console.log(JSON.stringify(response.data.districts));
    //     //districts.push()
    //     console.log(response.data.districts)
    //     setdistricts(response.data.districts)

    //   }).catch(function (error) {

    //     console.log(error)

    //   });

    const getstate = axios.post(BASE_URL + "/district", params, {
      headers: headers,
    });
    const getcaste = axios.post(
      BASE_URL + "/getAllCastes",
      {},
      { headers: headers }
    );
    const getproof = axios.post(
      BASE_URL + "/getIdProofs",
      {},
      { headers: headers }
    );

    const getsession = axios.post(
      BASE_URL + "/getTSessionactive",
      params1,
      { headers: headers }
    );

    const getcenter = axios.post(
      BASE_URL + "/getCenter",
      {},
      { headers: headers }
    );

    const getcourses = axios.post(
      BASE_URL + "/getCourses",
      {},
      { headers: headers }
    );

    const getqual = axios.post(
      BASE_URL + "/getqual",
      {},
      { headers: headers }
    );


    Promise.all([getstate, getcaste, getproof, getsession, getcenter, getcourses, getqual])
      .then(function (responses) {
        setdistricts(responses[0].data.districts);
        setcastes(responses[1].data.Castes);
        setproofs(responses[2].data.Proofs);
        setsessions(responses[3].data.CenterSession);
        setcenters(responses[4].data.Centers);
        setgetcourses(responses[5].data.Courses);
        setgetquals(responses[6].data.Qualification)
      })
      .catch(function (errors) {
        console.log("state errors", errors[0]);
        console.log("caste errors", errors[1]);
        console.log("proof errors", errors[2]);
        console.log("session errors", errors[3]);
        console.log("center errors", errors[4]);
        console.log("courses error", errors[5]);
        console.log("qual error", errors[6]);
      });

  }, []);

  useEffect(() => {
    if (!file) {
      setpreview(undefined)
      sethiddenb(true)
      return
    }

    const objectUrl = URL.createObjectURL(file)
    sethiddenb(false)
    setpreview(objectUrl)


    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [file])

  function clearfields() {
    setdistrict('')
    setbname('')
    setfname('')
    setmname('')
    setdate(null)
    setaddress('')
    setrelation('')
    setemail('')
    setmobile('')
    setapl('')
    setqual('')
    setcourses('')
    setpid('')
    setpnum('')
    setbcaste('')
    setsid('')
    setFilename('')
    setfile('')
    setcid('')
  }

  const handlefilechange = (e) => {
    //const [files] = e.target.files;
    //console.log("data", files);
    setFilename(e.target.files[0].name);
    setfile(e.target.files[0]);



    //setFilename(e.target.files);
  };
  console.log("data from file value", file);

  const handleformfilechange = (e) => {
    //const [files] = e.target.files;
    //console.log("data", files);
    setformfilename(e.target.files[0].name);
    setformfile(e.target.files[0]);



    //setFilename(e.target.files);
  };
  console.log("data formfile value", formfile);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handlebackdropClose = () => {
    setbackdrop(false);
  };

  const handlesubmitclick = (e) => {
    e.preventDefault();

    if (validation()) {

      const headers = {
        publicKey: KEY,

      };

      setbackdrop(true)



      //console.log("sessionname ", sessions.filter(op => op.session_id === sid).map((option) => option.session_name).find(o => o))

      const data = new FormData();
      data.append("file", file);
      data.append("formfile", formfile);
      data.append(
        "model",
        JSON.stringify({
          ben_state_id: userdata.state_id, //fixing to 1 as only assam state has been implemented
          ben_dist_id: district,
          center_id: userdata.center_id,
          ben_name: bname.toUpperCase().trim(),
          ben_fname: fname.toUpperCase().trim(),
          ben_mname: mname.toUpperCase().trim(),
          ben_dob: ((moment(new Date(date)).format("YYYY-MM-DD") === "1970-01-01") ? null : moment(new Date(date)).format("YYYY-MM-DD")),
          ben_address: address,
          ben_email_id: email,
          ben_mobile_no: (mobile === "" ? null : mobile),
          session_id: sid,
          ben_id_type: pid,
          ben_id_proof_no: pnum,
          ben_caste_id: bcaste,
          apl_bpl: apl,
          ben_qual: qual,
          ben_course_opted: courses,
          updated_by: userdata.faculty_sys_id,
          gender: gender,
          relation: relation
        })
      );

      console.log(
        "sending data",
        JSON.stringify({
          ben_state_id: userdata.state_id, //fixing to 1 as only assam state has been implemented
          ben_dist_id: district,
          center_id: userdata.center_id,
          ben_name: bname,
          ben_fname: fname,
          ben_mname: mname,
          ben_dob: ((moment(new Date(date)).format("YYYY-MM-DD") === "1970-01-01") ? null : moment(new Date(date)).format("YYYY-MM-DD")),
          ben_address: address,
          ben_email_id: email,
          ben_mobile_no: (mobile === "" ? null : mobile),
          session_id: sid,
          ben_id_type: pid,
          ben_id_proof_no: pnum,
          ben_caste_id: bcaste,
          apl_bpl: apl,
          ben_qual: qual,
          ben_course_opted: courses,
          updated_by: userdata.faculty_sys_id,
          gender: gender,
          relation: relation
        })
      );

      axios
        .post(BASE_URL + "/addBeneficiary", data, { headers: headers })
        .then(function (response) {
          console.log(response);
          if (response.data === "OK" && response.status == 202) {
            setbackdrop(false)
            setsnackmsg("Beneficiary Added Successfully")
            setOpen(true);
            clearfields();
            setTimeout(function () {
              window.location.reload(false);
            }, 3000);

          }
          else {
            setbackdrop(false)

            setsnackmsg("Beneficiary Addition Failed")
            setOpen(true);
          }
        })
        .catch(function (errors) {
          setbackdrop(false)
          setsnackmsg("Beneficiary Addition Failed")
          setOpen(true);
        });

      console.log(JSON.stringify(moment(new Date(date)).format("YYYY-MM-DD")))

    }
  };

  function validation() {
    let isvalid = true;

    if (bname === "") {
      seterrbn(true)
      seterrbname("Name Cannot be Empty")

      isvalid = false;

    } else if (!bname.match(regex.letterandspace)) {

      seterrbn(true)
      seterrbname("Please Enter Valid Name")
      isvalid = false;

    } else {
      seterrbn(false)
      seterrbname("")
    }

    if (sid === "") {
      seterrsidb(true)
      seterrsid("Please Select Session")
      isvalid = false;

    } else {
      seterrsidb(false)
      seterrsid("")
    }

    if (fname === "") {
      seterrfnameb(true)
      seterrfname("Father/Husband name cannot be empty")
      isvalid = false;

    } else if (!fname.match(regex.letterandspace)) {

      seterrfnameb(true)
      seterrfname("Please Enter Valid Name")
      isvalid = false;

    } else {

      seterrfnameb(false)
      seterrfname("")

    }

    // if (moment(new Date(date)).format("YYYY-MM-DD") === "1970-01-01") {
    //   seterrdateb(true)
    //   seterrdate("Please Enter Date")
    //   isvalid = false

    // } else {
    //   seterrdateb(false)
    //   seterrdate("")
    // }

    if (address === "") {
      seterraddb(true)
      seterradd("Address Cannot Be Empty")
      isvalid = false
    } else if (!address.match(regex.letternumberspacecoma)) {
      seterraddb(true)
      seterradd("Enter Valid Address")
      isvalid = false
    } else {
      seterraddb(false)
      seterradd("")
    }

    // if (email === "") {
    //   seterremailb(false)
    //   seterremail("")

    // } else if (!email.match(regex.emailpattern)) {
    //   seterremailb(true)
    //   seterremail("Please Enter Valid Email")
    //   isvalid = false

    // } else {

    //   seterremailb(false)
    //   seterremail("")

    // }

    if (email !== "") {

      if (!email.match(regex.emailpattern)) {
        seterremailb(true)
        seterremail("Please Enter Valid Email")
        isvalid = false
      } else {
        seterremailb(false)
        seterremail("")
      }

    } else {
      seterremailb(false)
      seterremail("")
    }



    // if (mobile === "") {
    //   seterrmobb(true)
    //   seterrmob("Mobile Number Cannot Be Empty")
    //   isvalid = false;

    // } else if (!mobile.match(regex.mobile)) {
    //   seterrmobb(true)
    //   seterrmob("Please Enter Valid Mobile Number")
    //   isvalid = false;

    // } else {
    //   seterrmobb(false)
    //   seterrmob("")

    // }

    if (mobile !== "") {

      if (!mobile.match(regex.mobile)) {
        seterrmobb(true)
        seterrmob("Please Enter Valid Mobile Number")
        isvalid = false;

      } else {
        seterrmobb(false)
        seterrmob("")
      }
    } else {
      seterrmobb(false)
      seterrmob("")
    }

    if (qual === "") {
      seterrqualb(true)
      seterrqual("Qualification Cannot be Empty")
      isvalid = false

    } else {
      seterrqualb(false)
      seterrqual("")

    }

    if (courses === "") {
      seterrcoursesb(true)
      seterrcourses("Courses Cannot be Empty")
      isvalid = false

    } else {
      seterrcoursesb(false)
      seterrcourses("")

    }

    // if (pid === "") {
    //   seterrpidb(true)
    //   seterrpid("Please Select ID Type")
    //   isvalid = false

    // } else {
    //   seterrpidb(false)
    //   seterrpid("")


    // }

    if (district === "") {
      seterrdistrictb(true)
      seterrdistrict("Please District")
      isvalid = false

    } else {
      seterrdistrictb(false)
      seterrdistrict("")


    }

    // if (pnum === "") {
    //   seterrpnumb(true)
    //   seterrpnum("Proof Number Cannot be Empty")
    //   isvalid = false

    // } else if (!pnum.match(regex.letternumberspace)) {
    //   seterrpnumb(true)
    //   seterrpnum("Please Enter Valid Data")
    //   isvalid = false

    // } else {

    //   seterrpnumb(false)
    //   seterrpnum("")

    // }


    if (!pnum === "") {

      if (!pnum.match(regex.letternumberspace)) {
        seterrpnumb(true)
        seterrpnum("Please Enter Valid Data")
        isvalid = false

      } else {

        seterrpnumb(false)
        seterrpnum("")

      }

    } else {
      seterrpnumb(false)
      seterrpnum("")
    }



    if (bcaste === "") {
      seterrbcasteb(true)
      seterrbcaste("Please Select Caste")
      isvalid = false

    } else {
      seterrbcasteb(false)
      seterrbcaste("")


    }

    if (file === undefined) {
      setbtncolor("error")
      seterrbtn("Please Upload Image")
      setopenalertimg(true)
      setimagealert("Please Upload Image")
      isvalid = false

    } else if (file.size > 512000) {

      setbtncolor("error")
      seterrbtn("Image Size is Greater than 500kb")
      setopenalertimg(true)
      setimagealert("Image Size is Greater than 500kb")
      //alert("Image size is greater than 500kb")
      isvalid = false

    } else {
      setbtncolor("primary")
      seterrbtn("")
      setopenalertimg(false)
      setimagealert("")

    }

    if (formfile === undefined) {
      setformbtncolor("error")
      seterrformbtn("Please Upload Form")
      setopenalertform(true)
      setformalert("Please Upload Form")
      isvalid = false

    } else if (formfile.size > 512000) {

      setformbtncolor("error")
      seterrbtn("File Size is Greater than 500kb")
      //alert("Uploaded Form size is greater than 500kb")
      setopenalertform(true)
      setformalert("File Size is Greater than 500kb")
      isvalid = false

    } else {
      setformbtncolor("primary")
      seterrbtn("")
      //alert("Uploaded Form size is greater than 500kb")
      setopenalertform(false)
      setformalert("")

    }

    if (gender === "") {
      seterrgenderb(true)
      seterrgender("Please Select Gender")
      isvalid = false

    } else {
      seterrgenderb(false)
      seterrgender("")


    }

    if (relation === "") {
      seterrrelationb(true)
      seterrrelation("Please Select Relation")
      isvalid = false

    } else {
      seterrrelationb(false)
      seterrrelation("")

    }


    return isvalid;

  }

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  const handleChange = (e) => {

    setdistrict(e.target.value)
    //console.log("first", district)
    //setfcenter(centers.filter(op => op.center_dist_id === district))

  }

  return (
    <>
      {userdata.role_id == 1 ? <NavbarUser
        title={TITLE}
        name={userdata.faculty_name}
        role={userdata.role_name}
        state={userdata.district_name}
      /> : <NavBarFaculty
        title={TITLE}
        name={userdata.faculty_name}
        role={userdata.role_name}
        state={userdata.district_name}
        image={userdata.faculty_image}
      />}

      {/* carousel starts */}
      <div className="row">
        <div className="container ">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={login}
                  className="d-block w-100"
                  alt="Max-width 100%"
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </button>
          </div>
        </div>
      </div>
      {/* carousel ends */}
      <div className="container">
        <h1>
          <div className="abc text-white text-center col 10 p-1 mt-2">
            <h3> Add Beneficiary Details </h3>
          </div>
        </h1>
        <Box
          component="form"
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <div>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-read-only-input"
                  label="State Name"
                  InputProps={{
                    readOnly: true,
                  }}
                  //defaultValue={userdata.state_name}
                  value={userdata.state_name}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  error={errdistrictb}
                  helperText={errdistrict}
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-select-district"
                  select
                  label="District"
                  value={district}
                  onChange={(e) =>
                    setdistrict(e.target.value)
                    //setdistrict(centers.filter(op => op.center_dist_id === district))

                  }
                // InputProps={{
                //   readOnly: true,
                // }}
                //onChange={handleChange}
                >
                  {districts.map((option) => (
                    <MenuItem
                      key={option.district_id}
                      value={option.district_id}
                    >
                      {option.district_name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-select-district"
                  //select
                  label="  Center"
                  value={userdata.center_name}
                  InputProps={{
                    readOnly: true,
                  }}
                //onChange={(e) => setcid(e.target.value)}
                //onChange={handleChange}
                >
                  {/* {(centers.filter(op => op.center_dist_id === district)).map((option) => (
                    <MenuItem
                      key={option.center_id}
                      value={option.center_id}
                    >
                      {option.center_name}
                    </MenuItem>
                  ))} */}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  error={errsidb}
                  helperText={errsid}
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-select-district"
                  select
                  label="Select Session"
                  value={sid}
                  onChange={(e) => setsid(e.target.value)}
                //onChange={handleChange}
                >
                  {sessions.map((option) => (
                    <MenuItem
                      key={option.session_id}
                      value={option.session_id}
                    >
                      {option.center_name} , {option.session_name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  error={errbn}
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-name"
                  label="Enter Beneficiary Name"
                  helperText={errbname}
                  //defaultValue={userdata.state_name}
                  value={bname}
                  onChange={(e) => setbname(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  error={errgenderb}
                  helperText={errgender}
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-select-district"
                  select
                  label="Gender"
                  value={gender}
                  onChange={(e) => setgender(e.target.value)}
                //onChange={handleChange}
                >
                  <MenuItem key={1} value={"Male"}>
                    Male
                  </MenuItem>
                  <MenuItem key={2} value={"Female"}>
                    Female
                  </MenuItem>
                </TextField>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  error={errfnameb}
                  helperText={errfname}
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-name"
                  label="Enter Guardian Name"
                  //defaultValue={userdata.state_name}
                  value={fname}
                  onChange={(e) => setfname(e.target.value)}
                />
              </div>
            </Grid>
            {/* <Grid item xs={4}>
              <div>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-name"
                  label="Enter Mother's Name"
                  //defaultValue={userdata.state_name}
                  value={mname}
                  onChange={(e) => setmname(e.target.value)}
                />
              </div>
            </Grid> */}
            <Grid item xs={4}>
              <div>
                <TextField
                  error={errrelationb}
                  helperText={errrelation}
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-select-district"
                  select
                  label="Enter Relation"
                  value={relation}
                  onChange={(e) => setrelation(e.target.value)}
                //onChange={handleChange}
                >
                  <MenuItem key={1} value={"F"}>
                    Father
                  </MenuItem>
                  <MenuItem key={2} value={"M"}>
                    Mother
                  </MenuItem>
                  <MenuItem key={3} value={"H"}>
                    Husband
                  </MenuItem>
                </TextField>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker

                    sx
                    label="Date of Birth"
                    inputFormat="dd/MM/yyyy"
                    utcOffset={0}
                    value={date}
                    disableFuture

                    onChange={(newValue) => {
                      // const offsetDate = new Date(newValue.getTime() - (newValue.getTimezoneOffset() * 60000));
                      // //const offsetDate = moment(new Date(newValue.getTime() - (newValue.getTimezoneOffset() * 60000))).format("YYYY-MM-DD");
                      // setdate(offsetDate);
                      setdate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField  {...params}
                        onKeyDown={e => e.preventDefault()}
                        fullWidth sx={{ mt: 2 }}

                        error={errdateb}
                        helperText={errdate}

                      />

                    )}
                  //onChangeRaw={handleDateChangeRaw}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  error={erraddb}
                  helperText={erradd}
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-name"
                  label="Enter Address"
                  //defaultValue={userdata.state_name}
                  value={address}
                  onChange={(e) => setaddress(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  error={erremailb}
                  helperText={erremail}
                  margin="normal"

                  fullWidth
                  id="outlined-name"
                  label="Enter E-Mail"
                  //defaultValue={userdata.state_name}
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
            </Grid>
            {/* <Grid item xs={4}>
              <div>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="number"
                  id="outlined-number"
                  label="Enter Mobile Number"
                  inputProps={{maxLength: '10' }}
                  value={mobile}
                  //defaultValue={userdata.state_name}
                  onChange={(e) => setmobile(e.target.value)}
                />
              </div>
            </Grid> */}
            <Grid item xs={4}>
              <div>
                <TextField
                  error={errmobb}
                  helperText={errmob}
                  margin="normal"

                  fullWidth
                  id="outlined-number"
                  label="Enter Mobile Number"
                  type="number"
                  InputLabelProps={{
                    maxLength: 10,
                  }}

                  value={mobile}
                  onChange={(e) => setmobile(e.target.value)}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={4}>
              <div>
                <TextField
                  error={erraplb}
                  helperText={errapl}
                  margin="normal"

                  fullWidth
                  id="outlined-select-district"
                  select
                  label="APL/BPL"
                  value={apl}
                  onChange={(e) => setapl(e.target.value)}
                //onChange={handleChange}
                >
                  <MenuItem key={1} value={"APL"}>
                    APL
                  </MenuItem>
                  <MenuItem key={2} value={"BPL"}>
                    BPL
                  </MenuItem>
                  <MenuItem key={3} value={"NA"}>
                    NA
                  </MenuItem>
                </TextField>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  error={errqualb}
                  helperText={errqual}
                  margin="normal"
                  required
                  fullWidth
                  select
                  id="outlined-name"
                  label="Enter Beneficiary Qualification"
                  //defaultValue={userdata.state_name}
                  value={qual}
                  onChange={(e) => setqual(e.target.value)}
                >
                  {getquals.map((option) => (
                    <MenuItem
                      key={option.qual_id}
                      value={option.qual_id}
                    >
                      {option.qual_name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  error={errcoursesb}
                  helperText={errcourses}
                  margin="normal"
                  required
                  fullWidth
                  select
                  id="outlined-name"
                  label="Course Opted"
                  //defaultValue={userdata.state_name}
                  value={courses}
                  onChange={(e) => setcourses(e.target.value)}

                >
                  {getcourses.map((option) => (
                    <MenuItem
                      key={option.course_id}
                      value={option.course_id}
                    >
                      {option.course_name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  error={errpidb}
                  helperText={errpid}
                  margin="normal"
                  
                  fullWidth
                  id="outlined-select-district"
                  select
                  label="Select ID Proof Type"
                  value={pid}
                  onChange={(e) => setpid(e.target.value)}
                //onChange={handleChange}
                >
                  {proofs.map((option) => (
                    <MenuItem
                      key={option.proof_type_id}
                      value={option.proof_type_id}
                    >
                      {option.proof_type}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  error={errpnumb}
                  helperText={errpnum}
                  margin="normal"
                  
                  fullWidth
                  id="outlined-name"
                  label="Enter Proof Number"
                  //defaultValue={userdata.state_name}
                  value={pnum}
                  onChange={(e) => setpnum(e.target.value)}
                  inputProps={{ maxLength: 12 }}

                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <TextField
                  error={errbcasteb}
                  helperText={errbcaste}
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-select-district"
                  select
                  label="Select Caste"
                  value={bcaste}
                  onChange={(e) => setbcaste(e.target.value)}
                //onChange={handleChange}
                >
                  {castes.map((option) => (
                    <MenuItem key={option.caste_id} value={option.caste_id}>
                      {option.caste_name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>

            <Grid item xs={4}>
              <div>
                <Button
                  margin="normal"
                  fullWidth
                  required
                  component="label"
                  variant="outlined"
                  sx={{ mt: 2 }}
                  startIcon={<FontAwesomeIcon icon={faFileArrowUp} />}
                  color={btncolor}
                  helperText={errbtn}
                >
                  Upload Image (jpg, jpeg & png )
                  less than 500KB
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    name={file}
                    hidden
                    onChange={handlefilechange}
                  />
                </Button>
              </div>
              {filename} <img src={preview} hidden={hiddenb} alt="" width="100" height="100" />
            </Grid>
            <Grid item xs={4}>
              <div>
                <Button
                  margin="normal"
                  fullWidth
                  required
                  component="label"
                  variant="outlined"
                  sx={{ mt: 2 }}
                  startIcon={<FontAwesomeIcon icon={faFileArrowUp} />}
                  color={formbtncolor}
                  helperText={errformbtn}
                >
                  Upload Scanned Form (PDF )
                  less than 500KB
                  <input
                    type="file"
                    accept=".pdf"
                    name={formfile}
                    hidden
                    onChange={handleformfilechange}
                  />
                </Button>
              </div>
              {formfilename}
            </Grid>
            <Grid item xs={4}>
              <Typography></Typography>
            </Grid>
            <Grid item xs={4}>
              <Collapse in={openalertimg}>
                <Alert severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setopenalertimg(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {imagealert}
                </Alert>
              </Collapse>
            </Grid>
            <Grid item xs={4}>
              <Collapse in={openalertform}>
                <Alert severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setopenalertform(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {formalert}
                </Alert>
              </Collapse>
            </Grid>
            <Grid container justifyContent="center" sx={{ mt: 3, mb: 3 }}>
              <Button variant="contained" onClick={handlesubmitclick}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={snackmsg}
        //action={action}
        />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdrop}
          onClick={handlebackdropClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
}
