import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie';
import login from '../../images/login.jpg';
import NavbarUser from '../../components/NavbarUser';
import config from '../config.json';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box'
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ViewIcon from '@mui/icons-material/Tv'
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import { decrypter } from "../../Util"
import NavBarAdmin from '../NavBarAdmin';
import {Tooltip} from '@mui/material';


const cookies = new Cookies();
const BASE_URL = config.SERVER_URL;
const KEY = config.KEY;
const TITLE = config.TITLE;


export default function ManageCourses() {

    const columns = [
        {
            field: 'course_id', headerName: 'ID', flex: 0.2, align: 'center', headerAlign: 'center'
        },

        {
            field: 'course_name', headerName: 'Course Name', flex: 1, align: 'center', headerAlign: 'center'
        },

        {
            field: 'course_code', headerName: 'Course Code', flex: 0.5, align: 'center', headerAlign: 'center'
        },

        {
            field: 'course_description', headerName: 'Course Description', flex: 1, align: 'center', headerAlign: 'center'
        },
        {
            field: "Action",
            renderCell: (cellValues) => {
                return (
                    <>
                        <Tooltip title="Edit" arrow>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleEditClick(event, cellValues);
                            }}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" arrow>
                        <IconButton
                            //   variant="contained"
                            //   color="primary"
                            //   size='medium'
                            //   startIcon={<EditIcon></EditIcon>}

                            onClick={(event) => {
                                handleDeleteClick(event, cellValues);
                            }}
                        >
                            <DeleteIcon></DeleteIcon>
                        </IconButton>
                        </Tooltip>
                    </>
                );
            }, flex: 0.5, align: 'center', headerAlign: 'center'
        }

    ]
    let userdata = decrypter(cookies.get("userdata"))
    const navigate = useNavigate();
    const [courses, setcourses] = useState([]);
    const [open, setOpen] = useState(false);
    const [click, setclick] = useState(false);
    const [del, setdel] = useState('')
    const [dialog, setdialog] = useState(false)

    useEffect(() => {

        if (userdata === "false") {

            navigate('/')

        }

        const headers = {
            'publicKey': KEY
        }

        axios.post(BASE_URL + '/getCourses', {}, { headers: headers })
            .then(function (responses) {

                console.log("Courses", responses.data.Courses)
                setcourses(responses.data.Courses)


            })
            .catch(function (errors) {

            })

    }, [click])

    const handleCloseDialog = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setdialog(false);
    };

    const handleaddcourse = (e) => {

        e.preventDefault();
        navigate('/addCourse')

    }

    const handleEditClick = (event, cellValues) => {

        console.log("in edit click", cellValues.row)
        navigate('/editCourse', {
            state: {
                id: cellValues.row.course_id, name: cellValues.row.course_name,
                code: cellValues.row.course_code, desc: cellValues.row.course_description
            }
        })


    }

    const handleDeleteClick = (event, cellValues) => {

        console.log("in delete click", cellValues.row.course_id)
        setOpen(true);
        setdel(cellValues.row.course_id)



    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {

        const headers = {
            'publicKey': KEY
        }

        const data = new FormData();
        data.append('model', JSON.stringify({
            course_id: del
        }))

        axios.post(BASE_URL + '/deleteCourse', data, { headers: headers })
            .then(function (responses) {

                console.log(responses.data)
                if (responses.data === 'OK') {

                    setclick(true)
                    setdialog(true);
                }
            })
            .catch(function (errors) {

            })
        setOpen(false);
        setclick(false)

    }




    return (
        <>
            <NavBarAdmin
                title={TITLE}
                name={userdata.faculty_name}
                role={userdata.role_name}
                state={userdata.district_name}
                image={userdata.faculty_image}
            />
            {/* carousel starts */}
            <div className="row">
                <div className="container ">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={login} className="d-block w-100" alt="Max-width 100%" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* carousel ends */}
            <div className="container">
                <Button variant="contained" sx={{ mt: 2, mb: 2 }} startIcon={<FontAwesomeIcon icon={faPlus} className="me-1" />} onClick={handleaddcourse}>Add Course</Button>
                <h1>
                    <div className="abc text-white text-center col 10 p-1 mt-1">
                        <h3> Manage Courses </h3>
                    </div>
                </h1>

                <Box
                    justifyContent='center'
                    sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={courses}
                        getRowId={(r) => r.course_id}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}

                    // onSelectionModelChange={(newSelectionModel) => {
                    //     setSelectedRows(newSelectionModel);
                    // }}

                    />
                </Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"ARE YOU SURE YOU WANT TO DELETE?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Once deleted it cannot be undone
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDelete}>YES</Button>
                        <Button onClick={handleClose} autoFocus>
                            NO
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={dialog}
                    autoHideDuration={6000}
                    onClose={handleCloseDialog}
                    message="Course Deleted"
                //action={action}
                />


            </div>
        </>
    )
}
